import React, { useState, useEffect } from "react"

import { useMutation } from "@apollo/client"
import { Formik } from "formik"
import { toast } from "sonner"
import Confetti from "react-confetti"
import { useWindowSize } from "react-use"

import CommunityLofiProfileComponent from "@love-irish/community-lofi-profile-component"
import {
  TextInput,
  Checkbox,
  Label as FlowbiteLabel,
  ClipboardWithIcon,
} from "flowbite-react"
import Button from "@love-irish/core-button"
import { Input } from "@love-irish/ui-components/input"
import { Textarea } from "@love-irish/ui-components/textarea"

import { UpdateUserProfileMutation } from "@love-irish/graphql-api"
import Title from "design_system/core/components/title"
import Content from "design_system/core/components/content"
import Steps from "./steps"

export const ProfileOnboardingPage = ({
  profile,
  completedStep,
  currentStep,
}) => {
  const { width, height } = useWindowSize()
  const [updateUserProfile, { data, loading, error }] = useMutation(
    UpdateUserProfileMutation
  )

  return (
    <div className="container mt-8">
      <div className="max-w-screen-lg mx-auto">
        <div className="text-center">
          <Title title="Fáilte! First things first..." />
          <Content content="Create your profile to personalize how you'll appear in the community" />
          <div className="mt-8">
            <Steps completedStep={completedStep} currentStep={currentStep} />
          </div>
        </div>
      </div>

      <Confetti
        width={width}
        height={height}
        recycle={false}
        numberOfPieces={100}
        tweenDuration={3000}
        initialVelocityY={{ min: 5, max: 20 }}
        colors={[
          "#009a4a",
          "#166534",
          "#0f172a",
          "#cbd5e1",
          "#a3e635",
          "#eab308",
          "#d92b2a",
          "#009a4a",
        ]}
      />
      <div className="grid md:grid-cols-2 mt-24 gap-12">
        <div className="">
          <CommunityLofiProfileComponent
            className="!h-auto"
            avatar={profile.profileIcon?.imageUrl}
          />
        </div>
        <div className="">
          <Formik
            initialValues={{
              slug: profile.slug,
              name: profile.name,
              shortBio: profile.shortBio,
              communityTermsAccepted: profile.communityTermsAccepted,
            }}
            validate={(values) => {
              const errors = {}
              if (!values.slug) {
                errors.slug = "Required"
              }

              if (values?.slug?.includes(" ")) {
                errors.slug = "Contains invalid characters"
              }
              return errors
            }}
            onSubmit={(values, { setSubmitting, setErrors }) => {
              updateUserProfile({
                variables: {
                  input: {
                    ...values,
                  },
                },
              })
                .then((result) => {
                  if (!result?.data?.updateUserProfile?.success) {
                    const errors = {}

                    if (result?.data?.updateUserProfile?.errors) {
                      result.data.updateUserProfile.errors.forEach((error) => {
                        errors[error.path[1]] = error.message
                      })
                    }
                    setErrors(errors)
                    return toast.error(
                      "There was a problem updating your profile. Please try again or contact support."
                    )
                  } else {
                    window.location.href = "/start/overview"
                  }
                })
                .catch((_error) =>
                  toast.error(
                    "There was a problem updating your profile. Please try again or contact support."
                  )
                )
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              setFieldValue,
              dirty,
            }) => (
              <form onSubmit={handleSubmit}>
                <div className="space-y-8">
                  <div className="">
                    <div className="mb-2 block">
                      <FlowbiteLabel htmlFor="name" value="Display name" />
                    </div>
                    <Input
                      id="name"
                      placeholder="Cad is ainm duit? (what is your name)"
                      name="name"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.name}
                    />
                  </div>

                  <div className="">
                    <div className="mb-4 block">
                      <FlowbiteLabel htmlFor="slug" value="Username" />
                      <p className="text-sm italic">
                        Supports lowercase alphanumeric characters with accents
                        (<strong>á</strong> & <strong>è</strong>), dashes and
                        underscore and periods. Maximum 75 characters.
                      </p>
                    </div>
                    <TextInput
                      id="slug"
                      name="slug"
                      onChange={(e) =>
                        setFieldValue(
                          "slug",
                          e.target.value
                            .replace(/[^áéíóúàèìòùa-z0-9\-_.]/gi, "")
                            .toLowerCase()
                            .slice(0, 75)
                        )
                      }
                      onBlur={handleBlur}
                      value={values.slug}
                      sizing="md"
                      placeholder="username"
                      addon={<strong>@</strong>}
                      required
                    />

                    {errors?.slug && touched?.slug && (
                      <div className="text-error-500 my-2">{errors.slug}</div>
                    )}
                  </div>

                  <div className="">
                    <div className="mb-4 block">
                      <FlowbiteLabel htmlFor="shortBio" value="Tagline" />
                      <p className="text-sm italic">
                        Supports basic markdown like links, _italics_, and
                        **bold**. Maximum 200 characters.
                      </p>
                    </div>

                    <Textarea
                      id="shortBio"
                      placeholder="Add a short tagline"
                      rows={2}
                      name="shortBio"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.shortBio}
                      maxLength={200}
                    />
                  </div>

                  {/*<li className="mt-8">*/}
                  {/*  Goals (language learning, games & mythology, just exploring)*/}
                  {/*</li>*/}
                  {/*<li>Labels (fluency, corrections welcome, languages)</li>*/}
                  {/*<li>How did you hear about us? (optional)</li>*/}

                  <div className="hidden">
                    <div className="mb-4 block mt-8">
                      <FlowbiteLabel
                        htmlFor="inviteLink"
                        value="Your invite link"
                      />
                      <p className="mt-2 text-sm italic">
                        Earn a % of the experience from your referrals for 14
                        days after they join using your invite link.
                      </p>
                    </div>
                    <div className="relative">
                      <TextInput
                        id="inviteLink"
                        name="inviteLink"
                        sizing="md"
                        readOnly
                        onFocus={(e) => e.target.select()}
                        value="love.irish/invite/jd912d8as2"
                      />
                      <ClipboardWithIcon
                        valueToCopy="https://love.irish/invite/jd912d8as2"
                        type={"button"}
                      />
                    </div>
                  </div>

                  <div className="">
                    <div className="mb-4 block">
                      <FlowbiteLabel
                        htmlFor="communityTermsAccepted"
                        value="Community rules"
                      />
                      <p className="text-sm italic prose prose-sm">
                        You must agree to our{" "}
                        <a href="/rules">community rules</a> before your profile
                        can be active on this site.
                      </p>
                    </div>

                    <div className="flex gap-3 items-start">
                      <Checkbox
                        name="communityTermsAccepted"
                        className="mt-1"
                        required
                        onChange={handleChange}
                        onBlur={handleBlur}
                        checked={values.communityTermsAccepted}
                        id="communityTermsAccepted"
                      />
                      <p className="text-sm italic">
                        <FlowbiteLabel
                          htmlFor="communityTermsAccepted"
                          value="I agree to follow the community rules to do my part in creating a welcoming community for anyone interested in Irish and Celtic topics"
                        />
                      </p>
                    </div>
                  </div>

                  <div className="flex justify-end mt-4 gap-4">
                    <Button
                      type="submit"
                      disabled={!values.communityTermsAccepted || loading}
                    >
                      Save & continue &rarr;
                    </Button>
                  </div>
                </div>
              </form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  )
}

export default ProfileOnboardingPage
