import React, { Fragment, useEffect } from "react"
import Title from "design_system/core/components/title"
import {
  IconDeviceGamepad2,
  IconHeart,
  IconPlayerPlay,
  IconSearch,
} from "@tabler/icons-react"
import classNames from "classnames"
import Tooltip from "design_system/core/components/tooltip"
import Content from "design_system/core/components/content"
import Button from "design_system/core/components/button"
import useSound from "use-sound"
import "./index.css"

import tadhgAvatar from "images/profile_icons/tadhg.png"
import AddToCollection from "@love-irish/resource-add-to-collection"
import LofiString from "design_system/core/components/lo_fi/string"
import LofiInput from "design_system/core/components/lo_fi/input"

// We support a simple version of liquid type tags in codex record names to help
// with examples. Right now we use @grug as the data source for the dynamic tag.
export function addDynamicTags(string = "") {
  return string.replace("_name_", "_@grug_")
}

const lofiPreviews = {
  "Item::MiniGame": () => (
    <div className="grid grid-cols-2 gap-8 h-[100px] justify-center">
      <div className="space-y-4">
        <LofiInput
          className="h-6 p-2 border-success bg-success-100"
          stringClassName="bg-success"
        />
        <LofiInput className="h-6 p-2" />
        <LofiInput
          className="h-6 p-2 border-dark bg-dark-100 mx-auto"
          stringClassName="bg-dark"
        />
      </div>
      <div className="space-y-4">
        <LofiInput className="h-6 p-2" />
        <LofiInput
          className="h-6 p-2 border-success bg-success-100"
          stringClassName="bg-success"
        />
        <LofiInput className="h-6 p-2" />
      </div>
    </div>
  ),
  "Item::GuessingMiniGame": () => (
    <div className="flex flex-col gap-4 h-[100px]">
      <div className="text-center">
        <LofiInput
          width="max-w-[100px]"
          className="h-6 p-2 border-dark bg-dark-100 mx-auto"
          stringClassName="bg-dark"
        />
      </div>
      <div className="grid grid-cols-3 gap-2 justify-center">
        <LofiInput
          className="h-6 p-2 border-success bg-success-100"
          stringClassName="bg-success"
        />
        <LofiInput className="h-6 p-2" />
        <LofiInput className="h-6 p-2" />
      </div>
    </div>
  ),
}

const MiniGameListItem = ({
  className = "",
  content = null,
  addon = true,
  footer = false,
  displayAudioInfo = false,
  level = 0,
  item,
  collections,
  currentUser,
  ...props
}) => {
  const [play] = useSound(item.audioUrl || "")

  const PreviewComponent = lofiPreviews[item.type] || Fragment

  const handlePlay = (e) => {
    e.stopPropagation()
    play()
  }
  return (
    <div className={classNames("border rounded-xl min-w-[100px]", className)}>
      <div className="p-12 aspect-banner border-b">
        <PreviewComponent />
      </div>
      <div className="px-4 pt-4 text-xs text-secondary-600 flex gap-1 justify-between items-center">
        <div className="flex gap-1 items-center">
          <IconDeviceGamepad2
            className="text-secondary-400 inline-block align-middle shrink-0"
            size={14}
          />
          <Tooltip title="Practice words and earn experience, loot and other rewards">
            Mini Game
          </Tooltip>
        </div>
      </div>
      <div className="p-4">
        <div className="flex justify-between items-start mb-3 gap-8">
          <Title
            className="post-title text-2xl text-definition flex gap-2"
            title={addDynamicTags(item.name)}
            size="sm"
            showIcon={false}
            href={item.path}
            {...props}
          />
          <div className="flex shrink-0 gap-1">
            <Tooltip title="Start game">
              <Button size="sm" variant="ghost" as="a" href={item.path}>
                <IconDeviceGamepad2 size={16} className="inline-block" />
              </Button>
            </Tooltip>
            {item.audioUrl && (
              <Tooltip title="Play featured community recording">
                <Button size="sm" variant="ghost" onClick={handlePlay}>
                  <IconPlayerPlay size={16} className="inline-block" />
                </Button>
              </Tooltip>
            )}
            <AddToCollection itemId={item.gid} currentUser={currentUser} />
          </div>
        </div>

        <div className="flex gap-8 justify-between">
          <div>
            {item.translation && (
              <div className="mt-4">
                <p className="text-xs text-secondary uppercase mb-2">
                  Translation:
                </p>
                <Content
                  content={addDynamicTags(item.translation)}
                  className="prose-sm text-inherit text-sm text-dark dark:text-white !font-mono first-line:not-italic"
                />
              </div>
            )}

            {item.pronunciation && (
              <div className="mt-4">
                <p className="text-xs text-secondary uppercase mb-2">
                  Rough pronunciation for English speakers:
                </p>
                <Content
                  content={item.pronunciation}
                  className="prose-sm text-inherit text-sm text-dark dark:text-white !font-mono first-line:not-italic"
                />
              </div>
            )}

            {item.summary && (
              <div className="mt-4">
                <Content
                  content={item.summary}
                  className="prose-sm text-inherit text-sm text-dark dark:text-white !font-mono first-line:not-italic"
                />
              </div>
            )}

            {item.slug && (
              <div className="mt-4">
                <a href={item.slug}>
                  <strong>Leigh nos</strong> &middot; <em>Read more</em> &rarr;
                </a>
              </div>
            )}
          </div>

          {item.imageUrl && (
            <div className="w-[256px] aspect-square pixel-art mt-4 shrink-0">
              <img src={item.imageUrl} className="w-full block" />
            </div>
          )}
        </div>
      </div>

      {item.audioUrl && (
        <div className="p-2">
          <div className="flex justify-start gap-2 bg-earth-50/70 text-earth-800 p-2 rounded border border-earth-200 items-center">
            <img src={tadhgAvatar} className="w-[32px] pixel-art" />
            <button
              aria-label="Play audio"
              className="!bg-none cursor-pointer"
              onClick={handlePlay}
              type="button"
            >
              <IconPlayerPlay size={24} className="inline-block" />
            </button>
            <p>
              Featured community recording from{" "}
              <a href="/@tadhg" className="font-bold">
                @tadhg
              </a>
            </p>
          </div>
        </div>
      )}

      {footer && (
        <div className="p-3 text-xs  rounded-b border-t border-secondary-200 flex gap-2 bg-secondary-50 text-secondary-600 dark:bg-dark-900 dark:text-secondary-100 dark:border-dark-500">
          <IconSearch
            className="text-secondary-400 inline-block align-middle"
            size={14}
          />
          <div>
            {level === 1 ? "Unlocked a new codex record!" : "Found again!"}{" "}
            <span className="text-warning font-bold dark:text-warning-400">
              + 5 EXP
            </span>
          </div>
        </div>
      )}
    </div>
  )
}

export default MiniGameListItem
