import React from "react"

import { convert } from "ogham"
import { remove } from "diacritics"

import { format } from "timeago.js"

const Signature = ({ signature }) => (
  <div>
    <div className="text-3xl mb-6 mt-3">{convert(remove(signature.irish))}</div>
    <div className="">"{signature.irish}"</div>
    <div className="mt-3 text-sm italic">
      Translation: {signature.translation}
    </div>
    {signature.profile && (
      <div className="mt-3 text-sm">
        Signed by{" "}
        <a href={`/@${signature.profile}`} className="font-bold">
          @{signature.profile}
        </a>{" "}
        <time
          title={new Date(signature.createdAt).toLocaleString()}
          dateTime={signature.createdAt}
        >
          {format(signature.createdAt)}
        </time>
      </div>
    )}
  </div>
)

export default Signature
