import React from "react"
import classNames from "classnames"
import { Avatar, Dropdown, Navbar } from "flowbite-react"

import "./index.css"
import { IconExternalLink, IconMenu } from "@tabler/icons-react"

export const navigation = [
  { name: "Codex", href: "/codex" },
  { name: "Calendar", href: "/calendar" },
  { name: "Collections", href: "/collections" },
  { name: "Topics", href: "/topics" },
  { name: "Interviews", href: "/interviews" },
]

export default ({ currentUser, currentPath = "/" }) => {
  return (
    <header className="app-header">
      <div className="container">
        <Navbar fluid rounded className="py-3 !px-0">
          <ul className="mt-0 flex flex-row space-x-2 md:space-x-8 text-sm font-medium overflow-x-auto">
            {navigation.map(
              ({ name, className, translated, href, ...rest }) => {
                const isActive = currentPath.startsWith(href)

                return (
                  <Navbar.Link
                    key={name}
                    href={href}
                    className={classNames(
                      "app-nav-link border-0",
                      {
                        active: isActive,
                      },
                      className
                    )}
                    {...rest}
                  >
                    <span>
                      <span
                        className={classNames(
                          "text-md block font-medium text-dark-700",
                          { "font-extrabold": isActive }
                        )}
                      >
                        {name}{" "}
                        {href?.includes("http") && (
                          <IconExternalLink
                            className="inline align-top text-secondary"
                            size={12}
                          />
                        )}
                      </span>
                      <em className="text-xs">{translated}</em>
                    </span>
                  </Navbar.Link>
                )
              }
            )}
          </ul>
        </Navbar>
      </div>
    </header>
  )
}
