export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  /** An ISO 8601-encoded datetime */
  ISO8601DateTime: { input: any; output: any; }
  /** Represents untyped JSON */
  JSON: { input: any; output: any; }
};

export type Collection = Node & Record & {
  __typename?: 'Collection';
  accessLevel: Scalars['String']['output'];
  ancestry?: Maybe<Scalars['String']['output']>;
  /** Query related CollectionItems with filters. */
  collectionItems: CollectionItemConnection;
  content?: Maybe<Scalars['String']['output']>;
  /** The date and time that the resource was created. */
  createdAt: Scalars['ISO8601DateTime']['output'];
  data: Scalars['JSON']['output'];
  featured: Scalars['Boolean']['output'];
  /** ID of the object. */
  id: Scalars['ID']['output'];
  itemType?: Maybe<Scalars['String']['output']>;
  /** Query related Items with filters. */
  items: ItemConnection;
  name?: Maybe<Scalars['String']['output']>;
  owner?: Maybe<Profile>;
  path?: Maybe<Scalars['String']['output']>;
  pid?: Maybe<Scalars['String']['output']>;
  publishedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  slug?: Maybe<Scalars['String']['output']>;
  status: Scalars['String']['output'];
  summary?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  /** The date and time that the resource was last updated. */
  updatedAt: Scalars['ISO8601DateTime']['output'];
  url?: Maybe<Scalars['String']['output']>;
};


export type CollectionCollectionItemsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  collectionId?: InputMaybe<Scalars['ID']['input']>;
  collectionIdIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  collectionIdNin?: InputMaybe<Array<Scalars['ID']['input']>>;
  createdAt?: InputMaybe<Scalars['ISO8601DateTime']['input']>;
  createdAtGt?: InputMaybe<Scalars['ISO8601DateTime']['input']>;
  createdAtGte?: InputMaybe<Scalars['ISO8601DateTime']['input']>;
  createdAtIn?: InputMaybe<Array<Scalars['ISO8601DateTime']['input']>>;
  createdAtLt?: InputMaybe<Scalars['ISO8601DateTime']['input']>;
  createdAtLte?: InputMaybe<Scalars['ISO8601DateTime']['input']>;
  createdAtNil?: InputMaybe<Scalars['Boolean']['input']>;
  createdAtNin?: InputMaybe<Array<Scalars['ISO8601DateTime']['input']>>;
  createdAtNnil?: InputMaybe<Scalars['Boolean']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  idIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  idNin?: InputMaybe<Array<Scalars['ID']['input']>>;
  itemId?: InputMaybe<Scalars['ID']['input']>;
  itemIdIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  itemIdNin?: InputMaybe<Array<Scalars['ID']['input']>>;
  last?: InputMaybe<Scalars['Int']['input']>;
  limit?: Scalars['Int']['input'];
  page?: InputMaybe<Scalars['Int']['input']>;
  perPage?: InputMaybe<Scalars['Int']['input']>;
  sortBy?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['ISO8601DateTime']['input']>;
  updatedAtGt?: InputMaybe<Scalars['ISO8601DateTime']['input']>;
  updatedAtGte?: InputMaybe<Scalars['ISO8601DateTime']['input']>;
  updatedAtIn?: InputMaybe<Array<Scalars['ISO8601DateTime']['input']>>;
  updatedAtLt?: InputMaybe<Scalars['ISO8601DateTime']['input']>;
  updatedAtLte?: InputMaybe<Scalars['ISO8601DateTime']['input']>;
  updatedAtNil?: InputMaybe<Scalars['Boolean']['input']>;
  updatedAtNin?: InputMaybe<Array<Scalars['ISO8601DateTime']['input']>>;
  updatedAtNnil?: InputMaybe<Scalars['Boolean']['input']>;
};


export type CollectionItemsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['ISO8601DateTime']['input']>;
  createdAtGt?: InputMaybe<Scalars['ISO8601DateTime']['input']>;
  createdAtGte?: InputMaybe<Scalars['ISO8601DateTime']['input']>;
  createdAtIn?: InputMaybe<Array<Scalars['ISO8601DateTime']['input']>>;
  createdAtLt?: InputMaybe<Scalars['ISO8601DateTime']['input']>;
  createdAtLte?: InputMaybe<Scalars['ISO8601DateTime']['input']>;
  createdAtNil?: InputMaybe<Scalars['Boolean']['input']>;
  createdAtNin?: InputMaybe<Array<Scalars['ISO8601DateTime']['input']>>;
  createdAtNnil?: InputMaybe<Scalars['Boolean']['input']>;
  featured?: InputMaybe<Scalars['Boolean']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  idIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  idNin?: InputMaybe<Array<Scalars['ID']['input']>>;
  itemType?: InputMaybe<Scalars['String']['input']>;
  itemTypeIn?: InputMaybe<Array<Scalars['String']['input']>>;
  itemTypeNil?: InputMaybe<Scalars['Boolean']['input']>;
  itemTypeNin?: InputMaybe<Array<Scalars['String']['input']>>;
  itemTypeNnil?: InputMaybe<Scalars['Boolean']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  limit?: Scalars['Int']['input'];
  page?: InputMaybe<Scalars['Int']['input']>;
  perPage?: InputMaybe<Scalars['Int']['input']>;
  publishedAt?: InputMaybe<Scalars['ISO8601DateTime']['input']>;
  publishedAtGt?: InputMaybe<Scalars['ISO8601DateTime']['input']>;
  publishedAtGte?: InputMaybe<Scalars['ISO8601DateTime']['input']>;
  publishedAtIn?: InputMaybe<Array<Scalars['ISO8601DateTime']['input']>>;
  publishedAtLt?: InputMaybe<Scalars['ISO8601DateTime']['input']>;
  publishedAtLte?: InputMaybe<Scalars['ISO8601DateTime']['input']>;
  publishedAtNil?: InputMaybe<Scalars['Boolean']['input']>;
  publishedAtNin?: InputMaybe<Array<Scalars['ISO8601DateTime']['input']>>;
  publishedAtNnil?: InputMaybe<Scalars['Boolean']['input']>;
  slug?: InputMaybe<Scalars['String']['input']>;
  slugIn?: InputMaybe<Array<Scalars['String']['input']>>;
  slugNil?: InputMaybe<Scalars['Boolean']['input']>;
  slugNin?: InputMaybe<Array<Scalars['String']['input']>>;
  slugNnil?: InputMaybe<Scalars['Boolean']['input']>;
  sortBy?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
  statusIn?: InputMaybe<Array<Scalars['String']['input']>>;
  statusNil?: InputMaybe<Scalars['Boolean']['input']>;
  statusNin?: InputMaybe<Array<Scalars['String']['input']>>;
  statusNnil?: InputMaybe<Scalars['Boolean']['input']>;
  updatedAt?: InputMaybe<Scalars['ISO8601DateTime']['input']>;
  updatedAtGt?: InputMaybe<Scalars['ISO8601DateTime']['input']>;
  updatedAtGte?: InputMaybe<Scalars['ISO8601DateTime']['input']>;
  updatedAtIn?: InputMaybe<Array<Scalars['ISO8601DateTime']['input']>>;
  updatedAtLt?: InputMaybe<Scalars['ISO8601DateTime']['input']>;
  updatedAtLte?: InputMaybe<Scalars['ISO8601DateTime']['input']>;
  updatedAtNil?: InputMaybe<Scalars['Boolean']['input']>;
  updatedAtNin?: InputMaybe<Array<Scalars['ISO8601DateTime']['input']>>;
  updatedAtNnil?: InputMaybe<Scalars['Boolean']['input']>;
};

/** The connection type for Collection. */
export type CollectionConnection = {
  __typename?: 'CollectionConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<CollectionEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<Collection>>>;
  /** Information to aid in page-based pagination. */
  pageBasedPaginationInfo: PageBasedPaginationInfo;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type CollectionEdge = {
  __typename?: 'CollectionEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<Collection>;
};

export type CollectionItem = Node & Record & {
  __typename?: 'CollectionItem';
  collection: Collection;
  createdAt: Scalars['ISO8601DateTime']['output'];
  /** ID of the object. */
  id: Scalars['ID']['output'];
  item: Item;
  updatedAt: Scalars['ISO8601DateTime']['output'];
};

/** The connection type for CollectionItem. */
export type CollectionItemConnection = {
  __typename?: 'CollectionItemConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<CollectionItemEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<CollectionItem>>>;
  /** Information to aid in page-based pagination. */
  pageBasedPaginationInfo: PageBasedPaginationInfo;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type CollectionItemEdge = {
  __typename?: 'CollectionItemEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<CollectionItem>;
};

/** Autogenerated input type of CreateCollectionMutation */
export type CreateCollectionMutationInput = {
  accessLevel?: InputMaybe<Scalars['String']['input']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  content?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  ownerId: Scalars['ID']['input'];
  slug?: InputMaybe<Scalars['String']['input']>;
  summary?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of CreateCollectionMutation. */
export type CreateCollectionMutationPayload = {
  __typename?: 'CreateCollectionMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  collection?: Maybe<Collection>;
  errors: Array<MutationValidationError>;
  success: Scalars['Boolean']['output'];
};

/** Autogenerated input type of CreateCollectionResourceMutation */
export type CreateCollectionResourceMutationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  collectionId: Scalars['ID']['input'];
  collectionResourceId: Scalars['ID']['input'];
};

/** Autogenerated return type of CreateCollectionResourceMutation. */
export type CreateCollectionResourceMutationPayload = {
  __typename?: 'CreateCollectionResourceMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  collection?: Maybe<Collection>;
  errors: Array<MutationValidationError>;
  success: Scalars['Boolean']['output'];
};

export type CurrentUser = Node & Record & {
  __typename?: 'CurrentUser';
  availableProfileIcons?: Maybe<ItemProfileIconConnection>;
  /** The date and time that the resource was created. */
  createdAt: Scalars['ISO8601DateTime']['output'];
  email: Scalars['String']['output'];
  /** ID of the object. */
  id: Scalars['ID']['output'];
  onboardingState?: Maybe<StateMachineUserOnboarding>;
  pid?: Maybe<Scalars['String']['output']>;
  /** The date and time that the resource was last updated. */
  updatedAt: Scalars['ISO8601DateTime']['output'];
};


export type CurrentUserAvailableProfileIconsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type Item = Node & Record & {
  __typename?: 'Item';
  accessLevel: Scalars['String']['output'];
  ancestry?: Maybe<Scalars['String']['output']>;
  content?: Maybe<Scalars['String']['output']>;
  /** The date and time that the resource was created. */
  createdAt: Scalars['ISO8601DateTime']['output'];
  data: Scalars['JSON']['output'];
  featured: Scalars['Boolean']['output'];
  /** ID of the object. */
  id: Scalars['ID']['output'];
  itemType?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  path: Scalars['String']['output'];
  pid?: Maybe<Scalars['String']['output']>;
  publishedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  slug?: Maybe<Scalars['String']['output']>;
  status: Scalars['String']['output'];
  summary?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  /** The date and time that the resource was last updated. */
  updatedAt: Scalars['ISO8601DateTime']['output'];
};

/** The connection type for Item. */
export type ItemConnection = {
  __typename?: 'ItemConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<ItemEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<Item>>>;
  /** Information to aid in page-based pagination. */
  pageBasedPaginationInfo: PageBasedPaginationInfo;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type ItemEdge = {
  __typename?: 'ItemEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<Item>;
};

export type ItemProfileBanner = Node & Record & {
  __typename?: 'ItemProfileBanner';
  accessLevel: Scalars['String']['output'];
  ancestry?: Maybe<Scalars['String']['output']>;
  content?: Maybe<Scalars['String']['output']>;
  /** The date and time that the resource was created. */
  createdAt: Scalars['ISO8601DateTime']['output'];
  data: Scalars['JSON']['output'];
  featured: Scalars['Boolean']['output'];
  /** ID of the object. */
  id: Scalars['ID']['output'];
  itemType?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  path: Scalars['String']['output'];
  pid?: Maybe<Scalars['String']['output']>;
  publishedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  slug?: Maybe<Scalars['String']['output']>;
  status: Scalars['String']['output'];
  summary?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  /** The date and time that the resource was last updated. */
  updatedAt: Scalars['ISO8601DateTime']['output'];
};

export type ItemProfileIcon = Node & Record & {
  __typename?: 'ItemProfileIcon';
  accessLevel: Scalars['String']['output'];
  ancestry?: Maybe<Scalars['String']['output']>;
  content?: Maybe<Scalars['String']['output']>;
  /** The date and time that the resource was created. */
  createdAt: Scalars['ISO8601DateTime']['output'];
  data: Scalars['JSON']['output'];
  featured: Scalars['Boolean']['output'];
  /** ID of the object. */
  id: Scalars['ID']['output'];
  itemType?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  path: Scalars['String']['output'];
  pid?: Maybe<Scalars['String']['output']>;
  publishedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  slug?: Maybe<Scalars['String']['output']>;
  status: Scalars['String']['output'];
  summary?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  /** The date and time that the resource was last updated. */
  updatedAt: Scalars['ISO8601DateTime']['output'];
};

/** The connection type for ItemProfileIcon. */
export type ItemProfileIconConnection = {
  __typename?: 'ItemProfileIconConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<ItemProfileIconEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<ItemProfileIcon>>>;
  /** Information to aid in page-based pagination. */
  pageBasedPaginationInfo: PageBasedPaginationInfo;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type ItemProfileIconEdge = {
  __typename?: 'ItemProfileIconEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<ItemProfileIcon>;
};

export type Mutation = {
  __typename?: 'Mutation';
  createCollection: CreateCollectionMutationPayload;
  createCollectionResource: CreateCollectionResourceMutationPayload;
  signGuestbookItem: SignGuestbookItemMutationPayload;
  triggerItemActionRsvp: TriggerItemActionRsvpMutationPayload;
  updateUserProfile: UpdateUserProfileMutationPayload;
};


export type MutationCreateCollectionArgs = {
  input: CreateCollectionMutationInput;
};


export type MutationCreateCollectionResourceArgs = {
  input: CreateCollectionResourceMutationInput;
};


export type MutationSignGuestbookItemArgs = {
  input: SignGuestbookItemMutationInput;
};


export type MutationTriggerItemActionRsvpArgs = {
  input: TriggerItemActionRsvpMutationInput;
};


export type MutationUpdateUserProfileArgs = {
  input: UpdateUserProfileMutationInput;
};

/** A user-readable error */
export type MutationValidationError = {
  __typename?: 'MutationValidationError';
  /** A description of the error */
  message: Scalars['String']['output'];
  /** Which input value this error came from */
  path?: Maybe<Array<Scalars['String']['output']>>;
};

/** An object with an ID. */
export type Node = {
  /** ID of the object. */
  id: Scalars['ID']['output'];
};

export type PageBasedPaginationInfo = {
  __typename?: 'PageBasedPaginationInfo';
  currentPage: Scalars['Int']['output'];
  hasNextPage: Scalars['Boolean']['output'];
  hasPreviousPage: Scalars['Boolean']['output'];
  perPage: Scalars['Int']['output'];
  totalPages: Scalars['Int']['output'];
};

/** Information about pagination in a connection. */
export type PageInfo = {
  __typename?: 'PageInfo';
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars['String']['output']>;
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars['Boolean']['output'];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean']['output'];
  /** When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars['String']['output']>;
};

export type Profile = Node & Record & {
  __typename?: 'Profile';
  banner?: Maybe<ItemProfileBanner>;
  bannerUrl?: Maybe<Scalars['String']['output']>;
  /** Query related Collections with filters. */
  collections: CollectionConnection;
  content?: Maybe<Scalars['String']['output']>;
  /** The date and time that the resource was created. */
  createdAt: Scalars['ISO8601DateTime']['output'];
  /** ID of the object. */
  id: Scalars['ID']['output'];
  name?: Maybe<Scalars['String']['output']>;
  profileIcon?: Maybe<ItemProfileIcon>;
  profileIconUrl?: Maybe<Scalars['String']['output']>;
  slug: Scalars['String']['output'];
  type?: Maybe<Scalars['String']['output']>;
  /** The date and time that the resource was last updated. */
  updatedAt: Scalars['ISO8601DateTime']['output'];
};


export type ProfileCollectionsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['ISO8601DateTime']['input']>;
  createdAtGt?: InputMaybe<Scalars['ISO8601DateTime']['input']>;
  createdAtGte?: InputMaybe<Scalars['ISO8601DateTime']['input']>;
  createdAtIn?: InputMaybe<Array<Scalars['ISO8601DateTime']['input']>>;
  createdAtLt?: InputMaybe<Scalars['ISO8601DateTime']['input']>;
  createdAtLte?: InputMaybe<Scalars['ISO8601DateTime']['input']>;
  createdAtNil?: InputMaybe<Scalars['Boolean']['input']>;
  createdAtNin?: InputMaybe<Array<Scalars['ISO8601DateTime']['input']>>;
  createdAtNnil?: InputMaybe<Scalars['Boolean']['input']>;
  featured?: InputMaybe<Scalars['Boolean']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  idIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  idNin?: InputMaybe<Array<Scalars['ID']['input']>>;
  last?: InputMaybe<Scalars['Int']['input']>;
  limit?: Scalars['Int']['input'];
  ownerId?: InputMaybe<Scalars['ID']['input']>;
  ownerIdIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  ownerIdNin?: InputMaybe<Array<Scalars['ID']['input']>>;
  page?: InputMaybe<Scalars['Int']['input']>;
  perPage?: InputMaybe<Scalars['Int']['input']>;
  publishedAt?: InputMaybe<Scalars['ISO8601DateTime']['input']>;
  publishedAtGt?: InputMaybe<Scalars['ISO8601DateTime']['input']>;
  publishedAtGte?: InputMaybe<Scalars['ISO8601DateTime']['input']>;
  publishedAtIn?: InputMaybe<Array<Scalars['ISO8601DateTime']['input']>>;
  publishedAtLt?: InputMaybe<Scalars['ISO8601DateTime']['input']>;
  publishedAtLte?: InputMaybe<Scalars['ISO8601DateTime']['input']>;
  publishedAtNil?: InputMaybe<Scalars['Boolean']['input']>;
  publishedAtNin?: InputMaybe<Array<Scalars['ISO8601DateTime']['input']>>;
  publishedAtNnil?: InputMaybe<Scalars['Boolean']['input']>;
  slug?: InputMaybe<Scalars['String']['input']>;
  slugIn?: InputMaybe<Array<Scalars['String']['input']>>;
  slugNil?: InputMaybe<Scalars['Boolean']['input']>;
  slugNin?: InputMaybe<Array<Scalars['String']['input']>>;
  slugNnil?: InputMaybe<Scalars['Boolean']['input']>;
  sortBy?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
  statusIn?: InputMaybe<Array<Scalars['String']['input']>>;
  statusNil?: InputMaybe<Scalars['Boolean']['input']>;
  statusNin?: InputMaybe<Array<Scalars['String']['input']>>;
  statusNnil?: InputMaybe<Scalars['Boolean']['input']>;
  updatedAt?: InputMaybe<Scalars['ISO8601DateTime']['input']>;
  updatedAtGt?: InputMaybe<Scalars['ISO8601DateTime']['input']>;
  updatedAtGte?: InputMaybe<Scalars['ISO8601DateTime']['input']>;
  updatedAtIn?: InputMaybe<Array<Scalars['ISO8601DateTime']['input']>>;
  updatedAtLt?: InputMaybe<Scalars['ISO8601DateTime']['input']>;
  updatedAtLte?: InputMaybe<Scalars['ISO8601DateTime']['input']>;
  updatedAtNil?: InputMaybe<Scalars['Boolean']['input']>;
  updatedAtNin?: InputMaybe<Array<Scalars['ISO8601DateTime']['input']>>;
  updatedAtNnil?: InputMaybe<Scalars['Boolean']['input']>;
};

/** The connection type for Profile. */
export type ProfileConnection = {
  __typename?: 'ProfileConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<ProfileEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<Profile>>>;
  /** Information to aid in page-based pagination. */
  pageBasedPaginationInfo: PageBasedPaginationInfo;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type ProfileEdge = {
  __typename?: 'ProfileEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<Profile>;
};

export type Query = {
  __typename?: 'Query';
  /** Find Collection. */
  collection?: Maybe<Collection>;
  /** Query Collections with filters. */
  collections: CollectionConnection;
  currentAppVersion?: Maybe<Item>;
  currentProfile?: Maybe<Profile>;
  /** Return information for the current user. */
  currentUser?: Maybe<CurrentUser>;
  /** Find Item. */
  item?: Maybe<Item>;
  /** Query Items with filters. */
  items: ItemConnection;
  /** Fetches an object given its ID. */
  node?: Maybe<Node>;
  /** Fetches a list of objects given a list of IDs. */
  nodes: Array<Maybe<Node>>;
  /** Find Profile. */
  profile?: Maybe<Profile>;
  /** Query Profiles with filters. */
  profiles: ProfileConnection;
};


export type QueryCollectionArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
  slug?: InputMaybe<Scalars['ID']['input']>;
};


export type QueryCollectionsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['ISO8601DateTime']['input']>;
  createdAtGt?: InputMaybe<Scalars['ISO8601DateTime']['input']>;
  createdAtGte?: InputMaybe<Scalars['ISO8601DateTime']['input']>;
  createdAtIn?: InputMaybe<Array<Scalars['ISO8601DateTime']['input']>>;
  createdAtLt?: InputMaybe<Scalars['ISO8601DateTime']['input']>;
  createdAtLte?: InputMaybe<Scalars['ISO8601DateTime']['input']>;
  createdAtNil?: InputMaybe<Scalars['Boolean']['input']>;
  createdAtNin?: InputMaybe<Array<Scalars['ISO8601DateTime']['input']>>;
  createdAtNnil?: InputMaybe<Scalars['Boolean']['input']>;
  featured?: InputMaybe<Scalars['Boolean']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  idIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  idNin?: InputMaybe<Array<Scalars['ID']['input']>>;
  last?: InputMaybe<Scalars['Int']['input']>;
  limit?: Scalars['Int']['input'];
  ownerId?: InputMaybe<Scalars['ID']['input']>;
  ownerIdIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  ownerIdNin?: InputMaybe<Array<Scalars['ID']['input']>>;
  page?: InputMaybe<Scalars['Int']['input']>;
  perPage?: InputMaybe<Scalars['Int']['input']>;
  publishedAt?: InputMaybe<Scalars['ISO8601DateTime']['input']>;
  publishedAtGt?: InputMaybe<Scalars['ISO8601DateTime']['input']>;
  publishedAtGte?: InputMaybe<Scalars['ISO8601DateTime']['input']>;
  publishedAtIn?: InputMaybe<Array<Scalars['ISO8601DateTime']['input']>>;
  publishedAtLt?: InputMaybe<Scalars['ISO8601DateTime']['input']>;
  publishedAtLte?: InputMaybe<Scalars['ISO8601DateTime']['input']>;
  publishedAtNil?: InputMaybe<Scalars['Boolean']['input']>;
  publishedAtNin?: InputMaybe<Array<Scalars['ISO8601DateTime']['input']>>;
  publishedAtNnil?: InputMaybe<Scalars['Boolean']['input']>;
  slug?: InputMaybe<Scalars['String']['input']>;
  slugIn?: InputMaybe<Array<Scalars['String']['input']>>;
  slugNil?: InputMaybe<Scalars['Boolean']['input']>;
  slugNin?: InputMaybe<Array<Scalars['String']['input']>>;
  slugNnil?: InputMaybe<Scalars['Boolean']['input']>;
  sortBy?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
  statusIn?: InputMaybe<Array<Scalars['String']['input']>>;
  statusNil?: InputMaybe<Scalars['Boolean']['input']>;
  statusNin?: InputMaybe<Array<Scalars['String']['input']>>;
  statusNnil?: InputMaybe<Scalars['Boolean']['input']>;
  updatedAt?: InputMaybe<Scalars['ISO8601DateTime']['input']>;
  updatedAtGt?: InputMaybe<Scalars['ISO8601DateTime']['input']>;
  updatedAtGte?: InputMaybe<Scalars['ISO8601DateTime']['input']>;
  updatedAtIn?: InputMaybe<Array<Scalars['ISO8601DateTime']['input']>>;
  updatedAtLt?: InputMaybe<Scalars['ISO8601DateTime']['input']>;
  updatedAtLte?: InputMaybe<Scalars['ISO8601DateTime']['input']>;
  updatedAtNil?: InputMaybe<Scalars['Boolean']['input']>;
  updatedAtNin?: InputMaybe<Array<Scalars['ISO8601DateTime']['input']>>;
  updatedAtNnil?: InputMaybe<Scalars['Boolean']['input']>;
};


export type QueryItemArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
  slug?: InputMaybe<Scalars['ID']['input']>;
};


export type QueryItemsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['ISO8601DateTime']['input']>;
  createdAtGt?: InputMaybe<Scalars['ISO8601DateTime']['input']>;
  createdAtGte?: InputMaybe<Scalars['ISO8601DateTime']['input']>;
  createdAtIn?: InputMaybe<Array<Scalars['ISO8601DateTime']['input']>>;
  createdAtLt?: InputMaybe<Scalars['ISO8601DateTime']['input']>;
  createdAtLte?: InputMaybe<Scalars['ISO8601DateTime']['input']>;
  createdAtNil?: InputMaybe<Scalars['Boolean']['input']>;
  createdAtNin?: InputMaybe<Array<Scalars['ISO8601DateTime']['input']>>;
  createdAtNnil?: InputMaybe<Scalars['Boolean']['input']>;
  featured?: InputMaybe<Scalars['Boolean']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  idIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  idNin?: InputMaybe<Array<Scalars['ID']['input']>>;
  itemType?: InputMaybe<Scalars['String']['input']>;
  itemTypeIn?: InputMaybe<Array<Scalars['String']['input']>>;
  itemTypeNil?: InputMaybe<Scalars['Boolean']['input']>;
  itemTypeNin?: InputMaybe<Array<Scalars['String']['input']>>;
  itemTypeNnil?: InputMaybe<Scalars['Boolean']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  limit?: Scalars['Int']['input'];
  page?: InputMaybe<Scalars['Int']['input']>;
  perPage?: InputMaybe<Scalars['Int']['input']>;
  publishedAt?: InputMaybe<Scalars['ISO8601DateTime']['input']>;
  publishedAtGt?: InputMaybe<Scalars['ISO8601DateTime']['input']>;
  publishedAtGte?: InputMaybe<Scalars['ISO8601DateTime']['input']>;
  publishedAtIn?: InputMaybe<Array<Scalars['ISO8601DateTime']['input']>>;
  publishedAtLt?: InputMaybe<Scalars['ISO8601DateTime']['input']>;
  publishedAtLte?: InputMaybe<Scalars['ISO8601DateTime']['input']>;
  publishedAtNil?: InputMaybe<Scalars['Boolean']['input']>;
  publishedAtNin?: InputMaybe<Array<Scalars['ISO8601DateTime']['input']>>;
  publishedAtNnil?: InputMaybe<Scalars['Boolean']['input']>;
  slug?: InputMaybe<Scalars['String']['input']>;
  slugIn?: InputMaybe<Array<Scalars['String']['input']>>;
  slugNil?: InputMaybe<Scalars['Boolean']['input']>;
  slugNin?: InputMaybe<Array<Scalars['String']['input']>>;
  slugNnil?: InputMaybe<Scalars['Boolean']['input']>;
  sortBy?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
  statusIn?: InputMaybe<Array<Scalars['String']['input']>>;
  statusNil?: InputMaybe<Scalars['Boolean']['input']>;
  statusNin?: InputMaybe<Array<Scalars['String']['input']>>;
  statusNnil?: InputMaybe<Scalars['Boolean']['input']>;
  updatedAt?: InputMaybe<Scalars['ISO8601DateTime']['input']>;
  updatedAtGt?: InputMaybe<Scalars['ISO8601DateTime']['input']>;
  updatedAtGte?: InputMaybe<Scalars['ISO8601DateTime']['input']>;
  updatedAtIn?: InputMaybe<Array<Scalars['ISO8601DateTime']['input']>>;
  updatedAtLt?: InputMaybe<Scalars['ISO8601DateTime']['input']>;
  updatedAtLte?: InputMaybe<Scalars['ISO8601DateTime']['input']>;
  updatedAtNil?: InputMaybe<Scalars['Boolean']['input']>;
  updatedAtNin?: InputMaybe<Array<Scalars['ISO8601DateTime']['input']>>;
  updatedAtNnil?: InputMaybe<Scalars['Boolean']['input']>;
};


export type QueryNodeArgs = {
  id: Scalars['ID']['input'];
};


export type QueryNodesArgs = {
  ids: Array<Scalars['ID']['input']>;
};


export type QueryProfileArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
  slug?: InputMaybe<Scalars['ID']['input']>;
};


export type QueryProfilesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  bannerId?: InputMaybe<Scalars['ID']['input']>;
  bannerIdIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  bannerIdNin?: InputMaybe<Array<Scalars['ID']['input']>>;
  before?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['ISO8601DateTime']['input']>;
  createdAtGt?: InputMaybe<Scalars['ISO8601DateTime']['input']>;
  createdAtGte?: InputMaybe<Scalars['ISO8601DateTime']['input']>;
  createdAtIn?: InputMaybe<Array<Scalars['ISO8601DateTime']['input']>>;
  createdAtLt?: InputMaybe<Scalars['ISO8601DateTime']['input']>;
  createdAtLte?: InputMaybe<Scalars['ISO8601DateTime']['input']>;
  createdAtNil?: InputMaybe<Scalars['Boolean']['input']>;
  createdAtNin?: InputMaybe<Array<Scalars['ISO8601DateTime']['input']>>;
  createdAtNnil?: InputMaybe<Scalars['Boolean']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  idIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  idNin?: InputMaybe<Array<Scalars['ID']['input']>>;
  last?: InputMaybe<Scalars['Int']['input']>;
  limit?: Scalars['Int']['input'];
  page?: InputMaybe<Scalars['Int']['input']>;
  perPage?: InputMaybe<Scalars['Int']['input']>;
  slug?: InputMaybe<Scalars['String']['input']>;
  slugIn?: InputMaybe<Array<Scalars['String']['input']>>;
  slugNil?: InputMaybe<Scalars['Boolean']['input']>;
  slugNin?: InputMaybe<Array<Scalars['String']['input']>>;
  slugNnil?: InputMaybe<Scalars['Boolean']['input']>;
  sortBy?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['ISO8601DateTime']['input']>;
  updatedAtGt?: InputMaybe<Scalars['ISO8601DateTime']['input']>;
  updatedAtGte?: InputMaybe<Scalars['ISO8601DateTime']['input']>;
  updatedAtIn?: InputMaybe<Array<Scalars['ISO8601DateTime']['input']>>;
  updatedAtLt?: InputMaybe<Scalars['ISO8601DateTime']['input']>;
  updatedAtLte?: InputMaybe<Scalars['ISO8601DateTime']['input']>;
  updatedAtNil?: InputMaybe<Scalars['Boolean']['input']>;
  updatedAtNin?: InputMaybe<Array<Scalars['ISO8601DateTime']['input']>>;
  updatedAtNnil?: InputMaybe<Scalars['Boolean']['input']>;
};

/** An object with an ID. */
export type Record = {
  /** The date and time that the resource was created. */
  createdAt: Scalars['ISO8601DateTime']['output'];
  /** ID of the object. */
  id: Scalars['ID']['output'];
  /** The date and time that the resource was last updated. */
  updatedAt: Scalars['ISO8601DateTime']['output'];
};

/** Autogenerated input type of SignGuestbookItemMutation */
export type SignGuestbookItemMutationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  irishWord: Scalars['String']['input'];
  itemId: Scalars['ID']['input'];
  translation: Scalars['String']['input'];
};

/** Autogenerated return type of SignGuestbookItemMutation. */
export type SignGuestbookItemMutationPayload = {
  __typename?: 'SignGuestbookItemMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: Array<MutationValidationError>;
  success: Scalars['Boolean']['output'];
};

export type StateMachineUserOnboarding = Node & Record & {
  __typename?: 'StateMachineUserOnboarding';
  /** The date and time that the resource was created. */
  createdAt: Scalars['ISO8601DateTime']['output'];
  /** ID of the object. */
  id: Scalars['ID']['output'];
  state: StateMachineUserOnboardingEnums;
  /** The date and time that the resource was last updated. */
  updatedAt: Scalars['ISO8601DateTime']['output'];
};

export enum StateMachineUserOnboardingEnums {
  AboutUs = 'ABOUT_US',
  Completed = 'COMPLETED',
  Overview = 'OVERVIEW',
  Profile = 'PROFILE'
}

export type TriggerItemActionRsvpInput = {
  email?: InputMaybe<Scalars['String']['input']>;
  itemPid: Scalars['ID']['input'];
};

/** Autogenerated input type of TriggerItemActionRsvpMutation */
export type TriggerItemActionRsvpMutationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  input: TriggerItemActionRsvpInput;
};

/** Autogenerated return type of TriggerItemActionRsvpMutation. */
export type TriggerItemActionRsvpMutationPayload = {
  __typename?: 'TriggerItemActionRsvpMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: Array<MutationValidationError>;
  success: Scalars['Boolean']['output'];
};

/** Autogenerated input type of UpdateUserProfileMutation */
export type UpdateUserProfileMutationInput = {
  accessLevel?: InputMaybe<Scalars['String']['input']>;
  bannerId?: InputMaybe<Scalars['ID']['input']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /**
   * Each profile needs to accept the terms when created before using features.
   * This is considered the main flag for a profile to considered 'active'.
   */
  communityTermsAccepted?: InputMaybe<Scalars['Boolean']['input']>;
  content?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  profileIconId?: InputMaybe<Scalars['ID']['input']>;
  shortBio?: InputMaybe<Scalars['String']['input']>;
  slug?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of UpdateUserProfileMutation. */
export type UpdateUserProfileMutationPayload = {
  __typename?: 'UpdateUserProfileMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: Array<MutationValidationError>;
  profile?: Maybe<Profile>;
  success: Scalars['Boolean']['output'];
};
