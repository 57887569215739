import React from "react"
import Tooltip from "design_system/core/components/tooltip"
import { monthFromInt, weekdayFromInt } from "design_system/libs/i18n/ga"

const DateHeader = ({
  className,
  currentDate = new Date(),
  as: Component = "p",
  // Inline displays text next to each other otherwise we show as a tooltip
  inline = true,
}) => {
  const weekdayTranslations = weekdayFromInt(currentDate.getDay())

  if (inline) {
    return (
      <Component className={className}>
        <strong>
          {weekdayTranslations.irish}, {currentDate.getDate()}{" "}
          {monthFromInt(currentDate.getMonth()).irish}
        </strong>{" "}
        &middot;{" "}
        <em>
          {weekdayTranslations.english}, {currentDate.getDate()}{" "}
          {monthFromInt(currentDate.getMonth()).english}
        </em>
      </Component>
    )
  }

  return (
    <Component className={className}>
      <Tooltip
        title={`${weekdayTranslations.english}, ${currentDate.getDate()} ${
          monthFromInt(currentDate.getMonth()).english
        }`}
      >
        <span className="text-definition !font-normal">
          {weekdayTranslations.irish}, {currentDate.getDate()}{" "}
          {monthFromInt(currentDate.getMonth()).irish}
        </span>
      </Tooltip>
    </Component>
  )
}

export default DateHeader
