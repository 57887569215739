import React from "react"

import Title from "design_system/core/components/title"
import PageBreadcrumb from "design_system/application/components/page_breadcrumb"
import { Item } from "design_system/utils/types/item"
import Content from "design_system/core/components/content"
import InterviewOverview from "design_system/application/components/interview_overview"
import InterviewQuestionList from "design_system/application/components/interview_question_list"
import formatter from "design_system/utils/formatter"

import Button from "@love-irish/core-button"
import "./index.css"
import {
  IconBookmark,
  IconHeart,
  IconMoodHappy,
  IconMoodSmile,
  IconPlayerPlay,
  IconPlus,
} from "@tabler/icons-react"
import AddToCollection from "@love-irish/resource-add-to-collection"
import AdventureBanner from "@love-irish/adventure-banner-component"
import ResourceListItem, {
  addDynamicTags,
} from "@love-irish/resource-list-item"
import useSound from "use-sound"
import Tooltip from "design_system/core/components/tooltip"
import tadhgAvatar from "images/profile_icons/tadhg.png"

interface ResourceItemTemplateProps {
  item: Item
  currentUser?: any
}

const ResourceItemTemplate = ({
  item,
  currentUser,
}: ResourceItemTemplateProps) => {
  const [play] = useSound(item.audioUrl || "")

  const handlePlay = (e) => {
    e.stopPropagation()
    play()
  }

  return (
    <div>
      <div className="container mt-8">
        <PageBreadcrumb pages={[{ name: "Codex", href: "/codex" }]} />
      </div>
      {!!item.banner && (
        <div className="mt-8">
          <AdventureBanner banner={item.banner} />
        </div>
      )}

      <div className="container mt-8">
        <div className="mt-8 break-words">
          <Title size="lg" title={item.name} />
        </div>

        <div className="grid gap-12 grid-cols-1 sm:grid-cols-12 sm:mt-12 print:grid-cols-1">
          <div className="col-span-2 sm:mt-24">
            <div className="sticky top-10">
              <div className="text-secondary-600">
                <div className="mt-4 text-xs">
                  Created{" "}
                  {item.owner && (
                    <>
                      by{" "}
                      <strong>
                        <a href={item.owner.path}>{item.owner.username}</a>
                      </strong>{" "}
                    </>
                  )}
                  on{" "}
                  <time className="font-bold">
                    {formatter.shortDate(item.publishedAt || item.createdAt)}
                  </time>
                </div>
                <div className="mt-2 text-xs">
                  Last updated on{" "}
                  <time className="font-bold">
                    {formatter.shortDate(item.updatedAt)}
                  </time>
                </div>
              </div>

              <div className="">
                <hr className="w-[50px] mt-4 mb-4" />
                {item.audioUrl && (
                  <Tooltip title="Play featured community recording">
                    <Button size="sm" variant="ghost" onClick={handlePlay}>
                      <IconPlayerPlay size={16} className="inline-block" />
                    </Button>
                  </Tooltip>
                )}
                <AddToCollection
                  itemId={item.gid}
                  currentUser={currentUser}
                  align="start"
                />
                <hr className="w-[50px] mt-4 mb-4" />
                <h3 className="text-xs text-secondary-600">Jump to section:</h3>
                <ul className="mt-2">
                  <li>
                    <a href="#intro">Overview</a>
                  </li>
                  <li>
                    <a href="#items">Items</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div className="col-span-10">
            {item.imageUrl && (
              <div className="w-[128px] aspect-square pixel-art mb-4 shrink-0 block md:hidden">
                <img src={item.imageUrl} className="w-full block" />
              </div>
            )}

            <div id="intro">
              {!!(item.content || item.summary) && (
                <div className="grid grid-cols-4 gap-8 mb-8">
                  <div className="col-span-3">
                    <Content
                      content={item.content || item.summary}
                      dropcap={"md"}
                      className="!max-w-full prose-headings:!text-sm"
                    />
                    {item.translation && (
                      <div className="mt-8">
                        <p className="text-xs text-secondary uppercase mb-2">
                          Translation:
                        </p>
                        <Content
                          content={addDynamicTags(item.translation)}
                          className="prose-sm text-inherit text-sm text-dark dark:text-white !font-mono first-line:not-italic"
                        />
                      </div>
                    )}

                    {item.pronunciation && (
                      <div className="mt-4">
                        <p className="text-xs text-secondary uppercase mb-2">
                          Rough pronunciation for English speakers:
                        </p>
                        <Content
                          content={item.pronunciation}
                          className="prose-sm text-inherit text-sm text-dark dark:text-white !font-mono first-line:not-italic"
                        />
                      </div>
                    )}

                    {item.audioUrl && (
                      <div className="my-8">
                        <div className="flex justify-start gap-2 bg-earth-50/70 text-earth-800 p-2 rounded border border-earth-200 items-center">
                          <img
                            src={tadhgAvatar}
                            className="w-[32px] pixel-art"
                          />
                          <button
                            aria-label="Play audio"
                            className="!bg-none cursor-pointer"
                            onClick={handlePlay}
                            type="button"
                          >
                            <IconPlayerPlay
                              size={24}
                              className="inline-block"
                            />
                          </button>
                          <p>
                            Featured community recording from{" "}
                            <a href="/@tadhg" className="font-bold">
                              @tadhg
                            </a>
                          </p>
                        </div>
                      </div>
                    )}
                  </div>

                  {item.imageUrl && (
                    <div className="w-[256px] aspect-square pixel-art mt-4 shrink-0 hidden md:block">
                      <img src={item.imageUrl} className="w-full block" />
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ResourceItemTemplate
