import React from "react"

import PageBreadcrumb from "design_system/application/components/page_breadcrumb"
import stoneImage from "images/love_irish/adventure/objects/ogham-stone.png"
import Form from "./form"
import Signature from "./signature"
import classNames from "classnames"
import Content from "design_system/core/components/content"
import formatter from "design_system/utils/formatter"

const sectionMenus = {
  resources: [{ href: "/calendar", name: "Calendar" }],
  community: [{ href: "/community", name: "Community" }],
  adventure: [
    { href: "/adventure", name: "Adventure" },
    { href: "/adventure/ogham-stones", name: "Ogham stones" },
  ],
}

export const TemplateComponent = ({
  itemId,
  profileSlug,
  signatures,
  signed,
  item,
  currentSection,
  breadcrumbPages = [],
}) => {
  let label

  if (item.startsAt && item.endsAt) {
    label = (
      <strong>
        This ogham stone can only be signed between{" "}
        {formatter.shortDate(item.startsAt)}–{formatter.shortDate(item.endsAt)}.{" "}
      </strong>
    )
  }
  const menu = sectionMenus[currentSection] || sectionMenus.adventure

  if (menu && breadcrumbPages.length > 0) {
    menu.push(...breadcrumbPages)
  }

  // const label =
  // {item.endsAt && (
  //   <strong>
  //     This ogham stone can be signed until{" "}
  //     {formatter.shortDate(item.endsAt)}
  //   </strong>
  // )}
  // {item.startsAt && (
  //   <strong>
  //     This ogham stone can be signed after{" "}
  //     {formatter.shortDate(item.startsAt)}
  //   </strong>
  // )}

  return (
    <div className="container pt-8">
      {menu && <PageBreadcrumb pages={menu} />}

      <div className="grid md:grid-cols-2 mt-24">
        <div>
          <img
            src={stoneImage}
            alt="Ogham Stone"
            className="max-w-[240px] md:max-w-full w-full"
          />
        </div>
        <div className="mt-8">
          <h1 className="text-6xl font-serif mb-4">{item.name}</h1>
          <Content content={item.content} />
          <p className="bg-warning-100 p-2 mt-8">
            {label}
            Signatures are permanent and cannot be changed once carved into the
            stone.
          </p>

          <div
            className={classNames("mt-8 bg-background py-4 border-b", {
              "sticky -top-1": profileSlug && !signed,
            })}
          >
            <h2 className="font-bold mb-2 text-lg">Sign the ogham stone</h2>
            <p>
              Choose a word from your codex to carve into the stone. This cannot
              be changed so choose carefully.
            </p>
            <Form itemId={itemId} profileSlug={profileSlug} signed={signed} />
          </div>

          <div>
            <h2 className="font-bold mt-8 mb-2 text-lg">
              Signatures ({signatures.length})
            </h2>
            <p>Ogham is read from bottom-to-top or left-to-right</p>
            <div className="mt-8 divide-y">
              {signatures.map((signature) => (
                <div key={signature.id} className="pb-6">
                  <Signature signature={signature} />
                </div>
              ))}

              {signatures.length === 0 && (
                <div className="mt-8 text-center text-lg">
                  No signatures yet. Be the first to sign!
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default TemplateComponent
