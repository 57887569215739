"use client"

import React, { Fragment } from "react"
import classNames from "classnames"
import { Avatar, Dropdown, Navbar } from "flowbite-react"

import logo from "images/brand/logos/wordmark.svg"
import logoDarkMode from "images/brand/logos/wordmark-white.svg"
import UserAvatar from "./user_avatar"
import { ConnectedComponent as CurrentAppVersion } from "design_system/application/components/current_app_version"
import AdventureMenu, { navigation as adventureNav } from "./adventure_menu"
import ResourcesMenu, { navigation as resourcesNav } from "./resources_menu"
import "./index.css"
import { GraphqlProvider } from "design_system/utils/graphql_client"
import { IconExternalLink, IconMenu } from "@tabler/icons-react"
import { Toaster } from "@love-irish/ui-components/sonner"

const navigation = [
  // { name: "Interviews", href: "/interviews", translated: "Agallaimh" },
  // { name: "Blog", href: "/blog", translated: "Blag" },

  // { name: "Irish Language Week", href: '/', translated: "Seachtain na Gaeilge" },
  // { name: "About us", href: "/about-us", translated: "Fúinn" },
  // { name: "Library", href: routes.libraryPath(), translated: "leabharlann" },
  // { name: "Events", href: routes.eventsPath(), translated: "cás" },
  // { name: "Pub", href: routes.pubPath(), translated: "teach óil" },
  {
    name: "Community",
    href: "/community",
    translated: "Pobal",
    section: "community",
  },
  {
    name: "Resources",
    href: "/resources",
    translated: "Seachaidh",
    section: "resources",
  },
  {
    name: "Adventure",
    href: "/adventure",
    translated: "Eachtra",
    section: "adventure",
  },
  {
    name: "Login",
    href: "/login",
    translated: "Logáil isteach",
    className: "block sm:hidden",
  },
  // {
  //   name: "Shop",
  //   href: "https://shop.love.irish",
  //   translated: "Síopa",
  //   target: "_blank",
  //   rel: "noopener noreferrer",
  // },
  // { name: "More", href: "https://shop.love.irish", translated: "Níos mo" },
]

const sections = {
  community: null,
  resources: ResourcesMenu,
  adventure: AdventureMenu,
}

export default ({ currentUser, currentPath = "/", currentSection }) => {
  const handleLinkClick = ({ label, href, section }) => null
  // analytics.trackLinkClick({ label, href, location: ["header", section] })
  const SubMenu = sections[currentSection] || Fragment

  return (
    <>
      <header className="app-header">
        <div className="container">
          <Navbar fluid rounded className="py-3 !px-0 !bg-transparent">
            <Navbar.Brand as="div">
              <a href="/">
                <img
                  className="h-8 w-auto dark:hidden"
                  src={logo}
                  alt="Love.irish logo"
                />
                <img
                  className="h-8 w-auto hidden dark:!block"
                  src={logoDarkMode}
                  alt="Love.irish dark mode logo"
                />
              </a>
              <div className="inline-block self-start">
                <CurrentAppVersion />
              </div>
            </Navbar.Brand>
            <div className="flex md:order-2 gap-2">
              <UserAvatar currentUser={currentUser} />
              <Navbar.Toggle barIcon={IconMenu} />
            </div>
            <Navbar.Collapse className="justify-start">
              {navigation.map(
                ({ name, className, translated, href, section, ...rest }) => {
                  const isActive = currentSection === section

                  return (
                    <Navbar.Link
                      key={name}
                      href={href}
                      onClick={() =>
                        handleLinkClick({
                          label: name,
                          section: "main_nav",
                          href,
                        })
                      }
                      className={classNames(
                        "app-nav-link border-0",
                        {
                          active: isActive,
                        },
                        className
                      )}
                      {...rest}
                    >
                      <span>
                        <span
                          className={classNames(
                            "text-md block text-foreground",
                            {
                              "font-extrabold": isActive,
                            }
                          )}
                        >
                          {name}{" "}
                          {href?.includes("http") && (
                            <IconExternalLink
                              className="inline align-top text-muted-foreground"
                              size={12}
                            />
                          )}
                        </span>
                        <em className="text-xs app-link-subtitle">
                          {translated}
                        </em>
                      </span>
                    </Navbar.Link>
                  )
                }
              )}
            </Navbar.Collapse>
          </Navbar>
        </div>
      </header>
      <SubMenu />
      <Toaster position="top-right" offset={{ top: "90px" }} />
    </>
  )
}
