import { faker } from "@faker-js/faker"

import { Item } from "design_system/utils/types/item"

import isMiseTadhg from "./items/is-mise-tadhg-na-bogach.mp3"
import { banners } from "design_system/utils/sample_data/profile"

let currentId = 0

export function generateItem(attributes = {}): Item {
  currentId++
  const slug = faker.internet.userName().toLowerCase()

  return {
    id: currentId,
    gid: `ITEM${currentId}`,
    createdAt: new Date().toUTCString(),
    updatedAt: new Date().toUTCString(),
    slug,
    path: `/items/${slug}`,
    owner: {
      slug: "grug",
    },
    ...attributes,
  }
}

export function generateFullItem(attributes = {}): Item {
  return generateItem({
    name: faker.lorem.sentence(),
    summary: faker.lorem.paragraphs(faker.number.int({ max: 3 }), "\n\n"),
    content: faker.lorem.paragraphs(faker.number.int({ max: 10 }), "\n\n"),
    createdAt: faker.date.past().toUTCString(),
    updatedAt: faker.date.past().toUTCString(),
    publishedAt: faker.date.past().toUTCString(),
    ...attributes,
  })
}

export function generateChangelogItem(attributes = {}): Item {
  return generateItem({
    name: faker.lorem.sentence(),
    slug: `v${faker.number.int({ max: 100 })}.${faker.number.int({
      max: 100,
    })}.${faker.number.int({ max: 100 })}`,
    summary: faker.lorem.paragraphs(2, "\n\n"),
    content: faker.lorem.paragraphs(10, "\n\n"),

    createdAt: faker.date.past().toUTCString(),
    updatedAt: faker.date.past().toUTCString(),
    publishedAt: faker.date.past().toUTCString(),
    ...attributes,
  })
}

const codexRecords = [
  {
    name: "Is mise Tadhg na bogach",
    slug: "is-mise-tadhg-na-bogach",
    translation: "My name is Tadhg of the marsh",
    pronunciation: "Is mish-uh Tie-geh nuh bow-ga-hh",
    audioUrl: isMiseTadhg,
  },
  {
    name: "Dia dhuit",
    slug: "dia-dhuit",
    translation: "Hello",
    pronunciation: "Dee-uh gwitch",
  },
  {
    name: "Dia is Muire dhuit",
    slug: "dia-is-muire-dhuit",
    translation: "Hello to you",
    pronunciation: "Dee-uh is Mwir-uh gwitch",
  },
  {
    name: "Go raibh maith agat",
    slug: "go-raibh-maith-agat",
    translation: "Thank you",
    pronunciation: "Guh rev mah uh-gut",
  },
  {
    name: "Slán",
    slug: "slán",
    translation: "Goodbye",
    pronunciation: "Slawn",
  },
]

export function generateCodexRecord(attributes = {}): Item {
  const record =
    codexRecords[faker.number.int({ max: codexRecords.length - 1 })]

  return generateItem({
    type: "Item::CodexRecord",
    summary: faker.lorem.paragraph(),
    content: faker.lorem.paragraphs(10, "\n\n"),
    createdAt: faker.date.past().toUTCString(),
    updatedAt: faker.date.past().toUTCString(),
    publishedAt: faker.date.past().toUTCString(),
    ...record,
    path: `/codex/${record.slug}`,
    ...attributes,
  })
}
