import React from "react"
import Tooltip from "design_system/core/components/tooltip"
import { monthFromInt } from "design_system/libs/i18n/ga"

const GreetingHeader = ({
  inline = true,
  className,
  currentUser = {},
  date = new Date(),
}) => {
  const currentHour = date.getHours()

  const isMorning = currentHour < 12
  const isDay = currentHour >= 12 && currentHour < 18
  const isNight = currentHour >= 18
  let currentGreeting

  const greetings = {
    morning: {
      irish: "Maidin mhaith",
      emoji: "☕️",
      english: "Good morning",
    },
    day: {
      irish: "Tráthnóna maith",
      emoji: "☀️",
      english: "Good afternoon",
    },
    night: {
      irish: "Oíche mhaith",
      emoji: "🌙",
      english: "Good night",
    },
    irishWeek: {
      irish: "Seachtain na Gaeilge sona duit",
      emoji: "☘️",
      english: "Happy Irish week (3/1–3/17) to you",
      slug: "/codex/seachtain-na-gaeilge-sona-duit",
    },
    halloween: {
      irish: "Oíche Shamhna shona duit",
      emoji: "🎃",
      english: "Happy Halloween",
    },
  }

  if (isMorning) {
    currentGreeting = greetings.morning
  } else if (isDay) {
    currentGreeting = greetings.day
  } else {
    currentGreeting = greetings.night
  }

  if (date.getMonth() === 2 && date.getDate() <= 18) {
    currentGreeting = greetings.irishWeek
  }

  if (date.getMonth() === 9 && date.getDate() === 31) {
    currentGreeting = greetings.halloween
  }

  // check if it is the user's birthday month or day

  if (currentUser.birthday) {
    var birthday = new Date(currentUser.birthday)

    if (
      birthday.getDate() === date.getDate() &&
      birthday.getMonth() === date.getMonth()
    ) {
      currentGreeting = {
        irish: "Lá breithe shona duit",
        emoji: "🎂",
        english: "Happy birthday",
      }
    } else if (birthday.getMonth() === date.getMonth()) {
      currentGreeting = {
        irish: "Breithlá mí sona duit",
        emoji: "🎂",
        english: "Happy birthday month!",
      }
    }
  }

  const linkOrText = currentGreeting.slug ? (
    <a href={currentGreeting.slug} className="text-definition !font-bold">
      {currentGreeting.irish}
    </a>
  ) : (
    <>{currentGreeting.irish}</>
  )

  if (inline) {
    return (
      <div className={className}>
        <div>
          {currentGreeting.emoji}{" "}
          <strong>
            {linkOrText},{" "}
            {currentUser.slug ? `@${currentUser.slug}` : "a chara"}
          </strong>{" "}
          &middot;{" "}
          <em>
            {currentGreeting.english},{" "}
            {currentUser.slug ? `@${currentUser.slug}` : "my friend"}
          </em>
        </div>
      </div>
    )
  }

  return (
    <div className={className}>
      <Tooltip
        title={`${currentGreeting.english}${
          currentUser.slug ? `, @${currentUser.slug}` : ", my friend"
        }`}
      >
        <div>
          {currentGreeting.emoji}{" "}
          <span className="text-definition">
            {currentGreeting.irish},{" "}
            {currentUser.slug ? `@${currentUser.slug}` : "a chara"}
          </span>
        </div>
      </Tooltip>
    </div>
  )
}

export default GreetingHeader
