import React from "react"
import classNames from "classnames"
import routes from "design_system/utils/routes"

const themes = {
  orange: "group-hover:border-orange-400",
  default: "group-hover:border-earth-400",
}

export const AdventureBanner = ({ banner }) => {
  const formattedUsername = banner?.artistUrl
    ? banner?.artistUsername
    : `@${banner?.artistUsername}`
  return (
    <header className={classNames("max-w-banner md:mx-auto", banner && "")}>
      <div className="relative group">
        <div className="absolute bottom-2 md:bottom-5 w-full">
          <div className="container md:!p-0">
            <div className="">
              <div className="grid md:grid-cols-12 gap-6 items-end">
                <div className="col-start-5 col-span-5">
                  {/*  music player goes here */}
                </div>
                {banner && (
                  <div
                    className={`col-span-12 opacity-80 md:opacity-50 group-hover:opacity-100 md:col-span-3 lg:col-span-2 border-2 border-solid border-secondary-300/25 p-3 bg-dark/20 ${
                      themes[banner.theme] || themes.default
                    } transition-colors duration-100 group-hover:bg-dark/90`}
                  >
                    <a
                      className="text-white font-bold block text-sm"
                      href={`/adventure/rewards/banners/${banner.slug}`}
                    >
                      {banner.name}
                    </a>
                    <p className="text-secondary-200 text-xs">
                      Banner art by{" "}
                      <a
                        className="text-white transition-colors duration-100 font-bold"
                        href={
                          banner.artistUrl ||
                          routes.profilePath(banner.artistUsername)
                        }
                      >
                        {formattedUsername}
                      </a>
                    </p>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        {banner ? (
          <div className="">
            <img
              src={banner.imageUrl}
              className="pixel-art w-full"
              alt={`Artwork for the '${banner.name}' banner made by ${formattedUsername}`}
            />
          </div>
        ) : (
          <div className="h-[100px] sm:h-[200px] border bg-secondary-50 border-secondary-200"></div>
        )}
      </div>
    </header>
  )
}

export default AdventureBanner
