import React from "react"
import fetch from "cross-fetch"
import { schema } from "@love-irish/graphql-schema"
import {
  InMemoryCache,
  ApolloClient,
  ApolloProvider,
  HttpLink,
} from "@apollo/client"

const isServer = typeof window === "undefined"
const csrfToken = () => document.querySelector("[name='csrf-token']").content
export const client = new ApolloClient({
  cache: new InMemoryCache(),
  link: new HttpLink({
    fetch,
    credentials: "include",
    headers: {
      "X-CSRF-Token": !isServer && csrfToken(),
      "Content-Type": "application/json",
    },
  }),
  ssrMode: isServer,
  typeDefs: schema,
})

export const GraphqlProvider = ({ children }) => {
  return <ApolloProvider client={client}>{children}</ApolloProvider>
}
