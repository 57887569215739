import React from "react"
import PageBreadcrumb from "design_system/application/components/page_breadcrumb"
import Title from "design_system/core/components/title"
import Content from "design_system/core/components/content"
import CommunityLofiProfileComponent from "@love-irish/community-lofi-profile-component"

export const Template = ({ banners }) => {
  const [currentPreview, setCurrentPreview] = React.useState("")

  return (
    <div className="container mt-8">
      <PageBreadcrumb
        pages={[
          { href: "/adventure", name: "Adventure" },
          { href: "/rewards", name: "Rewards" },
        ]}
      />
      <div className="mt-8 mb-8">
        <Title title="Banners" />

        <div className="grid md:grid-cols-2 mt-4">
          <Content content="Banners can be used throughout the site like on your profile, collection or item pages and are found by completing quests and tasks or as loot drops like when playing our games." />
        </div>
      </div>
      <div className="grid md:grid-cols-3 gap-8 mt-16">
        <div className="space-y-16 order-2 md:order-none">
          {banners.map((banner, index) => (
            <div key={index} className="mb-8">
              <img
                src={banner.imageUrl}
                alt={banner.title}
                className="cursor-pointer"
                onClick={() => setCurrentPreview(banner.imageUrl)}
              />
              <Title
                title={banner.name}
                size="sm"
                className="mt-3"
                href={`/adventure/rewards/banners/${banner.slug}`}
              />
              <p className="text-sm mt-1">
                Art by{" "}
                {banner.artistUrl || (
                  <a href={`/@${banner.artistUsername}`}>
                    @{banner.artistUsername}
                  </a>
                )}
              </p>
            </div>
          ))}
        </div>

        <div className="md:col-span-2 order-1 md:order-none">
          <div className="sticky top-4">
            <CommunityLofiProfileComponent
              className="border-secondary-200"
              banner={currentPreview}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default Template
