import React from "react"
import { useQuery } from "@apollo/client"

import Tooltip from "design_system/core/components/tooltip"
import TimeAgo from "react-timeago"
import query from "./current_app_version.graphql"
import { IconLoader2 } from "@tabler/icons-react"
const CurrentAppVersion = ({ slug, publishedAt, name }) => {
  return (
    <Tooltip
      title={
        <span className="font-mono">
          <span className="text-accent-400 text-xs font-bold">{slug}</span>{" "}
          {name}
          <span className="text-xs text-secondary italic block mt-1">
            released <TimeAgo date={publishedAt} />
          </span>
        </span>
      }
      placement="bottom"
    >
      <a
        className="rounded bg-muted align-super inline-block font-mono px-2 py-1 text-2xs ml-2 self-start"
        href={`/changelog/${slug}`}
      >
        <strong>{slug}</strong>
      </a>
    </Tooltip>
  )
}

export const ConnectedComponent = () => {
  const { loading, error, data } = useQuery(query)

  if (loading) {
    return (
      <span className="rounded bg-muted align-super inline-block font-mono px-2 py-1 text-2xs ml-2 self-start">
        <IconLoader2 size={12} className="animate-spin" />
      </span>
    )
  }

  if (error || !data.currentAppVersion) return null

  return <CurrentAppVersion {...data.currentAppVersion} />
}

export default CurrentAppVersion
