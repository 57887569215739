import React from "react"

import Button from "@love-irish/core-button"
import oghamStone from "images/love_irish/adventure/objects/ogham-stone.png"
import styles from "./stone.module.css"
import classNames from "classnames"
const OghamStoneBanner = () => (
  <div
    className={classNames(
      "sm:flex bg-background border-2 shadow-xl rounded-2xl border-dark-500 p-2",
      styles.container
    )}
  >
    <img src={oghamStone} className={styles.stone} />
    <div className="flex flex-col justify-center p-8">
      <h2 className="text-4xl font-serif">Sign the ogham stone</h2>
      <p className="mt-4 mb-8 max-w-xl text-lg">
        Special events on the site have guestbook-style ogham stones which can
        be signed by anyone in the community by selecting a word or phrase to
        carve into it. <em>Sometimes magic happens when you do.</em>
      </p>

      <div>
        <Button size="lg" as="a" href="/calendar/irish-week/guestbook">
          Sign it &rarr;
        </Button>
      </div>
    </div>
  </div>
)

export default OghamStoneBanner
