import React from "react"

import SpriteAnimator from "design_system/core/hooks/use_sprite_animation/component"
import pixie from "design_system/adventure/codex/characters/pixie"
import themeSong from "./theme-song.mp3"

import banner from "images/profile_banners/landscape_california_mt_diablo@2x.png"
// import banner from "images/profile_banners/landscape_halloween.png"
import {
  IconBook2,
  IconSparkles,
  IconDeviceGamepad,
  IconDoor,
  IconHeart,
  IconSword,
} from "@tabler/icons-react"
import Typed from "react-typed"

const Hero = () => (
  <div
    style={{
      background: `linear-gradient(0deg, rgb(0 0 0 / 70%), rgb(0 0 0 / 65%)), url(${banner})`,
      backgroundSize: "cover",
      backgroundPosition: "bottom center",
      backgroundOrigin: "content-box",
      imageRendering: "pixelated",
      backgroundRepeat: "no-repeat",
      backgroundAttachment: "fixed",
    }}
    className="pixel-art"
  >
    <div className="container grid md:grid-cols-12 gap-10 items-end py-56">
      <header className="col-span-8">
        <div className="align-middle text-secondary-400 uppercase mb-8">
          Coming soon
        </div>
        <h1 className="text-8xl md:text-9xl font-mono-header font-medium leading-[0.85]">
          <div className="text-6xl md:text-7xl align-middle text-secondary-300">
            Chat-based
          </div>
          <div className="text-6xl md:text-7xl align-middle text-earth-400">
            Irish language
          </div>
          <div className="align-middle text-white mt-3">
            <Typed
              strings={[
                "Adventure",
                "Dungeon crawler",
                "Creature collector",
                "Trading card",
              ]}
              typeSpeed={40}
              backSpeed={50}
              backDelay={1500}
              loop
            />
          </div>
          <div className="mt-3">
            <span className="align-middle text-white">RPG</span>{" "}
            <SpriteAnimator
              className="inline-block"
              sprite={pixie.sprite}
              width={16}
              height={16}
              frameCount={4}
              shouldAnimate
              scale={4}
              fps={8}
              {...(pixie.spriteSettings || {})}
            />
          </div>
        </h1>

        <div className="grid grid-cols-6 gap-3 text-white divide-dark-500/40 divide-x text-sm mt-10 bg-black/30">
          <div className="pl-3 pb-4 pt-6 text-center">
            <IconDoor size={32} className="inline mb-3 text-warning-400" />
            <h2>Explore dungeons &amp; stories</h2>
          </div>
          <div className="pl-3 pb-4 pt-6 text-center">
            <IconSword size={32} className="inline mb-3 text-warning-400" />
            <h2>Discover loot &amp; rewards</h2>
          </div>
          <div className="pl-3 pb-4 pt-6 text-center">
            <IconBook2 size={32} className="inline mb-3 text-warning-400" />
            <h2>Level-up words &amp; phrases</h2>
          </div>
          <div className="pl-3 pb-4 pt-6 text-center">
            <IconDeviceGamepad
              size={32}
              className="inline mb-3 text-warning-400"
            />
            <h2>Play minigames</h2>
          </div>
          <div className="pl-3 pb-4 pt-6 text-center">
            <IconSparkles size={32} className="inline mb-3 text-warning-400" />
            <h2>Cast spells</h2>
          </div>

          <div className="pb-4 pt-6 text-center">
            <IconHeart size={32} className="inline mb-3 text-warning-400" />
            <h2>Make friends &amp; defeat foes</h2>
          </div>
        </div>
      </header>
      <div className="col-span-4">
        <div className="card">
          <div>
            <h2 className="font-bold">Season 1</h2>
            <h1 className="text-5xl font-mono-header mt-1">Reawakening</h1>
            <p className="italic text-sm">
              A power long dormant has been unleshed back into the world.
              Discover the secrets of the ancient Irish language and unlock the
              power of the seasons.
            </p>

            <div className="grid gap-2 divide-x text-center grid-flow-col mt-8 mb-4">
              <div className="grid gap-1">
                <div className="text-xs">Rewards</div>
                <h3 className="text-xl font-mono">5/10</h3>
              </div>
              <div className="grid gap-1">
                <div className="text-xs">Campaign</div>
                <h3 className="text-xl font-mono">40%</h3>
              </div>
              <div className="grid gap-1">
                <div className="text-xs">EXP earned</div>
                <h3 className="text-xl font-mono">192</h3>
              </div>
            </div>
          </div>
          <div className="mt-10 text-center">
            <h2 className="font-bold">Season theme song 🎶</h2>
            <p className="px-10 text-sm">
              Can be used as a profile theme song after completing season
              campaign.{" "}
              <a href="" className="font-bold underline text-secondary-400">
                Learn more
              </a>
            </p>
            <audio src={themeSong} controls className="mx-auto mt-4" />
          </div>
        </div>
      </div>
    </div>
  </div>
)

export default Hero
