import React from "react"

import Button from "@love-irish/core-button"
import { StateMachineUserOnboardingEnums } from "@love-irish/types"
import routes from "design_system/utils/routes"
export default function Steps({ currentStep = 1, completedStep = 2 }) {
  const stepNames = [
    StateMachineUserOnboardingEnums.Profile,
    StateMachineUserOnboardingEnums.Overview,
    StateMachineUserOnboardingEnums.AboutUs,
    StateMachineUserOnboardingEnums.Completed,
  ]
  console.log(completedStep, currentStep)
  const currentStepIndex = stepNames.indexOf(currentStep) || 0
  const completedStepIndex = stepNames.indexOf(completedStep) || 0

  return (
    <nav aria-label="Progress" className="flex items-center justify-center">
      <ol role="list" className="flex items-center space-x-5">
        {[
          routes.onboardingPath(),
          routes.onboardingOverviewStepPath(),
          routes.onboardingAboutUsStepPath(),
          routes.onboardingCompletedPath(),
        ].map((href, index) => {
          let color = "secondary"
          let variant = "outline"

          if (index < currentStepIndex) {
            color = "success"
            variant = "solid"
          } else if (index === currentStepIndex) {
            color = "primary"
            variant = "solid"
          }

          return (
            <li key={index} aria-current={index === currentStepIndex}>
              <Button
                className="block py-1"
                variant={variant}
                color={color}
                as={index > completedStepIndex ? "button" : "a"}
                href={href}
                disabled={index > completedStepIndex}
              >
                <span className="sr-only">Step {index + 1}</span>
              </Button>
            </li>
          )
        })}
      </ol>
    </nav>
  )
}
