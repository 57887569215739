import React from "react"
import type { schema } from "@love-irish/graphql-schema"
import PageBreadcrumb from "design_system/application/components/page_breadcrumb"
import Title from "design_system/core/components/title"
import Badge from "design_system/core/components/badge"
import Content from "design_system/core/components/content"
import LofiImage from "design_system/core/components/lo_fi/image"

type Topic = schema["Topic"]
type TemplateProps = {
  topics: Topic[]
}

export const Template = ({ topics }: TemplateProps) => (
  <div className="container mt-8">
    <PageBreadcrumb pages={[{ name: "Resources", href: "/resources" }]} />
    <div className="my-8">
      <Title title="Topics" size="lg" />
      <div className="mt-3">Browse popular topics on Love.irish</div>
    </div>
    <div className="grid grid-cols-5 mt-16">
      <div className="col-span-3">
        <h2 className="mb-2 font-bold text-lg">All featured topics</h2>
        <ul className="space-y-6 divide-y">
          {topics.map((topic) => (
            <li key={topic.id} className="pt-6 flex gap-6">
              <a href={`/topics/${topic.slug}`}>
                <LofiImage className="w-[64px] border bg-background" />
              </a>

              <div>
                <Title
                  title={topic.name}
                  href={`/topics/${topic.slug}`}
                  size="sm"
                />
                {topic.irishName && (
                  <div className="text-sm mt-1">{topic.irishName}</div>
                )}
                <Content
                  content={topic.description}
                  className="mt-1 line-clamp-3"
                />

                <div className="mt-4 text-sm">
                  <a href={`/topics/${topic.slug}`}>
                    View everything matching <strong>#{topic.slug}</strong>{" "}
                    &rarr;
                  </a>
                </div>
              </div>
            </li>
          ))}
        </ul>
      </div>

      <div className="col-span-2">
        <div>Popular topics</div>

        {topics.map((topic) => (
          <Badge key={topic.id} variant="outline">
            #{topic.slug}
          </Badge>
        ))}
      </div>
    </div>
  </div>
)

export default Template
