import { HiHeart } from "react-icons/hi2"
import Typed from "react-typed"
import React from "react"

import CodexSection from "./codex_section"
import InterviewSection from "./interview_section"
import IrishWeekSection from "./irish_week_section"

export default function AppHomepageTemplate({ featuredPost }) {
  return (
    <div className="overflow-hidden py-16 lg:py-24">
      <div className="relative mx-auto max-w-xl px-6 lg:max-w-7xl lg:px-8">
        <svg
          className="absolute right-full hidden translate-x-1/2 -translate-y-1/4 transform lg:block"
          width={404}
          height={784}
          fill="none"
          viewBox="0 0 404 784"
          aria-hidden="true"
        >
          <defs>
            <pattern
              id="b1e6e422-73f8-40a6-b5d9-c8586e37e0e7"
              x={0}
              y={0}
              width={20}
              height={20}
              patternUnits="userSpaceOnUse"
            >
              <rect
                x={0}
                y={0}
                width={4}
                height={4}
                className="text-gray-200"
                fill="currentColor"
              />
            </pattern>
          </defs>
          <rect
            width={404}
            height={784}
            fill="url(#b1e6e422-73f8-40a6-b5d9-c8586e37e0e7)"
          />
        </svg>

        <div className="relative z-10">
          <h2 className="text-center font-normal leading-8 tracking-tight text-gray-900 text-xl lg:text-4xl font-fancy min-h-[2.5em] lg:min-h-fit">
            For those interested in Irish #
            <Typed
              strings={[
                "language",
                "mythology",
                "games",
                "music",
                "books",
                "food",
                "events",
                "movies",
                "culture",
                "history",
                "literature",
                "art",
              ]}
              loop
              typeSpeed={40}
              backSpeed={50}
              backDelay={1500}
            />
          </h2>
          <p className="mx-auto mt-4 max-w-3xl text-center text-base md:text-lg text-gray-500">
            This site is run by and for fans and learners &mdash; corrections
            welcome <HiHeart className="text-error inline" />
          </p>
        </div>
        <IrishWeekSection />
        {/*<InterviewSection featuredPost={featuredPost} />*/}
        {/*<CodexSection />*/}
      </div>
    </div>
  )
}
