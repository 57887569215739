import React, { useState, useEffect } from "react"
import { Routes, Route } from "react-router"

import ProfileOnboardingPage from "./pages/profile"
import OverviewOnboardingPage from "./pages/overview"
import GetStartedOnboardingPage from "./pages/completed"
import AboutUsOnboardingPage from "./pages/about"
import routes from "design_system/utils/routes"

export const Template = ({
  profile,
  currentStep: pageCurrentStep,
  completedStep,
}) => {
  return (
    <Routes>
      <Route
        path={routes.onboardingPath()}
        element={
          <ProfileOnboardingPage
            profile={profile}
            completedStep={completedStep}
            currentStep={pageCurrentStep}
          />
        }
      />
      <Route
        path={routes.onboardingOverviewStepPath()}
        element={
          <OverviewOnboardingPage
            completedStep={completedStep}
            currentStep={pageCurrentStep}
          />
        }
      />

      <Route
        path={routes.onboardingAboutUsStepPath()}
        element={
          <AboutUsOnboardingPage
            completedStep={completedStep}
            currentStep={pageCurrentStep}
          />
        }
      />

      <Route
        path={routes.onboardingCompletedPath()}
        element={
          <GetStartedOnboardingPage
            completedStep={completedStep}
            currentStep={pageCurrentStep}
          />
        }
      />
    </Routes>
  )
}

export default Template
