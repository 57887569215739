import React from "react"
import cn from "design_system/libs/cn"
import LofiString from "./string"
import LoFiString from "./string"
import classNames from "classnames"

const LofiSentence = ({ className, ...props }) => {
  return (
    <div className={cn("flex flex-col gap-2", className)}>
      <LoFiString {...props} />
      <LoFiString {...props} width="w-[80%]" />
    </div>
  )
}

export default LofiSentence
