import React from "react"

import Button from "@love-irish/core-button"

import {
  IconBookmark,
  IconBrandSteam,
  IconChevronLeft,
  IconChevronRight,
  IconHeart,
} from "@tabler/icons-react"

const VocabSection = () => (
  <div className="grid mt-24 grid-cols-5 gap-10">
    <div className="mt-8">
      <h1 className="text-3xl font-serif">Vocab lists</h1>
      <p className="text-lg mt-4">
        From greetings to days of the week, here are some vocab lists you can
        reference while learning with audio samples and examples in media.
      </p>

      <p className="mt-6">
        <a href="/vocab">View more &rarr;</a>
      </p>
    </div>
    <div className="col-span-4">
      <div className="grid gap-10 grid-cols-2 items-start">
        <div className="border rounded-t-xl rounded-b bg-background shadow-lg overflow-hidden">
          <div className="p-4 py-6">
            <div className="flex justify-between items-start mb-3">
              <div className="text-2xl font-serif">
                <a href="/collections/irish-week-vocab">
                  Seachtain na Gaeilge (Irish week) vocab
                </a>
              </div>
            </div>
            <p className="italic">
              It might be overwhelming to know where to start when learning a
              language or maybe you just found out Irish is a language in
              Ireland and want to learn more. Here are some phrases and words to
              get you started with the celebration!
            </p>

            <div className="mt-4">
              <a href="/collections/irish-week-vocab">
                <strong>Léigh é</strong> &middot; <em>Read it</em> &rarr;
              </a>
            </div>
            <div className="mt-4 text-sm hidden">12 items</div>
            <div className="mt-6">
              {["vocab", "event"].map((tag) => (
                <span
                  key={tag}
                  className="mr-2 border p-2 font-bold text-sm mt-2"
                >
                  #{tag}
                </span>
              ))}
            </div>
          </div>
        </div>

        <div className="border rounded-t-xl rounded-b bg-background shadow-lg overflow-hidden bg-background">
          <div className="p-4 py-6">
            <div className="flex justify-between items-start mb-3 gap-3">
              <div className="text-2xl mb-2 font-serif">
                <a href="/collections/st-patricks-day-vocab">
                  St. Patrick's Day vocab
                </a>
              </div>
            </div>
            <p className="italic">
              A lot of people will get into the spirit of St. Patrick's Day by
              wearing green and celebrating with friends and family, some even
              dye the river green. This vocab list has some words and phrases to
              join in the celebration. This is the last day of Irish week (March
              1–17th) and is all about using your language at all skill levels.
            </p>

            <div className="mt-4">
              <a href="/collections/st-patricks-day-vocab">
                <strong>Léigh é</strong> &middot; <em>Read it</em> &rarr;
              </a>
            </div>
            <div className="mt-6">
              {["vocab", "event"].map((tag) => (
                <span
                  key={tag}
                  className="mr-2 border p-2 font-bold text-sm mt-2"
                >
                  #{tag}
                </span>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
)

export default VocabSection
