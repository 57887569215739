import React, { createContext } from "react"
import { useQuery } from "@apollo/client"
import query from "./current_user.graphql"

export const CurrentUserContext = createContext({})
export const CurrentProfileContext = createContext({})

export const SessionProvider = ({ children }) => {
  const { data, loading, error } = useQuery(query)
  return (
    <CurrentUserContext.Provider value={data?.currentUser}>
      <CurrentProfileContext.Provider value={data?.currentUser?.profile}>
        {children}
      </CurrentProfileContext.Provider>
    </CurrentUserContext.Provider>
  )
}

export default SessionProvider
