import React from "react"

import logo from "images/brand/logos/short.svg"
import { FiMapPin } from "react-icons/fi"
import Tooltip from "design_system/core/components/tooltip"
import ContentCloser from "design_system/application/components/content_closer"
import routes from "design_system/utils/routes"
import analytics from "design_system/utils/analytics"
import {
  IconBrandBluesky,
  IconBrandDiscord,
  IconBrandFacebook,
  IconBrandInstagram,
  IconBrandYoutube
} from "@tabler/icons-react";
import DynamicDatetimeLabel from "@love-irish/application-dynamic-datetime-label";

const resourceLinks = [
  { name: "Calendar", href: routes.calendarPath(), translation: "Ceiléir" },
  { name: "Topics", href: '/topics', translation: "Ábhair" },
  { name: "Collections", href: '/collections', translation: "Bailiúcháin" },
  { name: "Codex", href: '/codex', translation: "Códex" },
  { name: "Interviews", href: '/interviews', translation: "Agallaimh" },
]

const adventureLinks = [
  { name: "Arcade", href: '/arcade', translation: "Teach imirt" },
  { name: "Rewards", href: "/adventure/rewards", translation: "Duaiseanna" },
]

const shopLinks = [
  { name: "T-shirts", href: 'https://shop.love.irish/products/t-shirts', translation: "T-leinte" },
  { name: "Hoodies", href: 'https://shop.love.irish/products/hoodies', translation: "Geansai" },
  { name: "Everything", href: 'https://shop.love.irish/products', translation: "Gach rud" },
]

const communityLinks = [
  // { name: "Directory", href: routes.interviewsPath(), translation: "Díolachán" },
  { name: "Guestbook", href: '/guestbook', translation: "Leabhar Aíochta" },
  // { name: "Profiles", href: routes.interviewsPath(), translation: "Próifílí" },
  // { name: "Profile bling", href: routes.interviewsPath(), translation: "Bling próifíl" },
]

const libraryLinks = []

const supportLinks = [
  // { name: "Community guidelines", href: routes.sitemapPath() },
  { name: "FAQ", href: routes.faqPath(), translation: "Ceisteanna Coitianta" },
  { name: "Signup", href: '/signup', translation: "Cruthaigh cuntas" },
  // { name: "Changelog", href: routes.sitemapPath()},
  { name: "Sitemap", href: '/sitemap.xml', translation: "Léarscáil" },
  { name: "Privacy", href: '/privacy', translation: "Príobháideachas" },
  { name: "Terms", href: '/terms', translation: "Teorainneacha" },
  { name: "Contact", href: '/contact', translation: "Teagmháil" },
]

const companyLinks = [
  { name: "Home", href: routes.homePath(), translation: "Baile" },
  { name: "About us", href: routes.aboutUsPath(), translation: "Fúinn" },
  // { name: "Artists", href: routes.aboutUsPath(), translation: "Fúinn" },
  // { name: "Blog", href: routes.blogPostsPath(), translation: "Blag" },
]

const AppFooter = ({currentUser}) => {
  // eslint-disable-next-line camelcase
  const handleScrollToTopClick = () =>
    analytics.track("clicked#scroll_to_top", { link_location: ["footer"] })
  const handleLinkClick = ({ label, href, section }) =>
    analytics.trackLinkClick({ label, href, location: ["footer", section] })

  return (
    <footer className="grow-0 mt-12">
      <div className="container">
        <ContentCloser scrollToTop onScrollClick={handleScrollToTopClick} />

        <div className="grid gap-12 md:grid-cols-4 pb-10">
          <div className="md:col-span-1 col-span-2 text-sm">
            <a href="/"><img width={50} src={logo} alt="Love.irish logo" /></a>

            <p className="mt-4 mb-4 text-gray-800">
              <FiMapPin className="inline" /> Based in the{" "}
              <strong>San Francisco Bay Area</strong>
            </p>

            <p>
              <Tooltip
                title="Broken Irish is better than clever English️"
                wrapperClass="inline"
              >
                <em className="text-definition">
                  Is fearr Gaeilge bhriste ná Béarla cliste
                </em>
              </Tooltip>{" "}
              &middot; The best way to get better at something is to practice so
              don&rsquo;t worry about being perfect!
            </p>

            <p className="mt-4">
              This site is run by and for fans and learners — corrections welcome ❤️
            </p>

            <p className="mt-4">
              &copy; {new Date().getFullYear()} Love.irish. All rights
              reserved.
            </p>

            <div className="text-gray-400 mt-8 flex gap-3">
              <a href="https://discord.gg/BXHy8hbRYP" target="_blank" rel="noopener noreferrer">
                <IconBrandDiscord size={24} className="inline" />
              </a>
              <a href="https://www.youtube.com/@love-irish" target="_blank" rel="noopener noreferrer">
                <IconBrandYoutube size={24} className="inline" />
              </a>
              <a href="https://bsky.app/profile/love.irish" target="_blank" rel="noopener noreferrer">
                <IconBrandBluesky size={24} className="inline" />
              </a>
              <a href="https://www.facebook.com/loveirishapp" target="_blank" rel="noopener noreferrer">
                <IconBrandFacebook size={24} className="inline text-right" />
              </a>
              <a href="https://www.instagram.com/love.irish.app" target="_blank" rel="noopener noreferrer">
                <IconBrandInstagram size={24} className="inline" />
              </a>
            </div>
          </div>
          <div className="grid gap-3 grid-cols-2  col-span-2 md:grid-cols-5  md:col-span-3">
            <div>
            <h3 className="text-lg font-fancy">
                <a href="/community">Community</a>
              </h3>
              <p className="text-sm mb-3 text-gray-500">
                <em>Pobal</em>
              </p>
              <ul className="space-y-1">
                {communityLinks.map(({ name, href, translation }) => (
                  <li key={name}>
                    <a
                      href={href}
                      onClick={() =>
                        handleLinkClick({
                          label: name,
                          section: "products",
                          href,
                        })
                      }
                    >
                      {name}{" "}
                      <em className="text-gray-400 text-xs">{translation}</em>
                    </a>
                  </li>
                ))}
              </ul>
            </div>
            <div>
              <h3 className="text-lg font-fancy">
                <a href={routes.resourcesPath()}>Resources</a>
              </h3>
              <p className="text-sm mb-3 text-gray-500">
                <em>Seachaidh</em>
              </p>
              <ul className="space-y-1">
                {resourceLinks.map(({ name, href, translation }) => (
                  <li key={name}>
                    <a
                      href={href}
                      onClick={() =>
                        handleLinkClick({
                          label: name,
                          section: "products",
                          href,
                        })
                      }
                    >
                      {name}{" "}
                      <em className="text-gray-400 text-xs">{translation}</em>
                    </a>
                  </li>
                ))}
              </ul>
            </div>
            <div>
              <h3 className="text-lg font-fancy">
                <a href="/adventure">Adventure</a>
              </h3>
              <p className="text-sm mb-3 text-gray-500">
                <em>Eachtra</em>
              </p>
              <ul className="space-y-1">
                {adventureLinks.map(({ name, href, translation }) => (
                  <li key={name}>
                    <a
                      href={href}
                      onClick={() =>
                        handleLinkClick({
                          label: name,
                          section: "products",
                          href,
                        })
                      }
                    >
                      {name}{" "}
                      <em className="text-gray-400 text-xs">{translation}</em>
                    </a>
                  </li>
                ))}
              </ul>
            </div>
            <div>
              <h3 className="text-lg font-fancy">
                <a href="https://shop.love.irish">Shop</a>
              </h3>
              <p className="text-sm mb-3 text-gray-500">
                <em>Siopa</em>
              </p>
              <ul className="space-y-1">
                {shopLinks.map(({ name, href, translation }) => (
                  <li key={name}>
                    <a
                      href={href}
                      onClick={() =>
                        handleLinkClick({
                          label: name,
                          section: "products",
                          href,
                        })
                      }
                    >
                      {name}{" "}
                      <em className="text-gray-400 text-xs">{translation}</em>
                    </a>
                  </li>
                ))}
              </ul>
            </div>
            <div>
              <h3 className="text-lg font-fancy  mb-2">Company</h3>
              <ul>
                {companyLinks.map(({ name, href, translation }) => (
                  <li key={name}>
                    <a
                      href={href}
                      onClick={() =>
                        handleLinkClick({ label: name, section: "company", href })
                      }
                    >
                      {name}{" "}
                      <em className="text-gray-400 text-xs">{translation}</em>
                    </a>
                  </li>
                ))}
              </ul>

              <h3 className="text-lg font-fancy mb-2 mt-6">Help &amp; support</h3>
              <ul className="space-y-1">
                {supportLinks.map(({ name, href, translation }) => (
                  <li key={name}>
                    <a
                      href={href}
                      onClick={() =>
                        handleLinkClick({ label: name, section: "support", href })
                      }
                    >
                      {name}{" "}
                      <em className="text-gray-400 text-xs">{translation}</em>
                    </a>
                  </li>
                ))}
              </ul>


            </div>
          </div>
        </div>

        <hr className="mt-7 mb-3" />

        <div className="flex justify-between my-5 text-sm">
          <div>
            <DynamicDatetimeLabel currentUser={currentUser} />
          </div>
          <small>
            <em>
              <Tooltip title="I am a footer 🦶" wrapperClass="text-definition !font-normal">
                Is footer mé
              </Tooltip>
            </em>
          </small>
        </div>
      </div>
    </footer>
  )
}


export default AppFooter
