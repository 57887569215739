import React from "react"
import type { schema } from "@love-irish/graphql-schema"
import PageBreadcrumb from "design_system/application/components/page_breadcrumb"
import Title from "design_system/core/components/title"
import Badge from "design_system/core/components/badge"
import Content from "design_system/core/components/content"
import LofiImage from "design_system/core/components/lo_fi/image"
import formatter from "design_system/utils/formatter"

export const Template = ({ collections }) => (
  <div className="container mt-8">
    <PageBreadcrumb pages={[{ name: "Resources", href: "/resources" }]} />
    <div className="my-8">
      <Title title="Collections" size="lg" />
    </div>
    <div className="grid grid-cols-5 mt-16">
      <div className="col-span-3">
        <h2 className="mb-2 font-bold text-lg">Featured collections</h2>
        <ul className="space-y-6 divide-y">
          {collections.map((collection) => (
            <li key={collection.id} className="pt-6 flex gap-6">
              <div>
                <Title
                  title={collection.name}
                  href={collection.path}
                  size="sm"
                />
                <Content
                  content={collection.summary}
                  className="mt-1 line-clamp-3"
                />

                {collection.owner && (
                  <p className="mt-4 text-sm">
                    Created{" "}
                    {collection.owner && (
                      <>
                        by{" "}
                        <strong>
                          <a href={`/@${collection.owner?.slug}`}>
                            @{collection.owner?.slug}
                          </a>
                        </strong>{" "}
                      </>
                    )}
                    on{" "}
                    <time className="font-bold">
                      {formatter.shortDate(collection.publishedAt)}
                    </time>
                  </p>
                )}

                <div className="mt-4 text-sm">
                  <a href={collection.path}>View collection &rarr;</a>
                </div>
              </div>
            </li>
          ))}
        </ul>
      </div>

      <div className="col-span-2"></div>
    </div>
  </div>
)

export default Template
