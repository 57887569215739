import React, { Fragment } from "react"
import Button from "@love-irish/core-button"
import {
  IconBook,
  IconBookmark,
  IconBrandSteam,
  IconHeart,
  IconList,
  IconPlayerPlay,
} from "@tabler/icons-react"
import { format } from "timeago.js"

import AddToCollection from "@love-irish/resource-add-to-collection"
import TopicBadge from "@love-irish/resource-topic-badge"
import Title from "design_system/core/components/title"
import Tooltip from "design_system/core/components/tooltip"
import Content from "design_system/core/components/content"
import formatter from "design_system/utils/formatter"

import classnames from "classnames"
import { cn } from "design_system/libs"

import CodexRecordListItem, { addDynamicTags } from "./codex_record"
import InterviewListItem from "./interview"
import MiniGameListItem from "./mini_game"

const labelComponents = {
  Collection: () => (
    <div className="flex gap-1 items-center text-xs mb-4">
      <IconList
        className="text-secondary-400 inline-block align-middle"
        size={14}
      />
      Collection
    </div>
  ),
}

export const ResourceListItem = ({
  className,
  currentUser,
  item,
  collections = [],
}) => {
  const typename = item.__typename || item.type || item.typename
  if (typename == "Item::CodexRecord") {
    return (
      <CodexRecordListItem
        item={item}
        collections={collections}
        currentUser={currentUser}
      />
    )
  } else if (typename == "Item::Interview") {
    return (
      <InterviewListItem
        item={item}
        collections={collections}
        currentUser={currentUser}
      />
    )
  } else if (typename == "Item::MiniGame") {
    return (
      <MiniGameListItem
        item={item}
        collections={collections}
        currentUser={currentUser}
      />
    )
  }

  const LabelComponent = labelComponents[item.__typename] || Fragment

  return (
    <div className={cn("border rounded-xl bg-background", className)}>
      {item.imageUrl && (
        <img
          src={item.imageUrl}
          className="w-full max-w-full rounded-t-xl overflow-hidden"
        />
      )}

      <div className="p-4">
        <LabelComponent />
        <div className="flex justify-between items-start mb-3 gap-8 mt-2">
          <Title
            className="post-title text-2xl flex gap-2"
            title={addDynamicTags(item.name)}
            size="sm"
            showIcon={false}
            href={item.path}
          />
          <div className="flex shrink-0 gap-1">
            <AddToCollection itemId={item.gid} currentUser={currentUser} />
          </div>
        </div>

        {item.summary && (
          <div className="mt-4">
            <Content
              content={item.summary}
              className="prose-sm text-inherit text-sm text-dark dark:text-white !font-mono first-line:not-italic"
            />
          </div>
        )}

        {item.path && (
          <div className="mt-4">
            <a href={item.path}>
              <strong>Léigh mó</strong> &middot; <em>Read more</em> &rarr;
            </a>
          </div>
        )}

        {item.topics?.length > 0 && (
          <div className="mt-6">
            {["game", "steam", "irish-game-studio", "irish-language"].map(
              (topic) => (
                <TopicBadge topic={{ slug: topic }} key={topic} />
              )
            )}
          </div>
        )}
      </div>
      <div className="p-4 border-t flex justify-between text-xs">
        {item.owner && (
          <span>
            Added by <strong>@{item.owner.slug}</strong> on{" "}
            <time
              title={new Date(item.createdAt).toLocaleString()}
              dateTime={item.createdAt}
              className="font-bold"
            >
              {formatter.shortDate(item.createdAt)}
            </time>
          </span>
        )}
        <span>
          Updated{" "}
          <time
            title={new Date(item.updatedAt).toLocaleString()}
            dateTime={item.updatedAt}
            className="font-bold"
          >
            {format(item.updatedAt)}
          </time>
        </span>
      </div>
    </div>
  )
}

export { CodexRecordListItem as CodexRecord, addDynamicTags }

export default ResourceListItem
