import React from "react"

import { CodexRecord } from "@love-irish/resource-list-item"
import Title from "design_system/core/components/title"
import PageBreadcrumb from "design_system/application/components/page_breadcrumb"
import Content from "design_system/core/components/content"
import { IconBookmark, IconHeart } from "@tabler/icons-react"
import tadhgImage from "images/love_irish/adventure/characters/tadhg_full_body.png"

export const TemplateComponent = ({ items, currentUser }) => (
  <div className="container mt-8">
    <PageBreadcrumb pages={[{ name: "Resources", href: "/resources" }]} />
    <div className="mt-4 mb-16">
      <div className="grid grid-cols-3 gap-6">
        <div className="col-span-2">
          <Title title="Codex" size="lg" className="mb-4" />
          <Content content="Our collection of words and phrases that we are working on to add additional information like audio samples for pronunciation." />

          <div className="mt-4">
            <h2 className="mb-2 font-bold">Other resources</h2>
            <div className="prose">
              <p>
                <a href="https://www.teanglann.ie/">Teanglann.ie</a>–online
                dictionary and language library managed by Foras na Gaeilge and
                has several tools like an english-irish and irish-english
                dictionary as well as grammar and pronunciation guides.
              </p>
              <p>
                <a href="https://www.focloir.ie/en/">Foclóir.ie</a>–another
                online dictionary with more information on the etymology of
                words and phrases.
              </p>
            </div>
          </div>
        </div>
        <div className="text-center">
          <img src={tadhgImage} className="pixel-art w-[128px] inline-block" />
          <div className="font-bold mt-4 mb-2 text-definition">
            Is mise <a href="/@tadhg">@tadhg</a> na Bogach!
          </div>
          <p className="text-gray-500 max-w-lg mx-auto">
            "My name is <a href="/@tadhg">@tadhg</a> of the marsh." I am the
            seachaí, or storyteller, in charge of the codex and all it's
            records. Hopefully you find what you are looking for.
          </p>
        </div>
      </div>
    </div>

    <h2 className="font-bold mb-4">Recently added</h2>
    <div className="grid md:grid-cols-3 gap-5">
      <div className="col-span-2 space-y-6">
        {items.map((item) => (
          <div>
            <CodexRecord
              item={item}
              className="border"
              currentUser={currentUser}
            />
          </div>
        ))}
      </div>
    </div>
  </div>
)

export default TemplateComponent
