import { Label as FlowbiteLabel } from "flowbite-react"
import { Input } from "@love-irish/ui-components/input"
import { TextInput } from "flowbite-react"
import { Textarea } from "@love-irish/ui-components/textarea"
import {
  RadioGroup,
  RadioGroupItem,
} from "@love-irish/ui-components/radio-group"
import { Label } from "@love-irish/ui-components/label"
import Button from "@love-irish/core-button"
import React, { useState } from "react"
import { useMutation } from "@apollo/client"
import mutation from "./create_collection.graphql"
import { Formik } from "formik"
import type { CreateCollectionMutationPayload } from "@love-irish/types"
import { toast } from "sonner"
const accessLevels = [
  {
    name: "Public",
    description:
      "Anyone on the internet can see this collection and appears in public lists like search results or sitemaps crawled by bots",
    value: "public",
  },
  {
    name: "Private",
    value: "private",
    description: "Only you and people you invite can see this collection",
  },
  // { name: "Unlisted", value: 2, description: "Public but not included in our search results" },
]

export type ResourceNewCollectionFormProps = {
  owner: {
    gid: string
    slug: string
  }
  onSuccess?: (result: { data: CreateCollectionMutationPayload }) => void
}

const ResourceNewCollectionForm = ({
  owner,
  onSuccess,
}: ResourceNewCollectionFormProps) => {
  const [createCollection, { data, loading, error }] = useMutation(mutation)
  return (
    <Formik
      initialValues={{
        name: "",
        slug: "",
        accessLevel: "public",
        content: "",
        summary: "",
      }}
      validate={(values) => {
        const errors = {}
        if (!values.slug) {
          errors.slug = "Required"
        }

        if (values?.slug?.includes(" ")) {
          errors.slug = "Contains invalid characters"
        }
        return errors
      }}
      onSubmit={(values, { setSubmitting }) => {
        createCollection({
          variables: {
            input: {
              ownerId: owner.gid,
              ...values,
            },
          },
        })
          .then((result) => onSuccess?.(result))
          .catch((_error) =>
            toast.error("There was a problem creating your collection")
          )
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
      }) => (
        <form onSubmit={handleSubmit}>
          {!owner?.slug && (
            <div className="bg-warning-200 p-2 rounded mb-6">
              You need to finish setting up your profile before you can create a
              collection.
            </div>
          )}
          <div className="space-y-8">
            <div className="">
              <div className="mb-2 block">
                <FlowbiteLabel htmlFor="name" value="Name *" />
              </div>
              <p className="mb-4 text-sm italic">
                Supports minimal markdown like _italics_, and **bold**.
              </p>
              <Input
                id="name"
                placeholder="My special collection"
                required
                name="name"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.name}
              />
            </div>

            <div className="">
              <div className="mb-4 block">
                <FlowbiteLabel htmlFor="slug" value="Url *" />
                <p className="text-sm italic">
                  Supports alphanumeric characters with accents (áè), dashes and
                  underscore and are not case sensitive
                </p>
                {errors?.slug && touched?.slug && (
                  <div className="text-error-500 my-2">{errors.slug}</div>
                )}
              </div>
              <TextInput
                id="slug"
                name="slug"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.slug}
                sizing="md"
                placeholder="collection-name"
                addon={
                  <strong>@{owner?.slug || "profile"}/collections/</strong>
                }
                required
              />
            </div>

            <div className="">
              <div className="mb-4 block">
                <FlowbiteLabel htmlFor="summary" value="Summary" />
                <p className="text-sm italic">
                  Supports basic markdown like links, _italics_, and **bold**.
                </p>
              </div>

              <Textarea
                id="summary"
                placeholder="Add a short summary."
                rows={2}
                name="summary"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.summary}
              />
            </div>

            <div className="">
              <div className="mb-4 block">
                <FlowbiteLabel htmlFor="content" value="Content" />
                <p className="text-sm italic">
                  Supports more advanced markdown like lists, headings, links,
                  _italics_, and **bold**.
                </p>
              </div>
              <Textarea
                id="content"
                placeholder="Add content for collection page. Supports more markdown like lists, links, headings and more."
                rows={6}
                name="content"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.content}
              />
            </div>

            <div>
              <FlowbiteLabel
                htmlFor="accessLevel"
                value="Access level"
                className="mb-4"
              />
              <RadioGroup
                id="accessLevel"
                className="space-y-2 mt-4"
                onChange={handleChange}
                onBlur={handleBlur}
                name="accessLevel"
                defaultValue={values.accessLevel}
              >
                {accessLevels.map((level) => (
                  <div className="flex items-start space-x-2" key={level.name}>
                    <RadioGroupItem
                      className="mt-2"
                      value={level.value}
                      id={level.name}
                    />
                    <Label htmlFor={level.name}>
                      <div className="text-lg">{level.name}</div>
                      <div className="text-muted-foreground">
                        {level.description}
                      </div>
                    </Label>
                  </div>
                ))}
              </RadioGroup>
            </div>

            <div className="flex justify-end mt-4">
              <Button type="submit" disabled={loading || !owner || !owner.slug}>
                Create collection
              </Button>
            </div>
          </div>
        </form>
      )}
    </Formik>
  )
}

export default ResourceNewCollectionForm
