const routes = {
  toUrl: (path) =>
    `${process.env.NEXT_PUBLIC_LOVE_IRISH_APP || "https://love.irish"}${path}`,

  rootPath: () => "/",
  dashboardPath: () => "/",
  homePath: () => "/home",

  aboutUsPath: () => "/about-us",

  onboardingPath: () => "/start",
  onboardingStepPath: (step) => `/start/${step}`,
  onboardingOverviewStepPath: () => routes.onboardingStepPath("overview"),
  onboardingAboutUsStepPath: () => routes.onboardingStepPath("about-us"),
  onboardingCompletedPath: () => routes.onboardingStepPath("your-adventure"),

  /**
   *
   * @return {string}
   */
  blogPostsPath: () => "/blog",
  /**
   *
   * @param slug
   * @return {`/blog/${string}`}
   */
  blogPostPath: (slug) => `/blog/${slug}`,
  eventsPath: () => "/events",
  /**
   *
   * @param slug
   * @return {`/events/${string}`}
   */
  eventPath: (slug) => `/events/${slug}`,
  calendarPath: () => `/calendar`,
  calendarEventPath: (slug) => `/calendar/${slug}`,
  calendarEventGuestbookPath: (slug) => `/calendar/${slug}/guestbook`,
  faqPath: () => "/faq",
  /**
   *
   * @return {string}
   */
  interviewsPath: () => "/interviews",
  /**
   *
   * @param slug
   * @return {`/interviews/${string}`}
   */
  interviewPath: (slug) => `/interviews/${slug}`,
  libraryPath: () => "/library",
  pubPath: () => "/pub",
  /**
   *
   * @return {string}
   */
  resourcesPath: () => "/resources",
  /**
   *
   * @param slug
   * @return {`/resources/${string}`}
   */
  resourcePath: (slug) => `/resources/${slug}`,
  shopPath: () => "/shop",
  sitemapPath: () => "/sitemap",
  /**
   *
   * @param slug
   * @return {`/@${string}`}
   */
  profilePath: (slug) => `/@${slug}`,
  profileCollectionsPath: (slug) => `/@${slug}/collections`,
  /**
   * Special events
   */
  irishLanguageWeek2023Path: () => routes.eventPath("irish-language-week-2023"),
  irishWeekCalendarPath: () => routes.calendarEventPath("irish-week"),
  stPatricksDayCalendarPath: () => routes.calendarEventPath("st-patricks-day"),
}

export default routes
