import React from "react"

import duolingoAvatar from "images/profile_icons/duolingo.png"

const AppsSection = () => (
  <div className="grid mt-24 lg:grid-cols-5 gap-10">
    <div className="mt-8">
      <h1 className="text-3xl font-serif">Apps</h1>
      <p className="text-lg mt-4">
        Mobile and web apps to help you practice your Irish
      </p>
    </div>
    <div className="lg:col-span-4">
      <div className="grid gap-10 sm:grid-cols-2 items-start">
        <div className="border rounded-t-xl rounded-b bg-background shadow-lg overflow-hidden">
          <div className="p-4 bg-brand-duolingo rounded-t text-center">
            <img
              src={duolingoAvatar}
              alt=""
              className="pixel-art w-[128px] inline"
            />
          </div>
          <div className="p-4">
            <div className="flex justify-between items-start mb-3">
              <div className="text-2xl font-serif">
                <a href="https://duolingo.com">Duolingo</a>
              </div>
            </div>
            <p className="italic">
              Become a giant walking dice, explore dungeons and defy Lady Luck
              in this dice powered roguelike! Now featuring "Reunion", a free
              DLC with six brand-new episodes!
            </p>

            <div className="mt-4">
              <a href="https://duolingo.com">
                <strong>Foghlaim mó</strong> &middot; <em>learn more</em> &rarr;
              </a>
            </div>
            <div className="mt-4">
              {["app", "free-to-use", "subscription"].map((tag) => (
                <span
                  key={tag}
                  className="mr-2 border p-2 font-bold text-sm mt-2 inline-block"
                >
                  #{tag}
                </span>
              ))}
            </div>
          </div>
        </div>

        <div className="border rounded-t-xl rounded-b bg-background shadow-lg overflow-hidden bg-background">
          <div className="p-4">
            <div className="flex justify-between items-start mb-3 gap-3">
              <div className="text-2xl mb-2 font-serif">
                <a href="https://aistear.bitesize.irish">Aistear</a>
              </div>
            </div>
            <p className="italic">
              From the creators of Bitesize Irish, this is a learning platform
              with courses and ways to engage with the Irish language like
              singing songs.
            </p>

            <div className="mt-4">
              <a href="https://aistear.bitesize.irish">
                <strong>Foghlaim mó</strong> &middot; <em>learn more</em> &rarr;
              </a>
            </div>
            <div className="mt-4">
              {["app", "subscription"].map((tag) => (
                <span
                  key={tag}
                  className="mr-2 border p-2 font-bold text-sm mt-2 inline-block"
                >
                  #{tag}
                </span>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
)

export default AppsSection
