import React from "react"
import { GraphqlProvider } from "design_system/utils/graphql_client"
import type { CustomFlowbiteTheme } from "flowbite-react"
import { Flowbite } from "flowbite-react"
import { BrowserRouter } from "react-router"
import SessionProvider from "@love-irish/application-session-provider"

const customTheme: CustomFlowbiteTheme = {
  label: {
    root: {
      base: "text-sm font-bold",
    },
  },
  textInput: {
    field: {
      input: {
        colors: {
          gray: "border-gray-300 bg-background text-gray-900 focus:border-cyan-500 focus:ring-cyan-500 dark:border-gray-600 dark:text-white dark:placeholder-gray-400 dark:focus:border-cyan-500 dark:focus:ring-cyan-500",
        },
      },
    },
  },
}

export default function AppShell({ children, currentUser, currentProfile }) {
  return (
    <GraphqlProvider>
      <SessionProvider
        currentProfile={currentProfile}
        currentUser={currentUser}
      >
        <BrowserRouter>
          <Flowbite theme={{ theme: customTheme }}>{children}</Flowbite>
        </BrowserRouter>
      </SessionProvider>
    </GraphqlProvider>
  )
}
