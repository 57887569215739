import React from "react"
import routes from "design_system/utils/routes"
import classNames from "classnames"
import styles from "./index.module.css"
import oghamStone from "images/love_irish/adventure/objects/ogham-stone.png"
const IrishWeekSection = () => {
  return (
    <div
      className={classNames(
        "relative mt-12 lg:mt-60 grid grid-cols-1 lg:grid-cols-2 lg:items-end lg:gap-10 border-4 shadow-xl bg-background rounded-xl",
        styles.container
      )}
      id="interviews"
    >
      <div className="relative px-8 py-12">
        <h3 className="text-3xl font-normal text-gray-900 sm:text-4xl font-fancy">
          Celebrate Irish week &amp;{" "}
          <span className="inline-block">St. Patrick's Day</span>!
        </h3>

        <p className="mt-2 text-lg text-gray-500">
          We've created a guide to help you celebrate each event including
          activities and resources.
        </p>
        <div className="grid grid-cols-2 gap-8 mt-8">
          <div>
            <h2 className="font-serif text-xl">
              <a href={routes.irishWeekCalendarPath()}>Seachtain na Gaeilge</a>
            </h2>
            <div className="text-green-500 font-bold text-sm mt-1">
              March 1–17th
            </div>
            <p className="text-sm mt-3">
              "Irish week" was started in 1902 to encourage the use of the Irish
              language at all levels, from beginner to fluent.
            </p>
            <p className="mt-4">
              <a href={routes.irishWeekCalendarPath()}>
                <strong>Léigh é</strong> &middot; <em>Read it</em> &rarr;
              </a>
            </p>
          </div>
          <div>
            <h2 className="font-serif text-xl">
              <a href={routes.stPatricksDayCalendarPath()}>St. Patrick's Day</a>
            </h2>
            <div className="text-green-500 font-bold text-sm mt-1">
              March 17th
            </div>
            <p className="text-sm mt-3">
              A major holiday for the Irish community in the United States and
              has been growing in popularity around the world.
            </p>
            <p className="mt-4">Coming soon</p>
          </div>
        </div>

        <hr className="my-10" />

        <div className="bg-background py-4 lg:py-0">
          <h2 className="font-bold text-lg mb-2">Sign the Ogham stone</h2>
          <p>
            Special events on the site have guestbook-style ogham stones that
            can be signed by anyone in the community. Each event has its own
            stone and sometimes magical things happen when you sign them.
          </p>
          <p className="mt-4">
            <a href={routes.calendarEventGuestbookPath("irish-week")}>
              <strong>Scríobhann é</strong> &middot; <em>Sign it</em> &rarr;
            </a>
          </p>
        </div>
      </div>
      <div>
        <img src={oghamStone} className={styles.stone} />
      </div>
    </div>
  )
}

IrishWeekSection.propTypes = {}

export default IrishWeekSection
