import spellData from "design_system/adventure/codex/spells"

export const playScript = (bot, actions = {}) => {
  const spells = Object.entries(spellData)

  return bot.message
    .add({
      text: "A seanchaí can summon the power of the ancients through speaking the old language.",
    })
    .then(async (res) => {
      await bot.message.add({
        text: "Let's try casting a spell! You'll only have a couple when you're just starting out, but you can find more spells as you complete stories and through loot drops.",
      })
    })
    .then(async (res) => {
      const messages = await bot.message.getAll()
    })
    .then(async (res) => await startSpellCasting(bot, actions))
}

const startSpellCasting = async (bot, actions = {}) => {
  const spells = Object.entries(spellData).splice(0, 4)

  const options = spells.map(([name, data], index) => ({
    label: data.name,
    value: name,
    item: data,
  }))
  return await bot.action
    .set(
      {
        options,
      },
      {
        actionType: "castSpell",
        confirmButtonText: "Continue",
        bringIntoView: false,
        messageType: "spellCastResponse",
      }
    )
    .then(async ({ spell: item }) => {
      await bot.message.add(
        {
          item,
        },
        { fromHuman: true, messageType: "spellCast" }
      )
    })
    .then(async (res) => {
      await bot.message.add({
        text: "Nice! Try casting another spell.",
      })
    })
    .then(async (res) => await startSpellCasting(bot, actions))
}
