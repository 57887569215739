import React from "react"
import LofiImage from "design_system/core/components/lo_fi/image"
import LofiAvatar from "design_system/core/components/lo_fi/avatar"
import LofiString from "design_system/core/components/lo_fi/string"
import CelticDropCap from "design_system/application/components/celtic_drop_cap"
import LofiSentence from "design_system/core/components/lo_fi/sentence"
import { cx } from "class-variance-authority"

export const CommunityLofiProfileComponent = ({
  banner,
  avatar,
  className,
}: {
  banner?: string
  avatar?: string
  className?: string
}) => (
  <div
    className={cx(
      "h-full border-x border-t rounded-t-xl p-4 border-dark",
      className
    )}
  >
    <div>
      {banner ? (
        <img src={banner} alt="" className="pixel-art w-full" />
      ) : (
        <LofiImage height="h-36" background="bg-secondary-100" />
      )}
    </div>
    <div className="px-5 grid grid-cols-3 gap-5">
      <div className="card profile-card -mt-10 inline-block max-w-[200px]">
        <div className="profile-card-body p-3">
          <div className="flex gap-2">
            <div className="w-[32px]">
              {avatar ? (
                <img src={avatar} alt="" className="pixel-art" />
              ) : (
                <LofiAvatar />
              )}
            </div>
            <div className="w-[100px] grid gap-1 pt-1">
              <div>
                <LofiString width="max-w-[70%]" className="bg-gray-300" />
              </div>
              <div>
                <LofiString height="h-1.5" />
              </div>
            </div>
          </div>
          <hr className="my-3" />
          <div>
            <div className="grid gap-2">
              <LofiString height="h-1.5" width="max-w-[60%]" />
              <LofiString width="max-w-[40%]" height="h-1.5" />
            </div>
          </div>
        </div>
        <div className="grid gap-2 mt-4">
          <LofiString height="h-1.5" width="max-w-[60%]" />
          <LofiString width="max-w-[40%]" height="h-1.5" />
        </div>
      </div>

      <div className="col-span-2 py-5">
        <CelticDropCap className="m-0 mr-3" size={"xs"} letter="a" />
        <LofiSentence className="mb-4 w-[80px]" />
        <LofiSentence className="mb-4" />
        <LofiSentence className="mb-4" />
        <LofiSentence className="mb-4" />
      </div>
    </div>
  </div>
)

export default CommunityLofiProfileComponent
