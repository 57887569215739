import React from "react"
import Title from "design_system/core/components/title"
import Content from "design_system/core/components/content"

export const AdBlock = ({ title, content, label, href, imageUrl }) => (
  <div className="w-[200px] max-w-full">
    <a
      className="text-center border rounded px-4 py-7 block hover:border-brand-loveirish transition-colors"
      href={href}
    >
      {imageUrl && <img src={imageUrl} className="mb-6 px-3" />}
      <Title title={title} size="xs" className="mb-3 block" Component="span" />
      <Content content={content} className="text-sm block" as="span" />
      <span className="mt-4 inline-block text-xs font-bold">{label}</span>
    </a>
    <div className="text-right mt-3">
      <a href="/" className="text-xs text-muted-foreground italic">
        Ads by <strong>League of Celts</strong>
      </a>
    </div>
  </div>
)

export default AdBlock
