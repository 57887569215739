import React from "react"
import DateHeader from "./date_header"
import GreetingHeader from "./greeting_header"

export const DynamicDatetimeLabel = ({
  currentUser,
  currentDate = new Date(),
}) => (
  <div className="space-y-1">
    <GreetingHeader currentUser={currentUser} date={currentDate} />
    <DateHeader
      currentUser={currentUser}
      currentDate={currentDate}
      className="text-muted-foreground"
    />
  </div>
)

export default DynamicDatetimeLabel
