import React from "react"
import type { schema } from "@love-irish/graphql-schema"
import PageBreadcrumb from "design_system/application/components/page_breadcrumb"
import Title from "design_system/core/components/title"
import Badge from "design_system/core/components/badge"
import Content from "design_system/core/components/content"
import LofiImage from "design_system/core/components/lo_fi/image"
import ResourceItem from "@love-irish/resource-list-item"
import Button from "@love-irish/core-button"
import { IconBookmark, IconHeart } from "@tabler/icons-react"

type Topic = schema["Topic"]
type Item = schema["Item"]
type TemplateProps = {
  topic: Topic
  items: Item[]
}

export const Template = ({ topic, items = [] }: TemplateProps) => (
  <div className="container mt-8">
    <PageBreadcrumb pages={[{ name: "Topics", href: "/topics" }]} />
    <div className="grid grid-cols-3 mt-8">
      <div className="col-span-2">
        <div className="pt-6 flex gap-6">
          <div>
            <LofiImage className="w-[64px] border bg-background" />
          </div>

          <div>
            <div className="flex justify-between items-start">
              <div>
                <Title title={topic.name} size="md" />
                {topic.irishName && (
                  <div className="mt-1">{topic.irishName}</div>
                )}
              </div>
              <div className="flex ">
                <Button size="sm" variant="ghost">
                  <IconHeart className="inline" size={16} />
                </Button>
                <Button size="sm" variant="ghost">
                  <IconBookmark className="inline" size={16} />
                </Button>
              </div>
            </div>

            <Content content={topic.description} className="mt-1" />
          </div>
        </div>

        <div className="mt-24">
          <h2 className="text-xl font-bold">Everything matching this topic</h2>

          <ul>
            {items.map((item) => (
              <li key={topic.id} className="pt-6 flex gap-6">
                <ResourceItem item={item} />
              </li>
            ))}
          </ul>
        </div>
      </div>

      <div className="col-span-1"></div>
    </div>
  </div>
)

export default Template
