import React from "react"

/*
  This example requires some changes to your config:

  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/forms'),
    ],
  }
  ```
*/
const tabs = [
  // translations are in irish
  { name: "About me", href: "", translation: "Fúm" },
  { name: "Collections", href: "collections", translation: "Bailiúcháin" },
  // { name: "Achievements", href: "achievements", translation: "Éachtaí" },
  // { name: "Activity", href: "activity", translation: "Gníomhaíocht" },
]

function classNames(...classes) {
  return classes.filter(Boolean).join(" ")
}

export default function ProfileTabs({ profile, currentTab }) {
  const currentTabData = tabs.find((tab) => tab.href == currentTab) || tabs[0]
  return (
    <div className="bg-background/90 hover:bg-background transition-colors duration-200 backdrop-blur-sm">
      <div className="block sm:hidden w-full">
        <label htmlFor="tabs" className="sr-only">
          Switch tabs
        </label>
        {/* Use an "onChange" listener to redirect the user to the selected tab URL. */}
        <select
          id="tabs"
          name="tabs"
          className="block w-full rounded-md border-secondary-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500"
          value={currentTabData?.href}
          onChange={(e) =>
            (window.location.href = `/@${profile.slug}/${e.target.value}`)
          }
        >
          <option value={null} disabled>
            Switch tabs
          </option>
          {tabs.map((tab) => (
            <option key={tab.name} value={tab.href}>
              {tab.name}
            </option>
          ))}
        </select>
      </div>
      <div className="hidden sm:block">
        <div className="border-b border-secondary-200">
          <nav className="-mb-px flex space-x-8" aria-label="Tabs">
            {/* We would use react-router here since this page acts as a SPA */}
            {tabs.map((tab) => (
              <a
                key={tab.name}
                href={`/@${profile.slug}/${tab.href}`}
                className={classNames(
                  tab.name === currentTabData.name
                    ? "border-dark-500 text-dark-600 font-bold"
                    : "border-transparent text-secondary-500 hover:border-earth hover:text-secondary-700 hover:bg-earth-50/50",
                  "whitespace-nowrap border-b-2 py-4 px-3 text-sm text-center"
                )}
                aria-current={
                  tab.name === currentTabData.name ? "page" : undefined
                }
              >
                <span className="text-md block text-dark-700">{tab.name}</span>

                <span className="block text-xs italic text-secondary-500 text-normal">
                  {tab.translation}
                </span>
              </a>
            ))}
          </nav>
        </div>
      </div>
    </div>
  )
}
