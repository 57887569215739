import React from "react"
import cn from "design_system/libs/cn"
import LofiString from "./string"

const LofiInput = ({
  width = "max-w-[360px]",
  className = "",
  stringClassName,
}) => {
  return (
    <div
      className={cn(
        "rounded p-1 border flex flex-row items-center",
        width,
        className
      )}
    >
      <LofiString height="h-1" className={stringClassName} />
    </div>
  )
}

export default LofiInput
