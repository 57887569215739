import React from "react"
import Title from "design_system/core/components/title"
import Content from "design_system/core/components/content"
import Steps from "./steps"
import Button from "@love-irish/core-button"
import routes from "design_system/utils/routes"

const OverviewOnboardingPage = ({ completedStep, currentStep }) => {
  return (
    <div className="container mt-8">
      <div className="max-w-screen-lg mx-auto">
        <div className="text-center">
          <Title title="Overview" />
          <Content content="Quick breakdown of how the site works and how things are organized" />
          <div className="mt-8">
            <Steps completedStep={completedStep} currentStep={currentStep} />
          </div>
        </div>

        <div className="flex justify-end mt-4 gap-4">
          <Button as="a" href={routes.onboardingPath()} variant="outline">
            Back
          </Button>
          <Button
            type="submit"
            as="a"
            href={routes.onboardingAboutUsStepPath()}
          >
            Continue &rarr;
          </Button>
        </div>
      </div>
    </div>
  )
}

export default OverviewOnboardingPage
