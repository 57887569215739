import React from "react"
import Title from "design_system/core/components/title"
import PageBreadcrumb from "design_system/application/components/page_breadcrumb"
import routes from "design_system/utils/routes"

import type { schema } from "@love-irish/graphql-schema"
import spellData from "design_system/adventure/codex/spells"
import Content from "design_system/core/components/content"

type CollectionItem = schema["CollectionItem"]

export interface TemplateProps {
  collectionItems: CollectionItem[]
}

const sectionData = {
  january: {
    title: "January",
    translation: "Eanáir",
  },
  february: {
    title: "February",
    translation: "Feabhra",
  },
  march: {
    title: "March",
    translation: "Márta",
  },
  april: {
    title: "April",
    translation: "Aibreán",
  },
  may: {
    title: "May",
    translation: "Bealtaine",
  },
  june: {
    title: "June",
    translation: "Meitheamh",
  },
  july: {
    title: "July",
    translation: "Iúil",
  },
  august: {
    title: "August",
    translation: "Lúnasa",
  },
  september: {
    title: "September",
    translation: "Meán Fómhair",
  },
  october: {
    title: "October",
    translation: "Deireadh Fómhair",
  },
  november: {
    title: "November",
    translation: "Samhain",
  },
  december: {
    title: "December",
    translation: "Nollaig",
  },
}

export const Template = ({ collectionItems }: TemplateProps) => {
  const sectionItems = {}

  collectionItems.forEach((collectionItem) => {
    const section = collectionItem.section
    if (!sectionItems[section]) {
      sectionItems[section] = []
    }
    sectionItems[section].push(collectionItem)
  })

  return (
    <div className="container mt-8">
      <PageBreadcrumb
        pages={[{ name: "Resources", href: routes.resourcesPath() }]}
      />
      <Title title="Calendar" className="mt-6" size="lg" />

      <div className="divide-y grid gap-12">
        {Object.entries(sectionData).map(([id, section]) => {
          const items = sectionItems[id] || []

          if (items.length === 0) return null

          return (
            <div key={id} className="grid md:grid-cols-4 pt-6">
              <div className="pt-5">
                <div className="sticky top-3">
                  <h2 className="font-serif text-2xl">{section.translation}</h2>
                  <p>{section.title}</p>
                </div>
              </div>
              <div className="col-span-3 divide-y grid gap-5">
                {items.map((item) => (
                  <div key={item.id} className="pt-5">
                    <Title
                      title={item.resource?.name}
                      href={routes.calendarEventPath(item.slug)}
                    />
                    <Content
                      content={item.resource?.summary}
                      className="mt-3"
                    />
                  </div>
                ))}
              </div>
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default Template
