import React from "react"

import Button from "@love-irish/core-button"

import {
  IconBookmark,
  IconBrandSteam,
  IconChevronLeft,
  IconChevronRight,
  IconHeart,
} from "@tabler/icons-react"

const GettingStartedSection = () => (
  <div className="grid mt-24 grid-cols-5 gap-10">
    <div className="mt-24">
      <h1 className="text-3xl font-serif">Getting Started</h1>
      <p className="text-lg mt-4">
        create account - join discord - watch pronunciation video
      </p>

      <p className="mt-6">
        <a href="/topics/game">View more &rarr;</a>
      </p>
    </div>
    <div className="col-span-4">
      <div className="flex gap-4 justify-end mb-6 items-center">
        <span>1 / 3</span>
        <IconChevronLeft />
        <IconChevronRight />
      </div>
      <div className="grid gap-10 grid-cols-2 items-start">
        <div className="border rounded-t-xl rounded-b bg-background shadow-lg overflow-hidden">
          <div className="p-4 py-6">
            <div className="flex justify-between items-start mb-3">
              <div className="text-2xl font-serif">
                <a href="/resource/dicey-dungeons">Duolingo</a>
              </div>
              <div className="flex ">
                <Button size="sm" variant="ghost">
                  <IconHeart className="inline" size={16} />
                </Button>
                <Button size="sm" variant="ghost">
                  <IconBookmark className="inline" size={16} />
                </Button>
              </div>
            </div>
            <p className="italic">
              Become a giant walking dice, explore dungeons and defy Lady Luck
              in this dice powered roguelike! Now featuring "Reunion", a free
              DLC with six brand-new episodes!
            </p>

            <div className="mt-4">
              <strong>Léigh é</strong> &middot; <em>Read it</em> &rarr;
            </div>
            <div className="mt-6">
              {["vocab", "event"].map((tag) => (
                <span
                  key={tag}
                  className="mr-2 border p-2 font-bold text-sm mt-2"
                >
                  #{tag}
                </span>
              ))}
            </div>
          </div>
          <div className="px-4 py-2 border-t flex justify-between text-xs">
            <span>
              Added by <strong>@myk</strong>
            </span>
            <span>Updated 2 days ago</span>
          </div>
        </div>

        <div className="border rounded-t-xl rounded-b bg-background shadow-lg overflow-hidden bg-background">
          <div className="p-4 py-6">
            <div className="flex justify-between items-start mb-3 gap-3">
              <div className="text-2xl mb-2 font-serif">
                <a href="/resource/dicey-dungeons">St. Patrick's Day vocab</a>
              </div>
              <div className="flex">
                <Button size="sm" variant="ghost">
                  <IconHeart className="inline" size={16} /> 40k
                </Button>
                <Button size="sm" variant="ghost">
                  <IconBookmark className="inline" size={16} />
                </Button>
              </div>
            </div>
            <p className="italic">
              It might be overwhelming to know where to start when learning a
              language or maybe you just found out Irish is a language in
              Ireland and want to learn more. Here are some phrases and words to
              get you started with the celebration!
            </p>

            <div className="mt-4">
              <a href="/resource/dicey-dungeons">
                <strong>Léigh é</strong> &middot; <em>Read it</em> &rarr;
              </a>
            </div>
            <div className="mt-6">
              {["vocab", "event"].map((tag) => (
                <span
                  key={tag}
                  className="mr-2 border p-2 font-bold text-sm mt-2"
                >
                  #{tag}
                </span>
              ))}
            </div>
          </div>
          <div className="px-4 py-2 border-t flex justify-between text-xs">
            <span>
              Added by <strong>@myk</strong>
            </span>
            <span>Updated 2 days ago</span>
          </div>
        </div>
      </div>
    </div>
  </div>
)

export default GettingStartedSection
