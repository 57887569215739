import React from "react"

import duolingoIcon from "images/brand_icons/pixel/24x24/duolingo.png"
import { ProfileSocialLinkProps, types } from "./index"
import ProfileSocialLinkTemplate from "./template"

interface DuolingoProps extends Omit<ProfileSocialLinkProps, "type"> {}

const Duolingo = ({ profile, label = "Duolingo", ...args }: DuolingoProps) => {
  return (
    <ProfileSocialLinkTemplate
      site="Duolingo"
      label={label}
      username={profile.duolingoUsername}
      href={`https://duolingo.com/profile/${profile.duolingoUsername}`}
      icon={duolingoIcon}
      profile={profile}
      {...args}
    />
  )
}

export default Duolingo
