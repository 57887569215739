import React from "react"
import { Menu } from "@headlessui/react"
import Tooltip from "design_system/core/components/tooltip"
import defaultAvatar from "images/avatar/smiley/avatar@2x.png"
import Icon from "design_system/core/components/icon"
import { Avatar, Dropdown, Navbar } from "flowbite-react"
import isEmpty from "lodash/isEmpty"

const SettingsLink = () => (
  <a href="/settings" className="inline-block">
    <span className="align-middle">Settings</span>
  </a>
)

const ProfileLink = ({ username }) => (
  <a
    href={username ? `/@${username}` : "/settings/username"}
    className="inline-block"
  >
    <span className="align-middle">Profile</span>
  </a>
)

const LoginLink = ({ username }) => (
  <a href={"/login"} className="inline-block">
    <span className="align-middle">Login</span>
  </a>
)

const LearnMore = ({ username }) => (
  <a href={"/about-us"} className="inline-block">
    <span className="align-middle">Learn more</span>
  </a>
)

const CreateAccountLink = ({ username }) => (
  <a href={"/signup"} className="inline-block">
    <span className="align-middle">Signup</span>
  </a>
)

const LoggedOutWelcomeMessage = () => (
  <div className="px-1">
    Welcome{" "}
    <Tooltip
      className="text-center"
      title="You just learned &rdquo;my friend&ldquo; in Irish! ☘️"
      placement="bottom"
    >
      <strong className="font-medium text-black dark:text-white text-definition">
        a chara
      </strong>
    </Tooltip>
  </div>
)
const LoggedInWelcomeMessage = () => <div className="px-1">Welcome back!</div>

const UserAvatar = ({ currentUser }) => {
  const loggedOut = isEmpty(currentUser)
  return (
    <div className="sm:ml-6 items-center flex">
      <div className="text-right hidden sm:block">
        {loggedOut ? <LoggedOutWelcomeMessage /> : <LoggedInWelcomeMessage />}
        {loggedOut ? (
          <div className="px-1 text-xs mt-1 flex gap-2">
            <LoginLink /> &middot; <CreateAccountLink />
          </div>
        ) : (
          <div className="px-1 text-xs mt-1">
            <SettingsLink />
          </div>
        )}
      </div>

      <div className="relative ml-3 flex items-center" id="user-menu">
        {loggedOut ? (
          <img
            className="h-8 w-8 rounded-full"
            src={defaultAvatar}
            alt="Default avatar smiley face"
          />
        ) : (
          <Dropdown
            arrowIcon={false}
            inline
            className="items-center right-0"
            label={
              currentUser?.profileIconUrl ? (
                <img
                  className="w-[32px] pixel-art"
                  src={currentUser?.profileIconUrl}
                  alt="Avatar for current user"
                />
              ) : (
                <img
                  className="h-8 w-8 rounded-full"
                  src={defaultAvatar}
                  alt="Default avatar smiley face"
                />
              )
            }
          >
            {currentUser.slug && (
              <Dropdown.Header>
                <span className="block truncate text-sm font-medium">
                  @{currentUser.slug}
                </span>
              </Dropdown.Header>
            )}
            {currentUser && <Dropdown.Item href="/">Dashboard</Dropdown.Item>}
            {currentUser.slug && (
              <Dropdown.Item href={`/@${currentUser.slug}`}>
                Profile
              </Dropdown.Item>
            )}
            <Dropdown.Item href="/settings">Settings</Dropdown.Item>
            <Dropdown.Divider />
            <Dropdown.Item href="/logout">Logout</Dropdown.Item>
          </Dropdown>
        )}
      </div>
    </div>
  )
}

export default UserAvatar
