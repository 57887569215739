import React from "react"

import { gql, useMutation } from "@apollo/client"

import Button from "@love-irish/core-button"
import Signature from "./signature"

const SIGN_GUESTBOOK = gql`
  # Increments a back-end counter and gets its resulting value
  mutation SignGuestbookItem($input: SignGuestbookItemMutationInput!) {
    signGuestbookItem(input: $input) {
      success
      errors {
        message
        path
      }
    }
  }
`

const dictionary = {
  love: {
    irish: "Grá",
    translation: "Love",
  },
  happiness: {
    irish: "Sonas",
    translation: "Happiness",
  },
  peace: {
    irish: "Síocháin",
    translation: "Peace",
  },
  free: {
    irish: "Saor",
    translation: "Free",
  },
  ta_gaeilge_agam: {
    irish: "Tá Gaeilge agam",
    translation: "I speak Irish",
  },
  unity: {
    irish: "Ní neart go cur le chéile",
    translation: "There is no strength without unity",
  },
}

const Form = ({ itemId, profileSlug, signed }) => {
  const [currentWordId, setCurrentWordId] = React.useState("")
  const currentWord = dictionary[currentWordId]

  const [signGuestbook, { data, loading, error }] = useMutation(
    SIGN_GUESTBOOK,
    {
      update: (cache, result) => {
        console.log("update cache", cache, result)

        if (result?.data?.signGuestbookItem?.success) window.location.reload()
      },
    }
  )

  const handleSubmit = (e) => {
    e.preventDefault()
    signGuestbook({
      variables: {
        input: {
          irishWord: currentWord.irish,
          translation: currentWord.translation,
          itemId,
        },
      },
    })
  }
  return (
    <form onSubmit={handleSubmit}>
      <select
        className="mt-6"
        onChange={(e) => setCurrentWordId(e.target.value)}
      >
        <option>Select a word or phrase</option>
        {Object.keys(dictionary).map((wordId) => (
          <option key={wordId} value={wordId}>
            {dictionary[wordId].irish} ({dictionary[wordId].translation})
          </option>
        ))}
      </select>
      {Boolean(error || data?.signGuestbookItem?.errors?.length) && (
        <p className="text-red-500">
          There was a problem completing this action. Please try again
        </p>
      )}
      {signed && (
        <p className="mt-4">
          <span className="text-green-500 font-bold">
            You signed this stone!
          </span>{" "}
          Look for your signature in the list below.
        </p>
      )}
      {!!currentWordId && (
        <div>
          <div className="font-bold mt-4 text-sm">Preview</div>
          <div>
            <Signature
              signature={{
                irish: currentWord?.irish,
                translation: currentWord?.translation,
              }}
            />
          </div>
        </div>
      )}
      <div className="mt-4 flex gap-2 items-center">
        {!signed && (
          <Button
            type="submit"
            disabled={!profileSlug || !currentWord || loading}
          >
            Sign
          </Button>
        )}
        {!profileSlug && (
          <p className="text-sm">
            You must have a profile before you can use this feature.
          </p>
        )}
      </div>
    </form>
  )
}

export default Form
