import React from "react"
import "./index.css"
import {
  IconHelp,
  IconDotsVertical,
  IconHeartFilled,
  IconHeartBroken,
} from "@tabler/icons-react"
import Tooltip from "design_system/core/components/tooltip"
import { Profile } from "design_system/utils/types/profile"
import Excerpt from "design_system/core/components/excerpt"
import ProfileSocialLink from "design_system/community/components/profile_social_link"
import Button from "@love-irish/core-button"
import classnames from "classnames"
import LofiImage from "design_system/core/components/lo_fi/image"

// import {
//   DropdownMenu,
//   DropdownMenuContent,
//   DropdownMenuGroup,
//   DropdownMenuItem,
//   DropdownMenuLabel,
//   DropdownMenuPortal,
//   DropdownMenuSeparator,
//   DropdownMenuShortcut,
//   DropdownMenuSub,
//   DropdownMenuSubContent,
//   DropdownMenuSubTrigger,
//   DropdownMenuTrigger,
// } from "design_system/core/components/dropdown-menu"

export interface ProfileCardProps {
  profile: Profile
  showFollowButton?: boolean
  banner?: boolean
  showStats?: boolean
}

// const Menu = () => (
//   <DropdownMenu>
//     <DropdownMenuTrigger asChild>
//       <Button variant="outline" color="secondary">
//         <IconDotsVertical size={16} />
//       </Button>
//     </DropdownMenuTrigger>
//     <DropdownMenuContent className="w-56">
//       <DropdownMenuLabel>My Account</DropdownMenuLabel>
//       <DropdownMenuSeparator />
//       <DropdownMenuGroup>
//         <DropdownMenuItem>
//           Profile
//           <DropdownMenuShortcut>⇧⌘P</DropdownMenuShortcut>
//         </DropdownMenuItem>
//         <DropdownMenuItem>
//           Billing
//           <DropdownMenuShortcut>⌘B</DropdownMenuShortcut>
//         </DropdownMenuItem>
//         <DropdownMenuItem>
//           Settings
//           <DropdownMenuShortcut>⌘S</DropdownMenuShortcut>
//         </DropdownMenuItem>
//         <DropdownMenuItem>
//           Keyboard shortcuts
//           <DropdownMenuShortcut>⌘K</DropdownMenuShortcut>
//         </DropdownMenuItem>
//       </DropdownMenuGroup>
//       <DropdownMenuSeparator />
//       <DropdownMenuGroup>
//         <DropdownMenuItem>Team</DropdownMenuItem>
//         <DropdownMenuSub>
//           <DropdownMenuSubTrigger>Invite users</DropdownMenuSubTrigger>
//           <DropdownMenuPortal>
//             <DropdownMenuSubContent>
//               <DropdownMenuItem>Email</DropdownMenuItem>
//               <DropdownMenuItem>Message</DropdownMenuItem>
//               <DropdownMenuSeparator />
//               <DropdownMenuItem>More...</DropdownMenuItem>
//             </DropdownMenuSubContent>
//           </DropdownMenuPortal>
//         </DropdownMenuSub>
//         <DropdownMenuItem>
//           New Team
//           <DropdownMenuShortcut>⌘+T</DropdownMenuShortcut>
//         </DropdownMenuItem>
//       </DropdownMenuGroup>
//       <DropdownMenuSeparator />
//       <DropdownMenuItem>GitHub</DropdownMenuItem>
//       <DropdownMenuItem>Support</DropdownMenuItem>
//       <DropdownMenuItem disabled>API</DropdownMenuItem>
//       <DropdownMenuSeparator />
//       <DropdownMenuItem>
//         Log out
//         <DropdownMenuShortcut>⇧⌘Q</DropdownMenuShortcut>
//       </DropdownMenuItem>
//     </DropdownMenuContent>
//   </DropdownMenu>
// )

const ProfileCard = ({
  profile,
  showFollowButton = true,
  showStats = true,
  banner = false,
}: ProfileCardProps) => {
  const i18nDate = new Date(profile.createdAt)
  const formattedDate = i18nDate.toLocaleDateString("en-US", {
    month: "short",
    day: "numeric",
    year: "numeric",
  })

  const profilePath = `/@${profile.slug}`
  const profileUsername = `@${profile.slug}`
  const icon = profile.icon || profile.profileIcon

  return (
    <div className="card profile-card">
      <div className="profile-card-body">
        {banner &&
          (profile.banner ? (
            <a href={profilePath}>
              <img
                className="w-full pixel-art rounded-t"
                src={profile.banner.imageUrl}
              />
            </a>
          ) : (
            <a href={profilePath}>
              <div className="w-full bg-secondary-50 h-40 border-b"></div>
            </a>
          ))}
        <div className="flex gap-6 p-5">
          <div className="flex-shrink-0">
            <a href={profilePath}>
              {icon ? (
                <img className=" pixel-art" src={icon.imageUrl} width={64} />
              ) : (
                <LofiImage className="w-[64px]" />
              )}
            </a>
          </div>
          <div className="flex-grow min-w-0">
            <h1 className="font-fancy text-xl sm:text-2xl break-words">
              <a href={profilePath}>{profile.name || profileUsername}</a>
            </h1>
            <p className="text-sm">
              <a href={profilePath}>{profileUsername}</a>
            </p>
            {profile.shortBio && (
              <Excerpt className="text-xs mt-2 italic break-words" lines="3">
                {profile.shortBio}
              </Excerpt>
            )}
          </div>
        </div>
        <div className="profile-card-content">
          <div className="divide-y gap-3 grid pb-3">
            {showStats && profile.slug && (
              <div className="grid gap-2 divide-x text-center grid-flow-col mt-3 mb-4">
                <div className="grid gap-1">
                  <div className="text-xs">Followers</div>
                  <h3 className="text-xl font-mono">
                    {profile.profileFollowsCount || 0}
                  </h3>
                </div>
                <div className="grid gap-1 pl-2">
                  <div className="text-xs">Following</div>
                  <h3 className="text-xl font-mono">
                    {profile.followingProfileCount || 0}
                  </h3>
                </div>
              </div>
            )}
            <div className="px-3 pt-3">
              <ul className="grid gap-2">
                <li>Joined {formattedDate}</li>
                {profile.correctionsWelcome && (
                  <li>
                    Corrections welcome{" "}
                    <Tooltip title="People can suggest corrections on your content">
                      <IconHelp
                        className="inline-block text-secondary-400 align-middle"
                        size={16}
                      />
                    </Tooltip>
                  </li>
                )}
              </ul>
            </div>
            {[profile.facebookUsername, profile.duolingoUsername].some(
              (value) => value
            ) && (
              <div className="px-3 pt-4 pb-1">
                <ul className="grid gap-2">
                  {profile.duolingoUsername && (
                    <li>
                      <ProfileSocialLink
                        profile={profile}
                        type="duolingo"
                        label="username"
                      />
                    </li>
                  )}

                  {profile.facebookUsername && (
                    <li>
                      <ProfileSocialLink
                        profile={profile}
                        type="facebook"
                        label="username"
                      />
                    </li>
                  )}
                </ul>
              </div>
            )}
            {profile.type == "Profile::Npc" && (
              <div className="pt-3">
                <div className="bg-success-50 text-success-600 p-3 rounded border-success border">
                  This is a NPC profile and is automatically managed by our
                  system.
                </div>
              </div>
            )}
            {showFollowButton && !profile.canEdit && (
              <div className="pt-3 flex gap-2">
                {profile.isFollowing ? (
                  <Button
                    className="grow font-bold group"
                    color="error"
                    variant="outline"
                  >
                    <span className="inline-block group-hover:hidden">
                      <IconHeartFilled
                        size={16}
                        className="text-red-400 inline-block mr-2"
                      />
                      Following
                    </span>
                    <span className="hidden group-hover:inline-block">
                      <IconHeartBroken
                        size={16}
                        className="text-red-400 inline-block mr-2"
                      />
                      Unfollow
                    </span>
                  </Button>
                ) : (
                  <Button className="grow font-bold" as="a" href={"/login"}>
                    <IconHeartFilled
                      size={16}
                      className="text-red-400 inline-block"
                    />
                    ️ Follow
                  </Button>
                )}
              </div>
            )}
            {profile.canEdit && (
              <div className="pt-3">
                <Button
                  className="block"
                  variant="soft"
                  color="secondary"
                  href="/settings/profile"
                  as="a"
                >
                  Edit profile
                </Button>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default ProfileCard
