import { useBotUI, useBotUIMessage } from "@botui/react"
import React from "react"
import CodexRecord from "../blocks/codex_record"
import SpellCast from "../blocks/spell_cast"
import LootDrop from "../blocks/loot_drop"
import GameCredit from "../blocks/game_credit"
import { generateFullItem } from "../../../../../app/design_system/utils/sample_data/item"
import Content from "../../../../../app/design_system/core/components/content"

const MiniGameResponse = ({ message }) => {
  return <div>{message.data.value}</div>
}

const CastSpellResponse = ({ message }) => (
  <CodexRecord
    item={{
      ...message.data.spell,
      name: message.data.spell.spellText.irish,
      translation: message.data.spell.spellText.english,
    }}
  />
)

export const renderer = {
  text: ({ message }) => (
    <Content
      content={message?.data?.text}
      className="prose-sm text-inherit text-sm text-dark dark:text-white !font-mono first-line:not-italic"
    />
  ),
  codexRecord: ({ message }) => {
    return <CodexRecord {...message.data} />
  },
  lootDrop: ({ message }) => {
    return <LootDrop {...message.data} />
  },
  miniGameResponse: MiniGameResponse,
  // The reponse is the user invoking the spell
  spellCastResponse: CastSpellResponse,
  // This is the actual spell cast card
  spellCast: ({ message }) => {
    return <SpellCast {...message.data} />
  },
  gameCredit: ({ message }) => {
    return <GameCredit {...message.data} />
  },
}
