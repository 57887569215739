import React, { useEffect } from "react"
import Title from "design_system/core/components/title"
import {
  IconBook,
  IconPlayerPlay,
  IconSearch,
  IconVolume,
} from "@tabler/icons-react"
import classNames from "classnames"
import Tooltip from "design_system/core/components/tooltip"
import Content from "design_system/core/components/content"
import Button from "design_system/core/components/button"
import useSound from "use-sound"
import "./index.css"
import Excerpt from "design_system/core/components/excerpt"

import tadhgAvatar from "images/profile_icons/tadhg.png"

// We support a simple version of liquid type tags in codex record names to help
// with examples. Right now we use @grug as the data source for the dynamic tag.
export function addDynamicTags(string) {
  return string.replace("_name_", "_@grug_")
}

const CodexRecord = ({
  className = "",
  content = null,
  addon = true,
  displayAudioInfo = false,
  level = 1,
  item,
  ...props
}) => {
  const [play] = useSound(item.audioUrl || "")

  const handlePlay = (e) => {
    e.stopPropagation()
    play()
  }
  return (
    <div className={className}>
      <div className="px-4 pt-4 text-2xs text-secondary-600 flex gap-1 justify-between items-center">
        <div className="flex gap-1 items-center">
          <IconBook
            className="text-secondary-400 inline-block align-middle"
            size={14}
          />
          <Tooltip title="Dictionary words or phrases that can gain experience in certain parts of the app">
            Codex record
          </Tooltip>
        </div>
        {addon && (
          <span className="font-bold text-warning flex gap-2 items-center">
            <div className="bg-warning-50 rounded overflow-hidden h-1.5 w-8 flex border border-warning">
              <div className="bg-warning" style={{ width: "50%" }}>
                &nbsp;
              </div>
            </div>
            LVL {level}
          </span>
        )}
      </div>
      <div
        className="post-title text-2xl text-definition px-4 pb-6 pt-3 flex gap-2"
        {...props}
      >
        <Title
          title={addDynamicTags(item.name)}
          size="sm"
          showIcon={false}
          href={!!item.slug && `/codex/${item.slug}`}
        />
        {item.audioUrl && (
          <button
            className="!bg-none cursor-pointer"
            onClick={handlePlay}
            type="button"
          >
            <IconVolume size={24} className="inline-block text-secondary-300" />
          </button>
        )}
      </div>

      {item.translation && (
        <div className="px-4 pb-4">
          <p className="text-xs text-secondary uppercase mb-2">Translation:</p>
          <Content
            content={addDynamicTags(item.translation)}
            className="prose-sm text-inherit text-sm text-dark dark:text-white !font-mono first-line:not-italic"
          />
        </div>
      )}

      {item.pronunciation && (
        <div className="px-4 pb-4">
          <p className="text-xs text-secondary uppercase mb-2">
            Rough pronunciation for English speakers:
          </p>
          <Content
            content={item.pronunciation}
            className="prose-sm text-inherit text-sm text-dark dark:text-white !font-mono first-line:not-italic"
          />
        </div>
      )}
      {item.audioUrl && displayAudioInfo && (
        <div className="p-2">
          <div className="flex justify-start gap-2 bg-earth-50/70 text-earth-800 p-2 rounded border border-earth-200 items-center">
            <img src={tadhgAvatar} className="w-[32px] pixel-art" />
            <button
              aria-label="Play audio"
              className="!bg-none cursor-pointer"
              onClick={handlePlay}
              type="button"
            >
              <IconPlayerPlay size={24} className="inline-block" />
            </button>
            <p>
              Featured community audio from{" "}
              <a href="/@tadhg" className="font-bold">
                @tadhg
              </a>
            </p>
          </div>
        </div>
      )}

      {item.summary && (
        <div className="px-4 pb-4">
          <Content
            content={item.summary}
            className="prose-sm text-inherit text-sm text-dark dark:text-white !font-mono first-line:not-italic"
          />
        </div>
      )}

      {addon && (
        <div className="p-3 text-xs  rounded-b border-t border-secondary-200 flex gap-2 bg-secondary-50 text-secondary-600 dark:bg-dark-900 dark:text-secondary-100 dark:border-dark-500">
          <IconSearch
            className="text-secondary-400 inline-block align-middle"
            size={14}
          />
          <div>
            {level === 1 ? "Unlocked a new codex record!" : "Found again!"}{" "}
            <span className="text-warning font-bold dark:text-warning-400">
              + 5 EXP
            </span>
          </div>
        </div>
      )}
    </div>
  )
}

export default CodexRecord
