import React from "react"
import Title from "design_system/core/components/title"
import Content from "design_system/core/components/content"
import Steps from "./steps"
import Button from "@love-irish/core-button"
import routes from "design_system/utils/routes"

const GetStartedOnboardingPage = ({ completedStep, currentStep }) => {
  return (
    <div className="container mt-8">
      <div className="max-w-screen-lg mx-auto">
        <div className="text-center">
          <Title title="Your adventure begins" />
          <Content content="This might be the end of the onboarding flow but it's just the start of your adventure on this site" />
          <div className="mt-8">
            <Steps completedStep={completedStep} currentStep={currentStep} />
          </div>
        </div>

        <div className="flex justify-end mt-4 gap-4">
          <Button
            as="a"
            href={routes.onboardingAboutUsStepPath()}
            variant="outline"
          >
            Back
          </Button>
          <Button as="a" href={routes.dashboardPath()}>
            Go to dashboard &rarr;
          </Button>
        </div>
      </div>
    </div>
  )
}

export default GetStartedOnboardingPage
