import React from "react"
import ProfileCard from "design_system/community/components/profile_card"
import Title from "design_system/core/components/title"
import { ProfileBanner } from "@love-irish/calendar-event-irish-week-2025-template"

export const Template = ({ profiles }) => (
  <div className="container mt-12">
    <Title title="Community" />

    <h2 className="mt-16 text-lg mb-4 font-bold">Featured profiles</h2>
    <div className="grid md:grid-cols-3 gap-4">
      {profiles.map((profile) => (
        <ProfileCard
          profile={profile}
          key={profile.slug}
          banner={true}
          showFollowButton={false}
          showStats={false}
        />
      ))}
    </div>

    <div className="mt-8">
      <ProfileBanner />
    </div>
  </div>
)

export default Template
