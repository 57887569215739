import React from "react"
import PageBreadcrumb from "design_system/application/components/page_breadcrumb"
import Title from "design_system/core/components/title"

import grugArcadeBanner from "images/love_irish/adventure/arcade-grug@2x.png"
import Content from "design_system/core/components/content"
import ResourceListItem from "@love-irish/resource-list-item"
import { ProfileBanner } from "@love-irish/resource-calendar-event-template"
import { IconRefresh, IconWand, IconListDetails } from "@tabler/icons-react"

export const Template = ({ games }) => (
  <div className="container mt-8">
    <PageBreadcrumb pages={[{ name: "Adventure", url: "/adventure" }]} />
    <div className="flex justify-between my-8 items-start gap-24 flex-col-reverse md:flex-row">
      <div>
        <Title title="Arcade" size="lg" />
        <Content
          content="Play games, earn experience, find loot and even learn a cúpla focal (couple words) along the way."
          className="text-lg mt-2"
        />

        <hr className="w-44 my-6" />

        <Content
          content="These minigames are a fun way to practice Irish while earning loot you can show off on your profile. Choose from game modes like random, personalized learning based on your own codex or use a collection to limit the options to its contents. Game play settings generally include number of options, difficulty level and limting to words or phrases."
          className="mt-4"
        />

        <div className="max-w-3xl grid grid-flow-col gap-8 mt-12">
          <div>
            <h2 className="font-bold">
              <IconRefresh size={20} className="inline-block mr-2" />
              Random
            </h2>
            <Content
              content="**Use random entries from the codex at all difficulty levels**. Great for more fluent speakers to quickly build up their codex."
              className="mt-3 text-sm"
            />
          </div>
          <div>
            <h2 className="font-bold">
              <IconListDetails size={20} className="inline-block mr-2" />{" "}
              Collection
            </h2>
            <Content
              content="**Use a collection and it's codex records** as the source to generate a game. Great for focusing on a specific topic or level."
              className="mt-3 text-sm"
            />
          </div>
          <div>
            <h2 className="font-bold">
              <IconWand size={20} className="inline-block mr-2" />
              Personalized
            </h2>
            <Content
              content="**Use your codex to create a personalized game** based on magic like spaced repetition learning"
              className="mt-3 text-sm"
            />
          </div>
        </div>
      </div>
      <div className="shrink-0">
        <img
          src={grugArcadeBanner}
          className="pixel-art block relative w-[272px] max-w-full"
        />
      </div>
    </div>

    <div className="grid md:grid-cols-3 gap-8 mt-24">
      {games.map((game) => (
        <div key={game.gid}>
          <ResourceListItem item={game} />
        </div>
      ))}
      <div className="border-dashed border-3 rounded-xl p-6 text-center flex items-center justify-center">
        <div>
          <Title title="More games coming soon" size="xs" />
          <Content
            content="We are working on more minigames for the web as well as on Steam. Learn more about our progress on our blog."
            className="mt-2 text-sm"
          />
        </div>
      </div>
    </div>

    <ProfileBanner />
  </div>
)

export default Template
