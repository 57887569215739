import React from "react"

import Title from "design_system/core/components/title"
import PageBreadcrumb from "design_system/application/components/page_breadcrumb"
import { Interview } from "design_system/utils/types/item"
import Content from "design_system/core/components/content"
import InterviewOverview from "design_system/application/components/interview_overview"
import InterviewQuestionList from "design_system/application/components/interview_question_list"
import formatter from "design_system/utils/formatter"

import "./index.css"
import AddToCollection from "@love-irish/resource-add-to-collection"

interface InterviewDetailsTemplateProps {
  item: Interview
  currentUser?: any
}

const InterviewDetailsTemplate = ({
  item,
  currentUser,
}: InterviewDetailsTemplateProps) => {
  return (
    <div className="container mt-6 w-auto">
      <div className="grid md:grid-cols-12">
        <div className="col-span-12 sm:col-span-8">
          <header className="flex justify-between flex-col">
            <div>
              <PageBreadcrumb
                pages={[{ name: "Interviews", href: "/interviews" }]}
              />
            </div>
            <div>
              <div className="mt-8 break-words">
                <Title
                  size="lg"
                  title={item.name}
                  className="block md:hidden"
                />
                <Title
                  title={item.name}
                  size="xl"
                  className="hidden md:block"
                />
              </div>
            </div>
          </header>
          <div className="grid gap-12 grid-cols-1 md:grid-cols-8 md:mt-20 print:grid-cols-1">
            <div className="md:col-span-2 sm:mt-32">
              <div className="text-secondary-600">
                <div className="mt-4 text-xs">
                  Moderated by{" "}
                  <strong>
                    <a href="/@myk">@myk</a>
                  </strong>{" "}
                  on{" "}
                  <time className="font-bold">
                    {formatter.shortDate(item.moderatedAt)}
                  </time>
                </div>
                <div className="mt-2 text-xs">
                  Published on{" "}
                  <time className="font-bold">
                    {formatter.shortDate(item.publishedAt)}
                  </time>
                </div>
              </div>

              <div className="mt-4 sticky top-10">
                <hr className="w-[50px] mt-4 mb-4" />
                <AddToCollection
                  itemId={item.gid}
                  currentUser={currentUser}
                  align="start"
                />
                <hr className="w-[50px] mt-4 mb-4" />
                <h3 className="text-xs text-secondary-600">Jump to section:</h3>
                <ul className="mt-2">
                  <li>
                    <a href="#intro">Intro</a>
                  </li>
                  <li>
                    <a href="#format">Format</a>
                  </li>
                  <li>
                    <a href="#questions">Questions</a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-span-6">
              <Content
                id="intro"
                content={item.content}
                dropcap={"md"}
                className="!max-w-full prose-headings:!text-sm"
              />
              <div className="pt-4 clear-left">
                <hr className="mt-6 mb-10" />
                <InterviewOverview id="format" />
                <hr className="mt-6 mb-10" />
                <InterviewQuestionList questions={item.questions} />
              </div>
            </div>
          </div>
        </div>

        <div className="hidden sm:block col-span-4">
          {item.image?.url && (
            <img
              src={item.image.url}
              className="interview-cover-art"
              alt="Cover art for interview"
            />
          )}
        </div>
      </div>
    </div>
  )
}

export default InterviewDetailsTemplate
