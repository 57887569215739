import React from "react"

export const TopicBadge = ({ topic }) => (
  <a
    href={`/topics/${topic.slug}`}
    key={topic.slug}
    className="mr-2 border px-2 py-1 inline-block mt-2 text-sm rounded"
  >
    <span className="text-secondary">#</span>
    <span className="font-bold">{topic.slug}</span>
  </a>
)

export default TopicBadge
