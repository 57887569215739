import React, { Fragment } from "react"
import "./index.css"
import PageBreadcrumb from "design_system/application/components/page_breadcrumb"
import ProfileCard from "design_system/community/components/profile_card"

import classNames from "classnames"
import { Profile } from "design_system/utils/types/profile"
import ProfileTabs from "./tabs"
import Top9 from "./top_9"
import Content from "design_system/core/components/content"
import AdventureBanner from "@love-irish/adventure-banner-component"
import ResourceListItem from "@love-irish/resource-list-item"

interface UserProfileTemplateProps {
  profile: Profile
  currentTab?: string
  currentUser?: any
}

const About = ({ profile }) => (
  <div className="prose pt-10 sm:p-10">
    {profile.fullBio && <Content dropcap="sm" content={profile.fullBio} />}

    {!profile.fullBio && <div>No bio yet so not much is known about them.</div>}
  </div>
)

const Collections = ({ profile, currentUser }) => (
  <ul className="space-y-8 pt-10">
    {profile.collections?.map((collection) => (
      <li key={collection.gid}>
        <ResourceListItem item={collection} currentUser={currentUser} />
      </li>
    ))}
  </ul>
)

const profileTabComponents = {
  about: About,
  collections: Collections,
}

const UserProfileTemplate = ({
  profile,
  currentTab,
  currentUser,
}: UserProfileTemplateProps) => {
  const SectionComponent =
    profileTabComponents[currentTab] || profileTabComponents.about

  return (
    <div>
      <div className="container pt-8">
        <PageBreadcrumb pages={[{ name: "Community", href: "/community" }]} />
      </div>
      <div className="mt-8">
        <AdventureBanner banner={profile.banner} />
      </div>
      <section className="pt-16">
        <main className="container z-1 sm:grow sm:flex sm:flex-col items-stretch">
          <div className="grid grid-cols-12 gap-6 px-2 sm:px-0 sm:grow">
            <div
              className={classNames(
                "col-span-12 sm:col-span-5 lg:col-span-4",
                "-mt-10 lg:-mt-36"
              )}
            >
              <div className="relative sm:sticky sm:top-5 overflow-x-auto pb-10">
                <ProfileCard profile={profile} />
                {profile.top9 && (
                  <div className="mt-7">
                    <Top9 profile={profile} />
                  </div>
                )}
              </div>
            </div>

            <div className="col-span-12 sm:col-span-7 lg:col-span-8">
              <div className="sticky top-5">
                <ProfileTabs profile={profile} currentTab={currentTab} />
              </div>

              <SectionComponent profile={profile} currentUser={currentUser} />
            </div>
          </div>
        </main>
      </section>
    </div>
  )
}

export default UserProfileTemplate
