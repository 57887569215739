import React, { useState } from "react"

import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuPortal,
  DropdownMenuSeparator,
  DropdownMenuShortcut,
  DropdownMenuSub,
  DropdownMenuSubContent,
  DropdownMenuSubTrigger,
  DropdownMenuCheckboxItem,
  DropdownMenuTrigger,
} from "@love-irish/ui-components/dropdown-menu"

import Button from "@love-irish/core-button"
import { IconBookmark, IconLoader } from "@tabler/icons-react"
import Content from "design_system/core/components/content"
import Tooltip from "design_system/core/components/tooltip"
import { useMutation, useLazyQuery } from "@apollo/client"
import query from "./profile_collections.graphql"
import mutation from "./add_to_collection.graphql"
import { toast } from "sonner"

export const AddToCollection = ({ itemId, align = "end" }) => {
  const [open, setOpen] = useState(false)
  const [getCurrentProfileCollections, { loading, error, data }] =
    useLazyQuery(query)
  const [addResourceToCollection] = useMutation(mutation)

  const collections = data?.currentProfile?.collections?.nodes || []

  const handleSelect = (e, collection) => {
    e.preventDefault()
    addResourceToCollection({
      variables: {
        input: { collectionId: collection.id, collectionResourceId: itemId },
      },
    }).then((result) => {
      if (result?.data?.createCollectionResource?.success) {
        setOpen(false)
        toast.success(
          <p className="prose prose-sm">
            Resource added to collection{" "}
            <a href={collection.path}>{collection.name}</a>
          </p>
        )
      } else {
        toast.error("There was a problem adding the resource to collection")
      }
    })
  }

  const handleOpenState = (menuNowOpen) => {
    setOpen(menuNowOpen)
    if (menuNowOpen) {
      getCurrentProfileCollections()
    }
  }

  return (
    <DropdownMenu open={open} onOpenChange={handleOpenState}>
      <Tooltip title="Add to collection">
        <DropdownMenuTrigger asChild>
          <Button
            variant="ghost"
            size="sm"
            color="secondary"
            aria-label="Add to collection"
          >
            <IconBookmark size={16} />
          </Button>
        </DropdownMenuTrigger>
      </Tooltip>

      <DropdownMenuContent className="w-72 overflow-visible" align={align}>
        <DropdownMenuLabel>Your collections </DropdownMenuLabel>
        <DropdownMenuSeparator />
        <DropdownMenuGroup className="max-h-[350px] overflow-y-auto space-y-2">
          {collections.map((collection) => {
            return (
              <DropdownMenuItem
                className="flex-col justify-start items-start gap-1 cursor-pointer"
                key={collection.id}
                onSelect={(e) => handleSelect(e, collection)}
              >
                <Content
                  className="line-clamp-2 text-sm text-foreground first-line:not-italic"
                  content={collection.name}
                />
                <DropdownMenuShortcut className="ml-0">
                  /{collection.slug}
                </DropdownMenuShortcut>
              </DropdownMenuItem>
            )
          })}
          {loading && (
            <IconLoader className="text-muted-foreground inline" size={16} />
          )}
          {!loading && collections.length === 0 && (
            <div className="p-3 prose prose-sm">
              No collections yet–<a href="/collections/new">create one</a> to
              use this feature 🤑
            </div>
          )}
        </DropdownMenuGroup>
        <DropdownMenuSeparator />
        <DropdownMenuGroup className="grid grid-cols-2 gap-2">
          <Button as="a" href="/collections/new" size="sm">
            New Collection
          </Button>
          <Button
            variant="soft"
            color="secondary"
            size="sm"
            onClick={() => setOpen(false)}
          >
            Cancel
          </Button>
        </DropdownMenuGroup>
      </DropdownMenuContent>
    </DropdownMenu>
  )
}

export default AddToCollection
