import React from "react"

import ProfileBanner from "./banners/profile"
import PageBreadcrumb from "design_system/application/components/page_breadcrumb"
import AdventureBanner from "@love-irish/adventure-banner-component"
import Content from "design_system/core/components/content"
import Title from "design_system/core/components/title"
import { CodexRecordBlock } from "@love-irish/adventure-story-teller"

// {
//   sections.map((section) => {
//     const items = sectionItems[section] || []
//
//     return (
//       <div>
//         {section.name}
//
//         <div>
//           {items.map((item) => (
//             <div>{item.name}</div>
//           ))}
//         </div>
//       </div>
//     )
//   })
// }

export const TemplateComponent = ({ collection, items }) => {
  const sectionItems = {}

  items.forEach((item) => {
    if (!sectionItems[item.section]) {
      sectionItems[item.section] = []
    }

    sectionItems[item.section].push(item)
  })

  return (
    <>
      <div className="container mt-8">
        <PageBreadcrumb pages={[{ name: "Calendar", href: "/calendar" }]} />
      </div>
      {!!collection.banner && (
        <div className="mt-8">
          <AdventureBanner banner={collection.banner} />
        </div>
      )}
      <div className="container mt-16">
        <div className="grid md:grid-cols-2">
          <div className="col-span-2 mb-4">
            <Title title={collection.name} size="lg" />
          </div>
        </div>
        <div className="grid md:grid-cols-5 gap-8 mt-8">
          <div className="text-lg space-y-5 md:col-span-3">
            <Content content={collection.content} />
          </div>
          <div className="md:col-span-2">
            {(sectionItems.header || []).map((item, index) => {
              if (!item.resource || item.resource.type != "Item::CodexRecord")
                return null

              item.resource.slug = null

              return (
                <CodexRecordBlock
                  className="border"
                  addon={false}
                  item={item.resource}
                  key={index}
                  displayAudioInfo
                />
              )
            })}
          </div>
        </div>
      </div>

      <div className="container mt-24">
        <hr className="mb-8" />
        <div className="grid mt-24">
          <div className="max-w-xl mx-auto">
            <h2 className="text-5xl font-serif text-center mb-0">
              Things to do
            </h2>
            <p className="text-xl text-center text-gray-500 mt-6">
              This event is mostly self-paced so feel free to explore and engage
              how you like, from watching videos on YouTube or TG4, to joining a
              local group or online community.
            </p>
          </div>
        </div>
      </div>

      <div className="text-center mt-24">
        <p>Nothing here yet–check back later</p>
      </div>
    </>
  )
}

export { ProfileBanner }
export default TemplateComponent
