import React, { useContext, useState } from "react"

import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
  DialogFooter,
} from "@love-irish/ui-components/dialog"

import Button from "@love-irish/core-button"
import { IconBookmark } from "@tabler/icons-react"
import Content from "design_system/core/components/content"
import Tooltip from "design_system/core/components/tooltip"
import Menu from "./menu"
import { CurrentUserContext } from "@love-irish/application-session-provider"

const AddToCollection = ({ itemId, align }) => {
  const currentUser = useContext(CurrentUserContext)

  if (currentUser) {
    return <Menu itemId={itemId} align={align} />
  }

  return (
    <Dialog>
      <Tooltip title="Add to collection">
        <DialogTrigger asChild>
          <Button
            variant="ghost"
            size="sm"
            color="secondary"
            aria-label="Add to collection"
          >
            <IconBookmark size={16} />
          </Button>
        </DialogTrigger>
      </Tooltip>
      <DialogContent className="bg-white">
        <DialogHeader>
          <DialogTitle>You need an account to use this feature</DialogTitle>
          <DialogDescription className="py-4" asChild>
            <Content
              content="Sign in or create an account to save resources to your collections."
              className="prose-sm text-inherit text-sm text-dark dark:text-white !font-mono first-line:not-italic"
            />
          </DialogDescription>
        </DialogHeader>
        <DialogFooter>
          <Button as="a" href="/signup" variant="soft">
            Create an account
          </Button>
          <Button as="a" href="/login">
            Login
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  )
}

export default AddToCollection
