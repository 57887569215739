import React, { useEffect } from "react"
import Title from "design_system/core/components/title"
import { IconBackpack, IconStar, IconVolume } from "@tabler/icons-react"
import classNames from "classnames"
import Tooltip from "design_system/core/components/tooltip"
import Content from "design_system/core/components/content"
import Button from "design_system/core/components/button"
import useSound from "use-sound"
import "./index.css"
import Excerpt from "design_system/core/components/excerpt"

const LootDropBlock = ({
  className,
  children,
  previousDisplayCount = 0,
  item,
  ...props
}) => {
  const [play] = useSound(item.audioUrl)

  const handlePlay = (e) => {
    e.stopPropagation()
    play()
  }
  return (
    <div className={className}>
      <div className="px-4 pt-4 text-xs text-warning-700 flex gap-1 items-center">
        <IconStar
          className="text-warning inline-block align-middle  shrink-0"
          size={14}
        />
        <Tooltip title="Loot drops can happen randomly or for completing stories and can include items, gold and experience">
          Loot drop
        </Tooltip>
      </div>
      <div className="">
        {item.icon && (
          <img
            src={item.icon}
            alt={item.name}
            className="h-[64px] w-[64px] pixel-art flex-shrink-0 float-right mr-4 mb-2 ml-4"
          />
        )}
        <p
          className="post-title text-2xl text-definition px-4 pb-6 pt-3"
          {...props}
        >
          {item.name}
        </p>
      </div>

      {item.summary && (
        <div className="px-4 pb-4">
          <Content
            content={item.summary}
            className="prose-sm text-inherit text-sm text-dark dark:text-white !font-mono first-line:not-italic"
          />
        </div>
      )}

      <div className="px-4 py-3 text-xs rounded-b border-t border-warning flex gap-2 bg-warning-50 text-warning-700 dark:bg-dark-900 dark:text-warning-100 dark:border-dark-500">
        <p>Loot drop rewards have been added to your inventory</p>
      </div>
    </div>
  )
}

export default LootDropBlock
