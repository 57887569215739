import React from "react"
import classnames from "classnames"

import cookie from "images/pixel_art/food/cookie.png"

const CookieConsent = (_props, _context) => {
  const [cookiesAccepted, setCookiesAccepted] = React.useState(false)
  const handleClick = () => {
    setCookiesAccepted(true)
    document.cookie = "li_ca=true; path=/;"
  }

  return (
    <div
      id="cookies-corner-modal"
      aria-hidden="false"
      tabIndex="-1"
      className={classnames("fixed bottom-0 right-0 z-50", {
        hidden: cookiesAccepted,
      })}
    >
      <div className="relative p-4 w-full max-w-xl h-full md:h-auto">
        <div className="relative bg-background rounded-xl shadow-xl dark:bg-gray-800 z-50 border">
          <div className="p-5 text-sm font-light text-gray-500 dark:text-gray-400">
            <div className="flex gap-4 items-center mb-3">
              <img
                src={cookie}
                width={16 * 2}
                className="pixel-art"
                alt="Tasty chocolate chip cookie"
              />
              <h1 className="text-sm font-bold text-dark-950">
                An bhfuil cookie agat?
              </h1>
            </div>

            <p className="mb-2">
              We use cookies to improve your experience on the site. We don't
              use third-party advertising software or sell your data. By using
              this site you agree to letting us use cookies for sessions and
              other operational purposes.
            </p>
          </div>
          <div className="justify-between items-center p-6 pt-0 space-y-4 sm:flex sm:space-y-0">
            <div className="items-center space-y-4 sm:space-x-4 sm:flex sm:space-y-0">
              <button
                id="accept-cookies"
                type="button"
                onClick={handleClick}
                className="py-2 px-4 w-full text-sm font-medium text-center text-white rounded-lg bg-dark-700 sm:w-auto hover:bg-dark-800 focus:ring-4 focus:outline-none focus:ring-primary-300 dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800"
              >
                Accept &amp; continue
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
export default CookieConsent
