import React from "react"

import { IconHeartFilled } from "@tabler/icons-react"
import { Profile } from "design_system/utils/types/profile"
import Excerpt from "../../../core/components/excerpt"

interface Top9Props {
  profile: Profile
}
const Top9 = ({ profile }: Top9Props) => {
  return (
    <>
      <h3 className="mb-5 text-dark">
        <strong>
          <IconHeartFilled
            floodColor="red"
            className="inline text-error"
            size={12}
          />{" "}
          Naoi mo chroí
        </strong>{" "}
        (Top 9)
      </h3>
      <ul className="grid gap-2 grid-cols-3 w-max">
        {profile.top9?.map((profile) => (
          <li key={profile.id} className="w-full">
            <div className="aspect-square bg-secondary-50">
              <a href={`/@${profile.slug}`}>
                <img
                  className="max-w-full pixel-art"
                  src={profile.iconUrl}
                  width={64}
                />
              </a>
            </div>
          </li>
        ))}
      </ul>
    </>
  )
}

export default Top9
