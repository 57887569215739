import React from "react"

import OghamStoneBanner from "./banners/ogham-stone"
import ShopBanner from "./banners/shop"
import ProfileBanner from "./banners/profile"
import ExploreBanner from "./banners/explore"

import GamesSection from "./sections/games"
import VocabSection from "./sections/vocab_lists"
import BooksSection from "./sections/books"
import MusicSection from "./sections/music"
import FoodSection from "./sections/food"
import CommunitiesSection from "./sections/communities"
import AppsSection from "./sections/apps"
import PodcastsSection from "./sections/podcasts"
import TVMoviesSection from "./sections/tv_movies"
import GettingStartedSection from "./sections/getting_started"
import PageBreadcrumb from "design_system/application/components/page_breadcrumb"
import AdventureBanner from "@love-irish/adventure-banner-component"
import Title from "design_system/core/components/title"
import Content from "design_system/core/components/content"
import { CodexRecordBlock } from "@love-irish/adventure-story-teller"

export const TemplateComponent = ({ collection, items }) => {
  const sectionItems = {}

  items.forEach((item) => {
    if (!sectionItems[item.section]) {
      sectionItems[item.section] = []
    }

    sectionItems[item.section].push(item)
  })

  return (
    <>
      <div className="container mt-8">
        <PageBreadcrumb pages={[{ name: "Calendar", href: "/calendar" }]} />
      </div>
      {!!collection.banner && (
        <div className="mt-8">
          <AdventureBanner banner={collection.banner} />
        </div>
      )}
      <div className="container mt-16">
        <div className="grid md:grid-cols-2">
          <div className="col-span-2 mb-4">
            <Title title={collection.name} size="lg" />
          </div>
        </div>
        <div className="grid md:grid-cols-5 gap-16 mt-8">
          <div className="text-lg space-y-5 md:col-span-3">
            <Content content={collection.content} />
          </div>
          <div className="md:col-span-2">
            {/* items in header section */}
            {(sectionItems.header || []).map((item, index) => {
              if (!item.resource || item.resource.type != "Item::CodexRecord")
                return null

              item.resource.slug = null

              return (
                <CodexRecordBlock
                  className="border"
                  addon={false}
                  item={item.resource}
                  key={index}
                  displayAudioInfo
                />
              )
            })}
          </div>
        </div>
      </div>

      <div className="container mt-8">
        <div className="grid mt-24">
          <div className="max-w-xl mx-auto">
            <h2 className="text-5xl font-serif text-center mb-0">
              Things to do
            </h2>
            <p className="text-xl text-center text-gray-500 mt-6">
              This event is mostly self-paced so feel free to explore and engage
              how you like, from watching videos on YouTube or TG4, to joining a
              local group or online community.
            </p>
          </div>
        </div>
      </div>
      <div className="container mt-12 hidden">
        <GettingStartedSection />
      </div>
      <div className="container mt-12 hidden">
        <VocabSection />
      </div>
      <div className="container mt-12">
        <CommunitiesSection />
      </div>
      <div className="container mt-12">
        <OghamStoneBanner />
      </div>
      <div className="container mt-12">
        <PodcastsSection />
      </div>
      <div className="container mt-12">
        <AppsSection />
      </div>

      <div className="container mt-12">
        <ProfileBanner />
      </div>
      {false && (
        <>
          <div className="container mt-12">
            <MusicSection />
          </div>
          <div className="container mt-12">
            <GamesSection />
          </div>
          <div className="container mt-12 hidden">
            <TVMoviesSection />
          </div>
          <div className="container mt-12 hidden">
            <BooksSection />
          </div>
          <div className="container mt-12 hidden">
            <ShopBanner />
          </div>
          <div className="container mt-12 hidden">
            <FoodSection />
          </div>
          <div className="container mt-12 hidden">
            <ExploreBanner />
          </div>
        </>
      )}
    </>
  )
}

export { ProfileBanner }
export default TemplateComponent
