import React from "react"
import { IconMenu, IconPlus } from "@tabler/icons-react"
import Title from "design_system/core/components/title"
import Content from "design_system/core/components/content"
import routes from "design_system/utils/routes"
import { CodexRecordBlock } from "@love-irish/adventure-story-teller"
import balloon from "./balloon.gif"
import cauldron from "./cauldron.gif"
import logoDarkMode from "images/brand/logos/wordmark-white.svg"

const ResourcesIndexTemplate = ({
  topics = [],
  codexRecords = [],
  collections = [],
}) => {
  return (
    <div className="bg-gray-50 pt-12 pb-24">
      <div className="container">
        <Title title="Resources" />
        <div className="mt-10 grid gap-4 sm:mt-16 lg:grid-cols-3 lg:grid-rows-2">
          <div className="relative lg:row-span-2">
            <div className="absolute inset-px rounded-lg bg-background lg:rounded-l-[2rem]"></div>
            <div className="relative flex h-full flex-col overflow-hidden rounded-[calc(theme(borderRadius.lg)+1px)] lg:rounded-l-[calc(2rem+1px)]">
              <div className="px-8 pb-3 pt-8 sm:px-10 sm:pb-0 sm:pt-10">
                <p className="mt-2 text-lg font-medium tracking-tight text-gray-950 max-lg:text-center">
                  <a href="/collections">Collections</a>
                </p>
                <p className="mt-2 max-w-lg text-gray-600 max-lg:text-center">
                  Curate your own list of resources from the site to create
                  vocab lists and more
                </p>
                <a href="/collections" className="font-bold mt-3 inline-block">
                  Browse collections &rarr;
                </a>
              </div>
              <div className="relative min-h-[30rem] w-full grow [container-type:inline-size] max-lg:mx-auto max-lg:max-w-sm">
                <div className="absolute inset-x-10 bottom-0 top-10 overflow-hidden rounded-t-[12cqw] border-x-[3cqw] border-t-[3cqw] border-gray-700 bg-dark shadow-2xl p-8">
                  <div className="flex justify-between gap-4 items-center border-b border-dark-600 pb-3">
                    <div>
                      <img src={logoDarkMode} alt="" className="w-20" />
                    </div>
                    <div className="px-2 py-1 text-secondary border border-dark-600 rounded">
                      <IconMenu size={16} />
                    </div>
                  </div>

                  <div className="grid divide-y divide-dark-600 text-secondary-400 gap-4 mt-4 dark">
                    {collections.map((collection) => (
                      <div key={collection.slug}>
                        <Title
                          title={collection.name}
                          className="text-lg text-white font-serif pt-4"
                          href={collection.path}
                        />
                        <Content
                          content={collection.summary}
                          className="line-clamp-3 text-sm text-secondary-400 mt-2"
                        />
                        <p className="mt-2">
                          <a
                            href={collection.path}
                            className="text-white text-sm font-bold"
                          >
                            View collection &rarr;
                          </a>
                        </p>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
            <div className="pointer-events-none absolute inset-px rounded-lg shadow ring-1 ring-black/5 lg:rounded-l-[2rem]"></div>
          </div>
          <div className="relative max-lg:row-start-1">
            <div className="absolute inset-px rounded-lg bg-background max-lg:rounded-t-[2rem]"></div>
            <div className="relative flex h-full flex-col overflow-hidden rounded-[calc(theme(borderRadius.lg)+1px)] max-lg:rounded-t-[calc(2rem+1px)]">
              <div className="px-8 pt-8 sm:px-10 sm:pt-10">
                <p className="mt-2 text-lg font-medium tracking-tight text-gray-950 max-lg:text-center">
                  <a href={routes.calendarPath()}>Calendar</a>
                </p>
                <p className="mt-2 max-w-lg text-base/6 text-gray-600 max-lg:text-center prose prose-sm">
                  Guides for celebrations and events happening throughout the
                  year and include vocab lists and activities to join in, from{" "}
                  <a href={routes.calendarEventPath("irish-week")}>
                    Irish week
                  </a>{" "}
                  and{" "}
                  <a href={routes.calendarEventPath("st-patricks-day")}>
                    St. Patrick's Day
                  </a>{" "}
                  to{" "}
                  <a href={routes.calendarEventPath("halloween")}>Halloween</a>{" "}
                  and{" "}
                  <a href={routes.calendarEventPath("christmas")}>Christmas</a>.
                </p>

                <a href="/calendar" className="font-bold mt-3 inline-block">
                  View calendar &rarr;
                </a>
              </div>
              <div className="flex flex-1 items-center justify-center px-8 py-10 sm:px-10 gap-8">
                <img
                  className="w-[64px] max-lg:max-w-xs pixel-art"
                  src={balloon}
                  alt="Pixel art balloon"
                />
                <img
                  className="w-[128px] max-lg:max-w-xs pixel-art"
                  src={cauldron}
                  alt="Pixel art cauldron"
                />
              </div>
            </div>
            <div className="pointer-events-none absolute inset-px rounded-lg shadow ring-1 ring-black/5 max-lg:rounded-t-[2rem]"></div>
          </div>
          <div className="relative max-lg:row-start-3 lg:col-start-2 lg:row-start-2">
            <div className="absolute inset-px rounded-lg bg-background"></div>
            <div className="relative flex h-full flex-col overflow-hidden rounded-[calc(theme(borderRadius.lg)+1px)]">
              <div className="px-8 pt-8 sm:px-10 sm:pt-10">
                <p className="mt-2 text-lg font-medium tracking-tight text-gray-950 max-lg:text-center">
                  <a href="/topics">Topics</a>
                </p>
                <p className="mt-2 max-w-lg text-gray-600 max-lg:text-center">
                  Browse resources by topic or follow to receive updates when
                  things are added in the future
                </p>
                <a href="/topics" className="font-bold mt-3 inline-block">
                  Browse topics &rarr;
                </a>
              </div>
              <div className="flex space-6 items-end flex-wrap p-8 max-h-[250px] overflow-hidden">
                {topics.map((topic) => (
                  <a
                    href={`/topics/${topic.slug}`}
                    key={topic.slug}
                    className="mr-2 border px-2 py-1 inline-block mt-2 text-sm rounded"
                  >
                    <span className="text-secondary">#</span>
                    <span className="font-bold">{topic.slug}</span>
                  </a>
                ))}
              </div>
            </div>
            <div className="pointer-events-none absolute inset-px rounded-lg shadow ring-1 ring-black/5"></div>
          </div>
          <div className="relative lg:row-span-2">
            <div className="absolute inset-px rounded-lg bg-background max-lg:rounded-b-[2rem] lg:rounded-r-[2rem]"></div>
            <div className="relative flex h-full flex-col overflow-hidden rounded-[calc(theme(borderRadius.lg)+1px)] max-lg:rounded-b-[calc(2rem+1px)] lg:rounded-r-[calc(2rem+1px)]">
              <div className="px-8 pt-8 sm:px-10 pb-0 sm:pt-10">
                <p className="mt-2 text-lg font-medium tracking-tight text-gray-950 max-lg:text-center">
                  <a href="/codex">Codex records</a>
                </p>
                <p className="mt-2 max-w-lg text-gray-600 max-lg:text-center">
                  Words and phrases that have special features like community
                  audio samples and more
                </p>
                <a href="/codex" className="font-bold mt-3 inline-block">
                  Browse codex &rarr;
                </a>
              </div>
              <div className="relative min-h-[30rem] w-full grow px-8 flex items-end">
                <div className="grid  justify-end items-end flex-nowrap overflow-hidden gap-6 max-h-[600px]">
                  {codexRecords.map((record, index) => (
                    <CodexRecordBlock
                      item={record}
                      key={index}
                      addon={false}
                      className="border rounded min-w-[200px] w-fit"
                    />
                  ))}
                </div>
              </div>
            </div>
            <div className="pointer-events-none absolute inset-px rounded-lg shadow ring-1 ring-black/5 max-lg:rounded-b-[2rem] lg:rounded-r-[2rem]"></div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ResourcesIndexTemplate
