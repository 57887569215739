import React from "react"

import Button from "@love-irish/core-button"

import duolingoAvatar from "images/profile_icons/duolingo.png"
import starterAvatar from "images/profile_icons/starter.png"
import brandAvatar from "images/profile_icons/brand.png"
import LofiImage from "design_system/core/components/lo_fi/image"
import LofiString from "design_system/core/components/lo_fi/string"
import LofiSentence from "design_system/core/components/lo_fi/sentence"
import LofiAvatar from "design_system/core/components/lo_fi/avatar"
import CelticDropCap from "design_system/application/components/celtic_drop_cap"
const ProfilePreview = ({
  banner,
  avatar,
}: {
  banner?: string
  avatar?: string
}) => (
  <div className="h-full border-x border-t rounded-t-xl p-4 border-dark">
    <div>
      {banner ? (
        <img src={banner} alt="" className="pixel-art" />
      ) : (
        <LofiImage height="h-36" background="bg-secondary-100" />
      )}
    </div>
    <div className="px-5 grid grid-cols-3 gap-5">
      <div className="card profile-card -mt-10 inline-block max-w-[200px]">
        <div className="profile-card-body p-3">
          <div className="flex gap-2">
            <div className="w-[32px]">
              {avatar ? (
                <img src={avatar} alt="" className="pixel-art" />
              ) : (
                <LofiAvatar />
              )}
            </div>
            <div className="w-[100px] grid gap-1 pt-1">
              <div>
                <LofiString width="max-w-[70%]" className="bg-gray-300" />
              </div>
              <div>
                <LofiString height="h-1.5" />
              </div>
            </div>
          </div>
          <hr className="my-3" />
          <div>
            <div className="grid gap-2">
              <LofiString height="h-1.5" width="max-w-[60%]" />
              <LofiString width="max-w-[40%]" height="h-1.5" />
            </div>
          </div>
        </div>
        <div className="grid gap-2 mt-4">
          <LofiString height="h-1.5" width="max-w-[60%]" />
          <LofiString width="max-w-[40%]" height="h-1.5" />
        </div>
      </div>

      <div className="col-span-2 py-5">
        <CelticDropCap className="m-0 mr-3" size={"xs"} letter="a" />
        <LofiSentence className="mb-4 w-[80px]" />
        <LofiSentence className="mb-4" />
        <LofiSentence className="mb-4" />
        <LofiSentence className="mb-4" />
      </div>
    </div>
  </div>
)

const ProfileBanner = () => (
  <div className="grid sm:grid-cols-2 bg-background border shadow-2xl rounded-xl border-green-400 shadow-green-200/50 mt-24">
    <div className="px-4 sm:px-12 sm:pt-12 h-full order-2 sm:order-none">
      <ProfilePreview avatar={duolingoAvatar} />
    </div>
    <div className="flex flex-col justify-center p-8 col-span-1 py-8 sm:py-20 order-1 sm:order-none">
      <h2 className="text-4xl font-serif">Customizable profiles</h2>
      <p className="my-4 max-w-xl text-lg">
        Earn loot like avatars, banners and dropcap sets to use on your profile
        by completing quests and challenges on the site. You can also customize
        your profile with a bio and social links.
      </p>

      <p className="my-4 p-4 text-sm text-green-800 rounded-lg bg-green-50">
        Visit the site during Irish week (March 1–17) logged into your account
        to unlock an exclusive avatar and banner for your profile!
      </p>

      <div className="flex gap-2 mt-4">
        <Button size="lg" as="a" href="/settings/profile">
          Customize your profile &rarr;
        </Button>
      </div>

      <p className="text-sm mt-8">
        Don't have an account yet?{" "}
        <a href="/signup" className="font-bold">
          Create one here
        </a>
      </p>
    </div>
  </div>
)

export { ProfilePreview }
export default ProfileBanner
