import checklistPencil from "images/doodle_icons/checklist_pencil.svg"
import microphone from "images/doodle_icons/microphone.svg"
import content from "images/doodle_icons/content.svg"
import React from "react"

const incentives = [
  {
    name: "Gather questions",
    imageSrc: checklistPencil,
    description:
      "Questions can be submitted before the interview and will contain some from the community.",
  },
  {
    name: "Moderate interview",
    imageSrc: microphone,
    description: "Interviews typically happen offline.",
  },
  {
    name: "Post responses",
    imageSrc: content,
    description: "Responses are transcribed and added to the site.",
  },
]

export default function InterviewOverview(props = {}) {
  return (
    <div className="@container" {...props}>
      <div className="max-w-3xl">
        <h2 className="text-lg font-normal font-fancy tracking-tight text-secondary-900">
          Formáid agallaimh
        </h2>
        <p className="mt-0 text-sm mb-3 text-secondary-500 font-normal">
          &ldquo;Interview format&ldquo;
        </p>
        <p className="mt-4 text-secondary-500 bg-background/50">
          Each interview features a group or individual from the global diaspora
          who is having an impact on Irish in some way and includes questions
          submitted by the community.
        </p>
      </div>
      <div className="grid grid-cols-1 gap-y-10 gap-x-8 pr-20 @md:grid-cols-3 mt-8 @md:pr-0">
        {incentives.map((incentive) => (
          <div key={incentive.name}>
            <div className="h-[40px] w-[50px] flex items-center">
              <img src={incentive.imageSrc} alt="" />
            </div>
            <div className="mt-5">
              <h3 className="text-sm font-medium text-secondary-900">
                {incentive.name}
              </h3>
              <p className="mt-2 text-sm text-secondary-500 bg-background/50">
                {incentive.description}
              </p>
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}
