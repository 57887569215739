import React from "react"
import PageBreadcrumb from "design_system/application/components/page_breadcrumb"
import Title from "design_system/core/components/title"
import Content from "design_system/core/components/content"

import mykHeadshot from "./headshots/myk.jpg"
import ProfileSocialLink from "design_system/community/components/profile_social_link"

export const Template = ({ team, bots }) => (
  <div className="container mt-8">
    <PageBreadcrumb pages={[{ name: "Company" }]} />

    <div className="text-center my-24 max-w-lg mx-auto">
      <Title title="About us" size="lg" />
      <Content
        content="Dedicated learners of Irish and Celtic languages building things for other others"
        className="text-xl mt-4"
      />
    </div>

    <div className="grid md:grid-cols-3 text-center">
      <div>
        <img
          src={mykHeadshot}
          alt="Myk headshot"
          className="w-56 inline-block rounded mb-4"
        />
        <Title title="Myk Klemme" size="sm" />
        <p className="text-sm mt-2">
          <strong>CEO</strong> &middot; <a href="/@myk">@myk</a>
        </p>

        <p className="mt-4">
          I am a beginner Irish learner and live in the San Francisco Bay Area
          in California ☀️
        </p>

        <p className="mt-4">
          Software engineer (former <a href="https://dropbox.com">Dropbox</a> &{" "}
          <a href="https://theinformation.com">The Information</a>) who loves
          learning and creating things. This site is my way to help other people
          learning Irish or the other Celtic languages while making it fun and
          rewarding to do so.
        </p>

        <p className="prose mt-4">
          <a href="/@myk">View profile</a> &middot;{" "}
          <a href="https://mykklemme.com">View portfolio</a>
        </p>
      </div>
    </div>
  </div>
)

export default Template
