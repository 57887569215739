import React from "react"
import PageBreadcrumb from "design_system/application/components/page_breadcrumb"
import Title from "design_system/core/components/title"
import Content from "design_system/core/components/content"
import CommunityLofiProfileComponent from "@love-irish/community-lofi-profile-component"

export const Template = ({ banner }) => {
  return (
    <div className="container mt-8">
      <PageBreadcrumb
        pages={[
          { href: "/adventure", name: "Adventure" },
          { href: "/adventure/rewards", name: "Rewards" },
          { href: "/adventure/rewards/banners", name: "Banners" },
        ]}
      />
      <div className="grid md:grid-cols-3 gap-8 mt-16">
        <div className="space-y-16 order-2 md:order-none">
          <div className="mb-8">
            <Title title={banner.name} />

            <p className="text-sm mt-1">
              Art by{" "}
              {banner.artistUrl || (
                <a href={`/@${banner.artistUsername}`}>
                  @{banner.artistUsername}
                </a>
              )}
            </p>
            <Content content={banner.summary} className="mt-8" />
          </div>
        </div>

        <div className="md:col-span-2 order-1 md:order-none">
          <div className="sticky top-4">
            <CommunityLofiProfileComponent
              className="border-secondary-200"
              banner={banner.imageUrl}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default Template
