import React, { Fragment, useContext } from "react"
import { IconMoodEmpty, IconMoodSad, IconMoodSmile } from "@tabler/icons-react"
import { Radio, RadioGroup } from "@headlessui/react"

import Button from "@love-irish/core-button"
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@love-irish/ui-components/dialog"
import { Label } from "@love-irish/ui-components/label"

import { Textarea } from "@love-irish/ui-components/textarea"
import Tooltip from "design_system/core/components/tooltip"
import { CurrentUserContext } from "@love-irish/application-session-provider"

const emojis = [
  { name: "smile", value: "😊", icon: IconMoodSmile },
  { name: "neutral", value: "😐", icon: IconMoodEmpty },
  { name: "sad", value: "😞", icon: IconMoodSad },
]

const DefaultTrigger = (
  <Button variant="outline" size="xs">
    Share feedback
  </Button>
)

export function FeedbackModal({ trigger = DefaultTrigger }) {
  const [selected, setSelected] = React.useState("")
  const currentUser = useContext(CurrentUserContext)
  console.log(currentUser)

  return (
    <Dialog>
      <DialogTrigger asChild>{trigger}</DialogTrigger>
      <DialogContent className="sm:max-w-[425px] bg-white">
        <DialogHeader>
          <DialogTitle className="mb-3">
            Share your feedback with us ❤️
          </DialogTitle>
          <p>We are always looking for ways to improve this site.</p>
          <p>
            <strong>
              If you have any suggestions or ideas for what we can do better,
              please let us know.
            </strong>{" "}
            Remember we are a small team so keep it constructive.
          </p>
        </DialogHeader>
        <div className="grid gap-4 py-4">
          <Label htmlFor="feedbackMessage">Feedback</Label>
          <Textarea
            rows={5}
            placeholder="Add your feedback for how we can improve or any issues you found."
            id="feedbackMessage"
          />
        </div>

        <div>
          <Label>Rate your overall experience with our site</Label>

          <div>
            <RadioGroup
              value={selected}
              onChange={(e) => setSelected(e)}
              aria-label="Mood picker"
              className="flex gap-3 mt-3"
            >
              {emojis.map(({ icon: Icon = Fragment, ...emoji }) => (
                <Fragment key={emoji.name}>
                  <Tooltip title={emoji.name} className="z-50">
                    <Radio
                      as={Button}
                      variant="outline"
                      size="sm"
                      value={emoji.value}
                      className="group text-warning data-[checked]:bg-success-200 data-[checked]:!border-success-500 data-[checked]:text-success-700"
                    >
                      <span className="text-xl">
                        <Icon />
                      </span>
                    </Radio>
                  </Tooltip>
                </Fragment>
              ))}
            </RadioGroup>
          </div>
        </div>
        <DialogFooter>
          <Tooltip
            className="z-50"
            title={!currentUser && "You must have an account to share feedback"}
          >
            <Button type="submit" disabled={!currentUser}>
              Send feedback
            </Button>
          </Tooltip>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  )
}

export default FeedbackModal
