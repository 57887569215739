import React from "react"
import PageBreadcrumb from "design_system/application/components/page_breadcrumb"

import MatcherGame from "@love-irish/adventure-matcher-mini-game"

export const Template = ({ options, collection, game }) => (
  <div className="container mt-8">
    <PageBreadcrumb
      pages={[
        { name: "Adventure", href: "/adventure" },
        { name: "Arcade", href: "/arcade" },
      ]}
    />

    <MatcherGame
      game={game}
      options={options}
      collection={collection}
      onContinue={() => window.location.reload()}
    />
  </div>
)

export default Template
