import React from "react"

const CommunitiesSection = () => (
  <div className="grid mt-24 lg:grid-cols-5 gap-10 relative z-10">
    <div className="mt-8">
      <h1 className="text-3xl font-serif">Communities</h1>
      <p className="text-lg mt-4">
        Discord, Facebook and other communities to checkout around the web
      </p>
    </div>
    <div className="lg:col-span-4">
      <div className="grid gap-10 sm:grid-cols-2 lg:grid-cols-3 items-start">
        <div className="border rounded-t-xl rounded-b bg-background shadow-lg overflow-hidden">
          <div className="p-4">
            <div className="flex justify-between items-start mb-3">
              <div className="text-2xl font-serif">
                <a href="https://discord.gg/BXHy8hbRYP">Love.irish</a>
              </div>
            </div>
            <p className="italic">
              Our official Discord server where you can chat with other learners
              and people interested in Irish and Celtic topics.
            </p>

            <div className="mt-4">
              <a href="https://discord.gg/BXHy8hbRYP">
                <strong>Ceangal</strong> &middot; <em>Join</em> &rarr;
              </a>
            </div>
            <div className="mt-4">
              {["discord", "community"].map((tag) => (
                <span
                  key={tag}
                  className="mr-2 border p-2 font-bold text-sm mt-2 inline-block"
                >
                  #{tag}
                </span>
              ))}
            </div>
          </div>
        </div>

        <div className="border rounded-t-xl rounded-b bg-background shadow-lg overflow-hidden">
          <div className="p-4">
            <div className="flex justify-between items-start mb-3">
              <div className="text-2xl font-serif">
                <a href="https://www.facebook.com/bitesizeirish">
                  Bitesize Irish
                </a>
              </div>
            </div>
            <p className="italic">
              A Facebook community where you can find helpful videos of phrases
              and other resources to help you with Irish pronunciation and
              grammar and engage with other learners.
            </p>

            <div className="mt-4">
              <a href="https://www.facebook.com/bitesizeirish">
                <strong>Ceangal</strong> &middot; <em>Join</em> &rarr;
              </a>
            </div>
            <div className="mt-4">
              {["facebook", "community"].map((tag) => (
                <span
                  key={tag}
                  className="mr-2 border p-2 font-bold text-sm mt-2 inline-block"
                >
                  #{tag}
                </span>
              ))}
            </div>
          </div>
        </div>

        <div className="border rounded-t-xl rounded-b bg-background shadow-lg overflow-hidden bg-background">
          <div className="p-4">
            <div className="flex justify-between items-start mb-3 gap-3">
              <div className="text-2xl mb-2 font-serif">
                <a href="https://discord.com/invite/craiclegaeilge">
                  Craic le Gaeilge
                </a>
              </div>
            </div>
            <p className="italic">
              An Irish Language Discord server. Have fun 'as Gaeilge' (in Irish)
              and connect with other people learning and using Irish in their
              everyday lives. Here you will find VCs, grammar resources,
              dictionary bots, live radio, newsfeeds and much more. There is a
              space for English if you have questions.
            </p>

            <div className="mt-4">
              <a href="https://discord.com/invite/craiclegaeilge">
                <strong>Ceangal</strong> &middot; <em>Join</em> &rarr;
              </a>
            </div>
            <div className="mt-4">
              {["discord", "community"].map((tag) => (
                <span
                  key={tag}
                  className="mr-2 border p-2 font-bold text-sm mt-2 inline-block"
                >
                  #{tag}
                </span>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
)

export default CommunitiesSection
