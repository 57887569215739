import React from "react"

import formatter from "design_system/utils/formatter"

import Button from "@love-irish/core-button"

import ResourceListItem from "@love-irish/resource-list-item"
import Title from "design_system/core/components/title"
import PageBreadcrumb from "design_system/application/components/page_breadcrumb"
import Content from "design_system/core/components/content"
import { IconBookmark } from "@tabler/icons-react"
import AdventureBanner from "@love-irish/adventure-banner-component"
import routes from "design_system/utils/routes"

export const TemplateComponent = ({
  items,
  name,
  collection,
  publishedAt,
  updatedAt,
  content,
  currentUser,
}) => {
  const columns = [[], [], []]

  items.forEach((item, index) => {
    columns[index % 3].push(item)
  })

  const navPages = []

  if (collection.owner) {
    navPages.push({
      name: `@${collection.owner.slug}`,
      href: routes.profilePath(collection.owner.slug),
    })
    navPages.push({
      name: "Collections",
      href: routes.profileCollectionsPath(collection.owner.slug),
    })
  } else {
    navPages.push({ name: "Collections", href: "/collections" })
  }

  return (
    <div>
      <div className="container mt-8">
        <PageBreadcrumb pages={navPages} />
      </div>
      {!!collection.banner && (
        <div className="mt-8">
          <AdventureBanner banner={collection.banner} />
        </div>
      )}
      <div className="container mt-16">
        <div className="mt-8 break-words">
          <Title size="lg" title={collection.name} />
        </div>

        <div className="grid gap-12 grid-cols-1 sm:grid-cols-12 sm:mt-20 print:grid-cols-1">
          <div className="col-span-2 sm:mt-24">
            <div className="sticky top-10">
              <div className="text-secondary-600">
                <div className="mt-4 text-xs">
                  Created{" "}
                  {collection.owner && (
                    <>
                      by{" "}
                      <strong>
                        <a href={collection.owner.path}>
                          {collection.owner.username}
                        </a>
                      </strong>{" "}
                    </>
                  )}
                  on{" "}
                  <time className="font-bold">
                    {formatter.shortDate(
                      collection.publishedAt || collection.createdAt
                    )}
                  </time>
                </div>
                <div className="mt-2 text-xs">
                  Last updated on{" "}
                  <time className="font-bold">
                    {formatter.shortDate(collection.updatedAt)}
                  </time>
                </div>
              </div>

              <div className="">
                {false && (
                  <>
                    <hr className="w-[50px] mt-4 mb-4" />
                    <Button variant="ghost" size="xs">
                      <IconBookmark size={18} /> Add to collection
                    </Button>
                  </>
                )}
                <hr className="w-[50px] mt-4 mb-4" />
                <h3 className="text-xs text-secondary-600">Jump to section:</h3>
                <ul className="mt-2">
                  <li>
                    <a href="#intro">Overview</a>
                  </li>
                  <li>
                    <a href="#items">Items</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div className="col-span-10">
            <div id="intro">
              {!!collection.content && (
                <div className="grid grid-cols-4 gap-8 mb-24">
                  <Content
                    content={collection.content}
                    dropcap={"md"}
                    className="!max-w-full prose-headings:!text-sm col-span-3"
                  />
                </div>
              )}
            </div>

            <div className="grid grid-cols-4 gap-8">
              <div className="col-span-3" id="items">
                <h3 className="mb-2 font-bold text-lg">Items</h3>
                <ul>
                  {items.map((item, index) => (
                    <li key={index} className="pt-6">
                      <ResourceListItem
                        item={item.resource}
                        currentUser={currentUser}
                      />
                    </li>
                  ))}
                  {items.length == 0 && <li>No items yet</li>}
                </ul>
              </div>
              <div>
                <div className="sticky top-10"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default TemplateComponent
