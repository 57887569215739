import React from "react"
import { useQuery } from "@apollo/client"

import Title from "design_system/core/components/title"
import query from "./query.graphql"
import SkeletonLoader from "design_system/core/components/skeleton_loader/content"
import PageBreadcrumb from "design_system/application/components/page_breadcrumb"
import InterviewOverview from "design_system/application/components/interview_overview"
import Excerpt from "design_system/core/components/excerpt"
import routes from "design_system/utils/routes"
import Timeago from "react-timeago"
import formatter from "design_system/utils/formatter"
import ResourceListItem from "@love-irish/resource-list-item"
// <img
// src={"/@mykklemme/icon"}
// className="pixel-art inline-block ml-2 mr-1 border border-secondary-200/50"
// width={24}
// height={24}
// />
const List = ({ currentUser, items, loading = false }) => {
  if (loading) return <SkeletonLoader />

  return items.map((item) => {
    return (
      <div key={item.gid}>
        <ResourceListItem item={item} currentUser={currentUser} />
      </div>
    )

    return (
      <div>
        <div key={item.id} className="flex flex-col-reverse md:flex-row">
          <div className="grid md:col-span-12 py-8 flex-1">
            <div className="col-span-8">
              <Title
                size="md"
                title={item.name}
                href={routes.interviewPath(item.slug)}
              />
              <Excerpt className="mt-1 text-base">
                {item.summary || item.content}
              </Excerpt>

              <div className="mt-5 relative z-10">
                <a href={routes.interviewPath(item.slug)}>
                  <strong>Léigh é</strong> &middot;{" "}
                  <em className="text-secondary-600">Read it</em>
                </a>
              </div>

              <div className="text-secondary-600">
                <div className="mt-4 text-xs">
                  Moderated by{" "}
                  <strong>
                    <a href="/@myk">@myk</a>
                  </strong>{" "}
                  on{" "}
                  <time className="font-bold">
                    {formatter.shortDate(item.moderatedAt)}
                  </time>
                </div>
                <div className="mt-2 text-xs">
                  Published on{" "}
                  <time className="font-bold">
                    {formatter.shortDate(item.publishedAt)}
                  </time>
                </div>
              </div>
            </div>
          </div>
          <div>
            {item.thumbnail?.url && (
              <img
                src={item.thumbnail.url}
                alt={item.name}
                className=" w-full max-w-[300px]"
              />
            )}
          </div>
        </div>
      </div>
    )
  })
}

const InterviewListTemplate = ({
  currentUser,
  items = [],
  loading = false,
}) => {
  return (
    <div className="container grid gap-10 grid-cols-1 md:grid-cols-12 mt-8">
      <div className="md:col-span-12">
        <header className="flex justify-between flex-col">
          <div>
            <div>
              <PageBreadcrumb
                pages={[{ name: "Interviews", href: "/interviews" }]}
              />
            </div>
            <div className="mt-8">
              <Title title="Ár n-Agallaimh" />
              <p className="mt-1 text-lg mb-3 text-gray-500 font-normal">
                &ldquo;Our Interviews&ldquo;
              </p>
            </div>
          </div>
        </header>
      </div>
      <div className="col-span-8 space-y-8">
        <List items={items} loading={loading} currentUser={currentUser} />
      </div>

      <aside className="col-span-4 mt-8">
        <InterviewOverview />
      </aside>
    </div>
  )
}

export const ConnectedComponent = (props) => {
  const { data, loading, error } = useQuery(query, {
    variables: { slug: "changelog" },
  })
  return (
    <InterviewListTemplate
      currentUser={props.currentUser}
      loading={loading}
      items={loading ? [] : data.collection?.items?.nodes || []}
    />
  )
}

export default InterviewListTemplate
