import React from "react"
import routes from "design_system/utils/routes"
import FeaturedInterview from "./featured_interview"
import checklistPencil from "images/doodle_icons/checklist_pencil.svg"
import microphone from "images/doodle_icons/microphone.svg"
import content from "images/doodle_icons/content.svg"
import InterviewOverview from "design_system/application/components/interview_overview"

const transferFeatures = [
  {
    name: "Gather questions",
    icon: checklistPencil,
    description:
      "Questions can be submitted before the interview and will contain some from the community.",
  },
  {
    name: "Moderate interview",
    icon: microphone,
    description: "Interviews typically happen offline.",
  },
  {
    name: "Post responses",
    icon: content,
    description: "Responses are transcribed and added to the site.",
  },
]

const InterviewSection = () => {
  return (
    <div
      className="relative mt-12 lg:mt-40 grid grid-cols-1 lg:grid-cols-2 lg:items-center lg:gap-40"
      id="interviews"
    >
      <div className="relative lg:order-2">
        <a href={routes.interviewsPath()}>
          <h3 className="text-2xl font-normal tracking-tight text-gray-900 sm:text-3xl font-fancy">
            Ár nAgallaimh{" "}
            <span className="text-gray-400 text-base font-sans">
              &ldquo;Our Interviews&rdquo;
            </span>
          </h3>
        </a>
        <p className="mt-3 text-lg text-gray-500">
          Our interview series featuring a group or individual from the global
          diaspora who is having an impact on Irish in some way.
        </p>
        <div className="mt-10">
          <InterviewOverview />
        </div>
      </div>
      <div
        className="relative -mx-4 mt-10 lg:mt-0 mb-10 lg:mb-0 lg:order-1"
        aria-hidden="true"
      >
        <div className="px-10 relative z-10">
          <FeaturedInterview />
        </div>
      </div>
    </div>
  )
}

InterviewSection.propTypes = {}

export default InterviewSection
