import React from "react"

import StaticHomePage from "./static/home/index"
import AboutUsPage from "./static/company/about_us"

import ApplicationUserOnboarding from "./users/onboarding/index"

import AdventureIndexPage from "./adventure/index"
import ArcadeIndexPage from "./adventure/arcade/index"
import ArcadeGamePlayPage from "./adventure/arcade/play"
import AdventureBannersIndexPage from "./adventure/banners/index"
import AdventureBannersShowPage from "./adventure/banners/show"
import AdventureArtCommissionIndexPage from "./adventure/art_commissions/index"
import AdventureDemoIndexPage from "./adventure/demo/index"

import CommunityIndexPage from "./community/index"

import ResourceIndexPage from "./resources/index"
import CodexIndexPage from "./codex/index"
import CodexShowPage from "./codex/show"
import CalendarEventShowPage from "./calendar/show"
import CalendarEventIrishWeek from "./calendar/show/irish-week"
import CalendarEventIrishWeekGuestbook from "./calendar/guestbook"
import ChangelogIndexPage from "./changelog/index"
import ChangelogShowPage from "./changelog/show"
import InterviewIndexPage from "./interviews/index"
import InterviewShowPage from "./interviews/show"
import ResourceCalendarPage from "./calendar/index"
import ResourceCollectionsIndexPage from "./collections/index"
import ResourceCollectionsShowPage from "./collections/show"
import CollectionsNewPage from "./collections/new"
import TopicsIndexPage from "./topics/index"
import TopicsShowPage from "./topics/show"

import ProfilesShowPage from "./profiles/show"

import AppShell from "design_system/application/providers/app_shell"
import Bugsnag from "@bugsnag/js"
import BugsnagPluginReact from "@bugsnag/plugin-react"

const clientStage = window?.LoveIrish?.client?.stage || "development"
const bugsnagApiKey = window?.LoveIrish?.apiKeys?.bugsnag

Bugsnag.start({
  apiKey: bugsnagApiKey,
  releaseStage: clientStage,
  redactedKeys: [
    "access_token", // exact match: "access_token"
    /^password$/i, // case-insensitive: "password", "PASSWORD", "PaSsWoRd"
    /^cc_/,
  ],
  enabledReleaseStages: ["production", "staging"],
  plugins: [new BugsnagPluginReact()],
})

// @ts-expect-error We are defining the plugin above
const ErrorBoundary = Bugsnag.getPlugin("react").createErrorBoundary(React)

const ErrorView = () => (
  <div>
    <p>There was an error! Please try again</p>
  </div>
)

const wrapped = (Component) => {
  return (props, railsContext) => {
    if (bugsnagApiKey) {
      if (props.currentUser) {
        Bugsnag.setUser(props.currentUser.id, props.currentUser.email)
        Bugsnag.addMetadata("User", {
          profile: props.currentUser.slug,
        })
      }
      Bugsnag.addMetadata("Template", {
        Component: Component.name,
        props,
      })
    }

    return () => (
      <AppShell
        currentUser={props.currentUser}
        currentProfile={props.currentProfile}
      >
        <ErrorBoundary FallbackComponent={ErrorView}>
          <Component {...props} />
        </ErrorBoundary>
      </AppShell>
    )
  }
}

export default {
  StaticHomePage: wrapped(StaticHomePage),
  AboutUsPage: wrapped(AboutUsPage),

  UserOnboarding: wrapped(ApplicationUserOnboarding),

  ProfilesShowPage: wrapped(ProfilesShowPage),

  AdventureIndexPage: wrapped(AdventureIndexPage),
  ArcadeIndexPage: wrapped(ArcadeIndexPage),
  ArcadeGamePlayPage: wrapped(ArcadeGamePlayPage),
  AdventureBannersIndexPage: wrapped(AdventureBannersIndexPage),
  AdventureBannersShowPage: wrapped(AdventureBannersShowPage),
  AdventureArtCommissionIndexPage: wrapped(AdventureArtCommissionIndexPage),
  AdventureDemoIndexPage: wrapped(AdventureDemoIndexPage),

  CommunityIndexPage: wrapped(CommunityIndexPage),

  ResourceIndexPage: wrapped(ResourceIndexPage),
  CodexIndexPage: wrapped(CodexIndexPage),
  CodexShowPage: wrapped(CodexShowPage),

  CollectionsIndexPage: wrapped(ResourceCollectionsIndexPage),
  CollectionsShowPage: wrapped(ResourceCollectionsShowPage),
  CollectionsNewPage: wrapped(CollectionsNewPage),

  TopicsIndexPage: wrapped(TopicsIndexPage),
  TopicsShowPage: wrapped(TopicsShowPage),

  ResourceCalendarPage: wrapped(ResourceCalendarPage),
  CalendarEventShowPage: wrapped(CalendarEventShowPage),
  CalendarEventIrishWeek: wrapped(CalendarEventIrishWeek),
  CalendarEventIrishWeekGuestbook: wrapped(CalendarEventIrishWeekGuestbook),

  InterviewIndexPage: wrapped(InterviewIndexPage),
  InterviewShowPage: wrapped(InterviewShowPage),

  ChangelogIndexPage: wrapped(ChangelogIndexPage),
  ChangelogShowPage: wrapped(ChangelogShowPage),
}
