import React from "react"
import Button from "design_system/core/components/button"
import AdventureStoryPreview from "@love-irish/adventure-story-preview"
import { CodexRecordBlock } from "@love-irish/adventure-story-teller"
import { faker } from "@faker-js/faker"
import Tooltip from "design_system/core/components/tooltip"
import styles from "./index.module.css"
import * as classNames from "classnames"

console.log({ styles })

const CodexSection = () => (
  <div className="container grid md:grid-cols-2 gap-10 py-24">
    <div className="mt-16">
      <div className="sticky top-10">
        <h2 className="text-7xl font-mono-header">
          Level up words &amp; phrases
        </h2>
        <p className="text-lg">
          Keep track of words and phrases you've unlocked in the codex. You can
          use these to cast spells and unlock special features on the site.
        </p>
        <p className="text-lg">
          Each record has more info and audio pronunciation examples from the
          community to help you learn a{" "}
          <Tooltip
            title="couple of words in Irish"
            wrapperClass="text-definition"
          >
            cúpla focal as Gaeilge
          </Tooltip>
          .
        </p>
        <p className="mt-10">
          <a href="" className="font-bold underline">
            Learn more &rarr;
          </a>
        </p>
      </div>
    </div>
    <div className="">
      <div
        className={classNames(
          "grid grid-cols-2 gap-4 justify-start items-start -mt-10 ",
          styles.wordContainer
        )}
      >
        <div className="grid gap-4 mt-16">
          <CodexRecordBlock
            item={{ name: "Tá Gaeilge agam", translation: "I speak Irish" }}
            className={classNames("!max-w-full !border", styles.wordContainer)}
          />

          <CodexRecordBlock
            item={{
              name: "Slán go fóill",
              translation: "Goodbye for now",
            }}
            className={classNames("!max-w-full !border", styles.wordContainer)}
          />
          <CodexRecordBlock
            item={{ name: "Dia dhuit", translation: "Hello" }}
            className={classNames("!max-w-full !border", styles.wordContainer)}
          />
        </div>
        <div className="grid gap-4 -mt-6">
          <CodexRecordBlock
            item={{
              name: "Céad mile failte",
              translation: "A hundred thousand welcomes",
            }}
            className={classNames("!max-w-full !border", styles.wordContainer)}
          />
          <CodexRecordBlock
            item={{ name: "Madra", translation: "Dog" }}
            className={classNames("!max-w-full !border", styles.wordContainer)}
          />
          <CodexRecordBlock
            item={{
              name: "Fan go fóill agus éisteacht",
              translation: "Stay a while and listen",
            }}
            className={classNames("!max-w-full !border", styles.wordContainer)}
          />
          <CodexRecordBlock
            item={{
              name: "Suaimhneas",
              translation: "Peace, tranquility, or calm",
            }}
            className={classNames("!max-w-full !border", styles.wordContainer)}
          />
        </div>
      </div>
    </div>
  </div>
)

export default CodexSection
