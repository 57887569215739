import React, { useState } from "react"
import PageBreadcrumb from "design_system/application/components/page_breadcrumb"
import Title from "design_system/core/components/title"
import Form from "@love-irish/resource-new-collection-form"
import { toast } from "sonner"

export const Template = ({ collections, currentProfile = {} }) => {
  const handleSuccess = (result) => {
    console.debug("result", JSON.stringify(result, null, 2))
    if (!result?.data?.createCollection?.success) {
      return toast.error("There was a problem creating the collection")
    }

    if (result?.data?.createCollection?.collection?.url)
      window.location.href = result?.data.createCollection.collection.url
  }

  return (
    <div className="container mt-8">
      <PageBreadcrumb pages={[{ name: "Resources", href: "/resources" }]} />
      <div className="my-8">
        <Title title="Create new collection" />
        <p>
          Organize resources from our site like codex records, items, profiles,
          topics and even other collections for quick reference.
        </p>
      </div>
      <div className="grid md:grid-cols-2 mt-8">
        <div>
          <Form owner={currentProfile} onSuccess={handleSuccess} />
        </div>
      </div>
    </div>
  )
}

export default Template
