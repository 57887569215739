import React, { useState, useEffect } from "react"
import { useWindowSize } from "react-use"
import Confetti from "react-confetti"
import Title from "design_system/core/components/title"
import Content from "design_system/core/components/content"
import ResourceListItem, { CodexRecord } from "@love-irish/resource-list-item"
import AdBlock from "@love-irish/application-ad-block"
import { LootDropBlock } from "@love-irish/adventure-story-teller"
import bitesizeBanner from "./bitesize.png"
import duolingoAvatar from "images/profile_icons/duolingo.png"
import { IconStar, IconRefresh, IconTrophy } from "@tabler/icons-react"
import Tooltip from "design_system/core/components/tooltip"
import Button from "@love-irish/core-button"
import FeedbackModal from "@love-irish/application-feedback-modal"

const MatchingGame = ({
  options,
  onComplete,
  onContinue,
  collection,
  game,
}) => {
  const { width, height } = useWindowSize()
  // const currentProfile = useCurrentProfile()
  // Combined state object
  const [gameState, setGameState] = useState({
    leftItems: [],
    rightItems: [],
    selectedLeft: null,
    selectedRight: null,
    matches: [],
    completed: false,
  })

  // Initialize the game with shuffled items
  useEffect(() => {
    if (!options || options.length === 0) return

    // Shuffle both columns
    const shuffledLeft = [...options].sort(() => Math.random() - 0.5)
    const shuffledRight = [...options].sort(() => Math.random() - 0.5)

    setGameState({
      leftItems: shuffledLeft,
      rightItems: shuffledRight,
      selectedLeft: null,
      selectedRight: null,
      matches: [],
      completed: false,
    })
  }, [options])

  // Handle card selection
  const handleLeftSelect = (index) => {
    // Don't allow selection of matched items
    if (gameState.matches.some((match) => match.leftIndex === index)) return

    setGameState({
      ...gameState,
      selectedLeft: index,
    })
  }

  const handleRightSelect = (index) => {
    // Don't allow selection of matched items
    if (gameState.matches.some((match) => match.rightIndex === index)) return

    // Save current right selection
    const newSelectedRight = index

    // Check for matches if we have both selections
    if (gameState.selectedLeft !== null) {
      const leftItem = gameState.leftItems[gameState.selectedLeft]
      const rightItem = gameState.rightItems[newSelectedRight]

      if (leftItem.name === rightItem.name) {
        // Add to matches
        const newMatches = [
          ...gameState.matches,
          {
            leftIndex: gameState.selectedLeft,
            rightIndex: newSelectedRight,
          },
        ]

        // Check if game is complete
        const isCompleted = newMatches.length === options.length
        if (isCompleted && onComplete) {
          setTimeout(onComplete, 100)
        }

        // Update state with match
        setTimeout(() => {
          setGameState({
            ...gameState,
            matches: newMatches,
            selectedLeft: null,
            selectedRight: null,
            completed: isCompleted,
          })
        }, 100)
      } else {
        // No match, reset selections after delay
        setTimeout(() => {
          setGameState({
            ...gameState,
            selectedLeft: null,
            selectedRight: null,
          })
        }, 100)
      }
    }

    // Update right selection
    setGameState({
      ...gameState,
      selectedRight: newSelectedRight,
    })
  }

  return (
    <>
      {gameState.completed && (
        <Confetti
          width={width}
          height={height}
          recycle={false}
          numberOfPieces={50}
          tweenDuration={3000}
          initialVelocityY={{ min: 5, max: 20 }}
          colors={[
            "#009a4a",
            "#166534",
            "#0f172a",
            "#cbd5e1",
            "#a3e635",
            "#eab308",
          ]}
        />
      )}
      <div className="w-full max-w-4xl mx-auto p-4">
        <div className="text-center mb-8">
          <Title title={game.name} />
          <Content content="The Matching Game" size="sm" />

          <Content
            content="Match the Irish in the left column to their English translations on the right. You can review the codex records after you complete the round."
            className="mt-3 w-96 mx-auto text-sm"
          />
        </div>

        <div className="flex flex-col md:flex-row justify-between gap-8">
          {/* Left column */}
          <div className="flex-1">
            <div className="text-center mb-4">
              <Title title="Gaeilge" size="sm" />
              <Content content="Irish" size="xs" />
            </div>
            <div className="space-y-2">
              {gameState.leftItems.map((item, index) => (
                <div
                  key={`left-${index}`}
                  className={`p-4 rounded-lg cursor-pointer transition-all ${
                    gameState.matches.some((match) => match.leftIndex === index)
                      ? "bg-green-100 border-2 border-green-500"
                      : gameState.selectedLeft === index
                      ? "bg-dark-50 border-2 border-dark"
                      : "bg-secondary-50 hover:bg-secondary-200 border-2 border-transparent"
                  }`}
                  onClick={() => handleLeftSelect(index)}
                >
                  {item.name}
                </div>
              ))}
            </div>
          </div>

          {/* Right column */}
          <div className="flex-1">
            <div className="text-center mb-4">
              <Title title="Béarla" size="sm" />
              <Content content="English" size="xs" />
            </div>
            <div className="space-y-2">
              {gameState.rightItems.map((item, index) => (
                <div
                  key={`right-${index}`}
                  className={`p-4 rounded-lg cursor-pointer transition-all ${
                    gameState.matches.some(
                      (match) => match.rightIndex === index
                    )
                      ? "bg-green-100 border-2 border-green-500"
                      : gameState.selectedRight === index
                      ? "bg-dark-50 border-2 border-dark"
                      : "bg-secondary-50 hover:bg-secondary-200 border-2 border-transparent"
                  }`}
                  onClick={() => handleRightSelect(index)}
                >
                  {item.translation}
                </div>
              ))}
            </div>
          </div>
        </div>

        <div className="flex justify-end gap-4 mt-16">
          <div className="grow">
            <div className="border max-w-sm rounded p-2">
              <div className="flex items-start gap-8 justify-between mb-2">
                <div className="p-1">
                  <p className="text-xs">Using records from collection:</p>
                  <Title
                    title={collection.name}
                    size="xs"
                    className="mt-2"
                    href={collection.path}
                  />
                </div>
                <Tooltip
                  title="Change source (collection, random, personalized)"
                  className="text-sm"
                >
                  <Button
                    variant="ghost"
                    color="secondary"
                    size="xs"
                    className="hidden"
                  >
                    <IconRefresh size={16} />
                  </Button>
                </Tooltip>
              </div>
              <hr />
              <p className="text-sm py-1.5 px-2 bg-warning-100 mt-2 rounded prose prose-sm">
                Arcade is in early access!{" "}
                <strong>
                  You can still play but your progress will not be saved.
                </strong>{" "}
              </p>
              <div className="flex gap-2 justify-between items-center mt-3 hidden">
                <p className="text-sm">
                  Help us improve this game &amp; site ❤️
                </p>
                <FeedbackModal />
              </div>
              <p className="text-sm py-1.5 px-2 bg-warning-100 mt-1 rounded prose prose-sm hidden">
                Currently playing in demo mode until{" "}
                <a href="/start">your profile is active</a>. You can still play
                but your progress will not be saved.
              </p>
            </div>
          </div>
          {/* Progress indicator */}
          <div>
            <div className="text-right">
              <div className="flex justify-end items-center mb-2">
                <span>
                  Matched: {gameState.matches.length}/{options.length}
                </span>
              </div>
              <div className="w-[150px] bg-gray-200 rounded-full h-2.5">
                <div
                  className="bg-dark h-2.5 rounded-full transition-all"
                  style={{
                    width: `${
                      (gameState.matches.length / options.length) * 100
                    }%`,
                  }}
                ></div>
              </div>
            </div>

            {gameState.completed && (
              <div className="mt-4 text-right">
                <Button
                  className="px-6 py-2 rounded-lg font-medium bg-green-500 text-white hover:bg-green-600"
                  onClick={onContinue}
                >
                  Play again
                </Button>
              </div>
            )}
          </div>
        </div>

        {gameState.completed && (
          <div className="mt-12">
            <hr />
            <div className="text-center mb-4 mt-12">
              <Title title="Summary" size="sm" />
            </div>

            <div className="grid md:grid-cols-3 gap-8 mt-12">
              <div>
                <p>Rewards 🏆</p>

                <ul className="mt-8 space-y-6">
                  <li>
                    <div className="border border-warning rounded p-4 grid">
                      <div className="text-xs text-warning-700 flex gap-1 items-center mb-2">
                        <IconTrophy
                          className="text-warning inline-block align-middle shrink-0"
                          size={14}
                        />
                        EXP
                      </div>
                      <p className="text-sm">
                        Earn experience for each codex record practiced
                      </p>
                      <ul className="mt-4">
                        <li className="flex justify-between">
                          Codex:{" "}
                          <strong className="text-warning">
                            5 EXP (x{options.length})
                          </strong>
                        </li>
                        <li className="flex justify-between">
                          Difficulty multiplier:{" "}
                          <strong className="text-warning">1</strong>
                        </li>
                        <li className="flex justify-between pt-4">
                          <strong>Total:</strong>{" "}
                          <strong className="text-warning">
                            {options.length * 5} EXP
                          </strong>
                        </li>
                      </ul>
                    </div>
                  </li>
                  <li>
                    <LootDropBlock
                      className="!max-w-full !border !border-warning rounded"
                      item={{
                        // name: "Íocón na ulchabhán glas",
                        name: "Avatar",
                        summary:
                          "You found a new avatar which can be used for your profile",
                        icon: duolingoAvatar,
                      }}
                    />
                  </li>
                </ul>

                <div className="mt-6 sticky top-8 flex justify-end hidden">
                  <AdBlock
                    imageUrl={bitesizeBanner}
                    title="Start Singing in Irish Today"
                    content="Learn to sing Irish language songs with confidence, guided by the fluent Bitesize Irish team."
                    href="https://bitesize.irish/sing"
                    label="bitesize.irish/sing"
                  />
                </div>
              </div>
              <div className="col-span-2">
                <p>Codex records 📝</p>
                <ul className="mt-8 space-y-6">
                  {options.map((item) => (
                    <li key={item.id}>
                      <CodexRecord item={item} level={1} />
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  )
}

export default MatchingGame
