import React from "react"

import Button from "@love-irish/core-button"

import {
  IconBookmark,
  IconBrandSteam,
  IconChevronLeft,
  IconChevronRight,
  IconHeart,
} from "@tabler/icons-react"

const PodcastsSection = () => (
  <div className="grid mt-24 lg:grid-cols-5 gap-10">
    <div className="mt-8">
      <h1 className="text-3xl font-serif">Podcasts</h1>
      <p className="text-lg mt-4">
        Podcasts can be a great way to immersive yourself into the language and
        hear everyday conversations in Irish or from learners on their journey.
      </p>
    </div>
    <div className="lg:col-span-4">
      <div className="grid gap-10 sm:grid-cols-2 items-start">
        <div className="border rounded-t-xl rounded-b bg-background shadow-lg overflow-hidden">
          <div className="p-4 py-6">
            <div className="flex justify-between items-start mb-3">
              <div className="text-2xl font-serif">
                <a href="https://open.spotify.com/show/75kbMRRV1mWcI6HFcFazJv?si=6b3253730e5a4233">
                  Bitesize Irish podcast
                </a>
              </div>
              <div className="flex ">
                <Button size="sm" variant="ghost">
                  <IconHeart className="inline" size={16} />
                </Button>
                <Button size="sm" variant="ghost">
                  <IconBookmark className="inline" size={16} />
                </Button>
              </div>
            </div>
            <p className="italic">
              Discussion podcast for Irish language learners. Bitesize Irish
              offers self-paced Irish language learning courses, and a private
              online community including regular conversation practice calls.
              Based in Limerick, Ireland, since 2010.
            </p>

            <div className="mt-4">
              <a href="https://open.spotify.com/show/75kbMRRV1mWcI6HFcFazJv?si=6b3253730e5a4233">
                <strong>Éist</strong> &middot; <em>Listen</em> &rarr;
              </a>
            </div>
            <div className="mt-6">
              {["podcast", "irish-language"].map((tag) => (
                <span
                  key={tag}
                  className="mr-2 border p-2 font-bold text-sm mt-2"
                >
                  #{tag}
                </span>
              ))}
            </div>
          </div>
        </div>

        <div className="border rounded-t-xl rounded-b bg-background shadow-lg overflow-hidden bg-background">
          <div className="p-4 py-6">
            <div className="flex justify-between items-start mb-3 gap-3">
              <div className="text-2xl mb-2 font-serif">
                <a href="https://open.spotify.com/show/1im0t221EzNhvPWNSrv6PI?si=f2c71998d2574488">
                  An Saol ó Dheas
                </a>
              </div>
              <div className="flex">
                <Button size="sm" variant="ghost">
                  <IconHeart className="inline" size={16} /> 40k
                </Button>
                <Button size="sm" variant="ghost">
                  <IconBookmark className="inline" size={16} />
                </Button>
              </div>
            </div>
            <p className="italic">
              An almost daily podcast in Irish featuring interviews with people
              from all around Ireland about a range of topics. A great resource
              to hear different accents and dialects.
            </p>

            <div className="mt-4">
              <a href="https://open.spotify.com/show/1im0t221EzNhvPWNSrv6PI?si=f2c71998d2574488">
                <strong>Éist</strong> &middot; <em>Listen</em> &rarr;
              </a>
            </div>
            <div className="mt-6">
              {["podcast", "irish-language"].map((tag) => (
                <span
                  key={tag}
                  className="mr-2 border p-2 font-bold text-sm mt-2"
                >
                  #{tag}
                </span>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
)

export default PodcastsSection
