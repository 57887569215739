import React, { useEffect } from "react"
import Title from "design_system/core/components/title"
import { IconMicrophone } from "@tabler/icons-react"
import Content from "design_system/core/components/content"

import AddToCollection from "@love-irish/resource-add-to-collection"
import TopicBadge from "@love-irish/resource-topic-badge"
import formatter from "design_system/utils/formatter"
import { format } from "timeago.js"

// We support a simple version of liquid type tags in codex record names to help
// with examples. Right now we use @grug as the data source for the dynamic tag.
export function addDynamicTags(string = "") {
  return string.replace("_name_", "_@grug_")
}

const InterviewListItem = ({ currentUser, item, ...props }) => {
  return (
    <div className="border rounded-xl bg-background">
      <div className="p-4">
        <div className="flex gap-1 items-center text-xs mb-4">
          <IconMicrophone
            className="text-secondary-400 inline-block align-middle"
            size={14}
          />
          Interview
        </div>
        <div className="flex justify-between items-start mb-3 gap-8 mt-2">
          <Title
            className="post-title text-2xl flex gap-2"
            title={addDynamicTags(item.name)}
            size="sm"
            showIcon={false}
            href={item.path}
          />
          <div className="flex shrink-0 gap-1">
            <AddToCollection itemId={item.gid} currentUser={currentUser} />
          </div>
        </div>

        <div className="mt-4 flex gap-2">
          <div>
            {item.summary && (
              <Content
                content={item.summary}
                className="prose-sm text-inherit text-sm text-dark dark:text-white !font-mono first-line:not-italic"
              />
            )}
            {item.path && (
              <div className="mt-4">
                <a href={item.path}>
                  <strong>Léigh mó</strong> &middot; <em>Read more</em> &rarr;
                </a>
              </div>
            )}

            {item.topics?.length > 0 && (
              <div className="mt-6">
                {["game", "steam", "irish-game-studio", "irish-language"].map(
                  (topic) => (
                    <TopicBadge topic={{ slug: topic }} key={topic} />
                  )
                )}
              </div>
            )}
          </div>
          {(item.imageUrl || item.thumbnailUrl) && (
            <img
              src={item.thumbnailUrl || item.imageUrl}
              className="w-[300px] max-w-full rounded-t-xl overflow-hidden shrink-0"
            />
          )}
        </div>
      </div>
      <div className="p-4 border-t flex justify-between text-xs">
        {item.owner && (
          <span>
            Added by <strong>@{item.owner.slug}</strong> on{" "}
            <time
              title={new Date(item.createdAt).toLocaleString()}
              dateTime={item.createdAt}
              className="font-bold"
            >
              {formatter.shortDate(item.createdAt)}
            </time>
          </span>
        )}
        <span>
          Updated{" "}
          <time
            title={new Date(item.updatedAt).toLocaleString()}
            dateTime={item.updatedAt}
            className="font-bold"
          >
            {format(item.updatedAt)}
          </time>
        </span>
      </div>
    </div>
  )
}

export default InterviewListItem
